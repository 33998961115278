import { useCallback } from 'react';
import { IListProviderData } from '@/Data/Interfaces/response/Provider/IProviderResponse';
import { useFormikContext } from 'formik';
import { IUseFunctions } from './interfaces';

export const useProviderFunctions = ({
  providersList,
  setProviderId,
  refetchSearchProviderPage,
}: IUseFunctions) => {
  const form = useFormikContext();

  const onChange = useCallback(
    (x: string) => {
      const providerSelected = providersList?.find(
        (value: IListProviderData) => value.externalId === x
      );
      setProviderId(x);
      form?.setFieldValue(
        'razaoSocial',
        providerSelected && providerSelected.nome
      );
    },
    [providersList, setProviderId]
  );

  const onSearch = useCallback(
    (search: string | undefined) => {
      refetchSearchProviderPage({
        search: search,
      });
    },
    [refetchSearchProviderPage]
  );

  return {
    onChange,
    onSearch,
  };
};

import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { useFormikContext } from 'formik';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import { IUnitaryValue } from './interfaces';
import { useUnitaryValueCallAPI } from './Hooks/useUnitaryValueCallAPI';
import { useUnitaryValueMemorizations } from './Hooks/useUnitaryValueMemorizations';
import { removeMaskByCurrencyType } from '@/Utils/MasksUtils';

export const UnitaryValue: FC<IUnitaryValue> = ({
  index,
  formValues,
  setTotal,
  getTotalValue,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();

  const { result, unitsMeasurements } = useUnitaryValueCallAPI({
    formValues,
  });

  const { dropdownRight } = useUnitaryValueMemorizations({
    index,
    unitsMeasurements,
  });

  useEffect(() => {
    if (result.isSuccess && result.data !== null) {
      const x = result.data;

      form.setFieldValue(
        `pedidoCompraItens.${index}.precoUnitario`,
        x?.valorCusto
      );

      form.setFieldValue(
        `pedidoCompraItens.${index}.tipoDesconto`,
        form?.values?.pedidoCompraItens?.[index]?.tipoDesconto ??
          DiscountTypes.currency
      );
    }
  }, [result.data?.externalId]);

  return (
    <>
      <CurrencyInput
        name={`pedidoCompraItens.${index}.precoUnitario`}
        label={
          index === 0
            ? t(
                'purchasing.purchaseOrder.create.productsCardField.unitaryValue'
              )
            : ''
        }
        required
        placeHolder={t(
          'purchasing.purchaseOrder.create.productsCardPlaceHolders.unitaryValue'
        )}
        allowExtraDecimal
        withoutMarginBottom
        dropdownRight={dropdownRight}
        disabled={!formValues.productExternalId}
        onChange={(x) => {
          setTotal(
            getTotalValue({
              newUnitValue: removeMaskByCurrencyType(x),
            })
          );
        }}
      />
    </>
  );
};

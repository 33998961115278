import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { ILaboratoryData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '../../../../../../Data/Interfaces/model/permissionEnum';
import { IPermissionWrapper } from '@/Components/PermissionWrapper';
import { IDeleteModalTable } from '@/Components/Table';
import { TitleHandler, BodyHandler } from '../Modal/laboratoryListUtils';

export const useListMemorizations = ({
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      newButton: {
        label: t('production.laboratory.list.newButton'),
        onClick: () => history.push('/production/labs/create'),
        permission: {
          permission: PermissionEnum.Producao_Laboratorio_Cadastrar,
          tooltip: 'noPermission.production.laboratory.create',
        },
        dataTestId: 'production-laboratory-new-button',
      },
      editButton: {
        permission: {
          permission: PermissionEnum.Producao_Laboratorio_AlterarStatus,
          tooltip: 'noPermission.production.laboratory.statusEdit',
        },
        options: [
          {
            label: t('production.laboratory.list.status'),
            isStatusOnly: true,
          },
        ],
      },
      deleteButton: {
        permission: {
          permission: PermissionEnum.Producao_Laboratorio_Excluir,
          tooltip: 'noPermission.production.laboratory.delete',
        },
      },
      searchPlaceholder: t('production.laboratory.list.searchPlaceholder'),
      nameKey: 'laboratorio',
    }),
    []
  );

  const rowsOptions = useMemo(
    (): {
      permission: IPermissionWrapper;
      onRowDoubleClick?: (record: any) => void;
      onRowClick?: (record: any) => void;
    } => ({
      permission: {
        permission: PermissionEnum.Producao_Laboratorio_VerDetalhes,
        tooltip: 'noPermission.production.laboratory.details',
        placement: 'topLeft',
      },
      onRowDoubleClick: (x: ILaboratoryData) =>
        history.push(`/production/labs/${x.externalId}/details`),
    }),
    []
  );

  const deleteModal = useMemo((): IDeleteModalTable | undefined => {
    return {
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    };
  }, [selectedRowsOnTable, t]);

  return {
    headerOptions,
    rowsOptions,
    deleteModal,
  };
};

import { FC } from 'react';
import { Col } from 'antd';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { useTranslation } from 'react-i18next';
import { IQuantity } from './interfaces';
import { useQuantityCallAPI } from './Hooks/useQuantityCallAPI';
import { useQuantityMemorizations } from './Hooks/useQuantityMemorizations';

export const Quantity: FC<IQuantity> = ({
  index,
  currentProductExternalId,
  pharmaceuticalFormExternalId,
  addRow,
}) => {
  const { t } = useTranslation();

  const {
    unitsMeasurementsDefault,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
  } = useQuantityCallAPI({
    currentProductExternalId,
    pharmaceuticalFormExternalId,
  });

  const { dropdownRight } = useQuantityMemorizations({
    unitsMeasurementsDefault,
    listunitsMeasurementsPrescription,
    isUnitOfMeasurePrescriptionLoading,
    index,
  });

  return (
    <Col span={3}>
      <WeightInput
        name={`produtos.${index}.quantidade`}
        label={
          index === 0
            ? t('production.standardFormula.create.productLine.quantity')
            : ''
        }
        dropdownRight={dropdownRight}
        required
        placeHolder={t(
          'production.standardFormula.create.productLine.quantityPlaceholder'
        )}
        onChange={addRow}
      />
    </Col>
  );
};

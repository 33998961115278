import { i18n } from '@/Components';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CHANGE_STATUS_SPECIALTIES,
  CREATE_SPECIALTIES,
  DELETE_SPECIALTIES,
  FETCH_LIST_SPECIALTIES,
} from '@/ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createSpecialties,
    isLoading: createSpecialtiesLoading,
  }: any = useControllerMutateApiHook({
    uniqId: CREATE_SPECIALTIES,
    entityApi: SpecialtiesAPI.createSpecialties,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('specialties.create.success'));

        queryClient.invalidateQueries([FETCH_LIST_SPECIALTIES]);
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const {
    mutateAsync: changeSpecialtiesStatus,
    isLoading: changeSpecialtiesStatusLoading,
  }: any = useControllerMutateApiHook({
    uniqId: CHANGE_STATUS_SPECIALTIES,
    entityApi: SpecialtiesAPI.changeSpecialtiesStatus,
    options: {
      onSuccess: () => queryClient.invalidateQueries([FETCH_LIST_SPECIALTIES]),
    },
  });

  const { mutateAsync: deleteSpecialties }: any = useControllerMutateApiHook({
    uniqId: DELETE_SPECIALTIES,
    entityApi: SpecialtiesAPI.deleteSpecialties,
    options: {
      onSuccess: () => {
        messageSucessToast(i18n.t('specialties.listPage.deleteModal.success'));

        queryClient.invalidateQueries([FETCH_LIST_SPECIALTIES]);
      },
    },
  });

  const especialtiesList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SPECIALTIES,
    entityApi: SpecialtiesAPI.listSpecialties,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  return {
    createSpecialties,
    createSpecialtiesLoading,
    changeSpecialtiesStatus,
    changeSpecialtiesStatusLoading,
    deleteSpecialties,
    especialtiesList,
  };
};

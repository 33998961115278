import { v4 as uuidV4 } from 'uuid';
import { IUseFunctions } from './interfaces';

export const useProductFunctions = ({
  index,
  totalRows,
  setProductRowAmount,
  refetchSearchProductPage,
}: IUseFunctions) => {
  const addRow = () => {
    if (index === totalRows - 1) {
      setProductRowAmount((oldState: string[]) => [...oldState, uuidV4()]);
    }
  };

  const onSearch = (search: string | undefined) =>
    refetchSearchProductPage({
      search: search,
    });

  return {
    addRow,
    onSearch,
  };
};

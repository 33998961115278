import { FC } from 'react';
import { Form } from '../../../../../Components';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { FormikContextType, useFormikContext } from 'formik';
import _ from 'lodash';
import { DescriptionRow } from './Components/Rows/DescriptionRow';
import { FooterButtons } from './Components/FooterButtons';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { useTranslation } from 'react-i18next';
import { IEditPackingClassification } from '@/Data/Interfaces/request/PackingClassification/IPackingClassificationRequest';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { CreateSchema } from '../Create/CreateForm.schema';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const PackingClassificationDetail: FC<IDetailsForm> = ({ disabled }) => {
  const form: FormikContextType<IEditPackingClassification> =
    useFormikContext();

  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { editPackingClassification, loading, getPackingClassification } =
    useEditCallAPI({ externalId });

  const { onSubmit } = useEditFunctions({
    editPackingClassification,
    getPackingClassification,
  });

  return (
    <PageLoading loading={loading}>
      <Form
        initialValues={{
          ...getPackingClassification,
        }}
        onSubmit={onSubmit}
        schema={CreateSchema}
      >
        <>
          <DescriptionRow
            disabled={disabled}
            descriptionPackingClassification={
              getPackingClassification?.descricao
            }
          />

          {!disabled && <FooterButtons />}
          <ConnectedFocusError />
        </>
      </Form>
    </PageLoading>
  );
};

export default PackingClassificationDetail;

import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { ModalBody } from './Body';
import { ICreateServiceModal } from './interfaces';
import { CreateOrderEditSchema } from './CreateOrder.schema';

export const CreateServiceModal: FC<ICreateServiceModal> = ({
  changeVisible,
  visible,
  clienteExternalId,
  onSubmit,
  isLoading,
  t,
}) => (
  <Modal
    onOkClickForm={onSubmit}
    title={t('callCenter.createService.newService')}
    body={<ModalBody />}
    visible={visible}
    onCancelClick={() => changeVisible(!visible)}
    htmlType="submit"
    loading={isLoading}
    onClose={() => changeVisible(!visible)}
    withForm
    minWidth={475}
    initialValues={{ clienteExternalId }}
    noLeaveWithoutLeaving
    dataTestIdOkButton="create-button-ok"
    okButtonName={t('common.create')}
    schema={CreateOrderEditSchema}
  />
);

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '@/Components';
import { IProduct } from './interfaces';
import { useProductCallAPI } from './Hooks/useProductCallAPI';
import { useProductCallFunctions } from './Hooks/useProductCallFunctions';
import { useProductCallMemorizations } from './Hooks/useProductCallMemorizations';

export const Product: FC<IProduct> = ({
  index,
  setProductRowAmount,
  uuidV4,
  totalRows,
  refetchUnitsMesurements,
  setClasseProdutoId,
}) => {
  const { t } = useTranslation();

  const { productList, fetchNewProductPage, refetchSearchProductPage } =
    useProductCallAPI({ index });

  const { onChange, onSearch } = useProductCallFunctions({
    index,
    totalRows,
    setProductRowAmount,
    uuidV4,
    refetchSearchProductPage,
    refetchUnitsMesurements,
    setClasseProdutoId,
  });

  const { items, label } = useProductCallMemorizations({
    productList,
    index,
  });

  return (
    <InputWithSearch
      name={`pedidoCompraItens.${index}.produtoExternalId`}
      items={items}
      onChange={(_, item) => onChange(item)}
      label={label}
      required
      placeHolder={t(
        'purchasing.purchaseOrder.create.productsCardPlaceHolders.product'
      )}
      onScrollEnd={fetchNewProductPage}
      withoutMarginBottom
      onSearch={onSearch}
    />
  );
};

import { isNumeric } from '@/Utils/IsNumericUtils';
import { IUseFunctions } from './interfaces';

export const useUnitaryDiscountFunctions = ({
  setTotal,
  getTotalValue,
}: IUseFunctions) => {
  const onChange = (x: any) =>
    setTotal(
      getTotalValue({
        newDiscountValue: parseFloat(isNumeric(x) ? x : 0),
      })
    );

  return {
    onChange,
  };
};

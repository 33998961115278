import { TextInput, Tooltip } from '@/Components';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDosageInputsCallAPI } from './Hooks/useDosageInputsCallAPI';
import { useDosageInputsStates } from './Hooks/useDosageInputsStates';
import { useDosageInputsMemorizations } from './Hooks/useDosageInputsMemorizations';
import { useDosageInputsFunctions } from './Hooks/useDosageInputsFunctions';
import { IDosageInputs } from './interfaces';

export const DosageInputs: FC<IDosageInputs> = ({
  initialValues,
  disabledForm,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { unitAbbreviation, setUnitAbbreviation, ratio, setRatio } =
    useDosageInputsStates();

  const { unitMeasurementList } = useDosageInputsCallAPI();

  const { dropdownRightMinimalDosage } = useDosageInputsMemorizations({
    unitMeasurementList,
    setUnitAbbreviation,
  });

  const { onChangeMaximalDosage } = useDosageInputsFunctions();

  useEffect(() => {
    if (form.values.diluicao) {
      const ratio = 100 / form.values.diluicao;
      const finalResult = Math.round(ratio);
      setRatio(`1:${finalResult}`);
    } else {
      setRatio('');
    }
  }, [form.values.diluicao]);

  return (
    <>
      <Col span={3}>
        <Tooltip
          title={`${t('products.diluted.originalMinimalDosage')}${
            initialValues?.dosagemMinima
          }`}
          showMe={
            initialValues &&
            initialValues.dosagemMinima !== form.values?.dosagemMinima
          }
        >
          <WeightInput
            required={!disabledForm}
            name="dosagemMinima"
            withStatusIcon
            label={t('products.diluted.minimalDosage')}
            dropdownRight={dropdownRightMinimalDosage}
            placeHolder="0,000"
            disabled={disabledForm || form.values.seQualquerDosagem}
          />
        </Tooltip>
      </Col>
      <Col span={3}>
        <Tooltip
          title={`${t('products.diluted.originalMaximalDosage')}${
            initialValues?.dosagemMaxima
          }`}
          showMe={
            initialValues &&
            initialValues.dosagemMaxima !== form.values?.dosagemMaxima
          }
        >
          <WeightInput
            name="dosagemMaxima"
            withStatusIcon
            required={!disabledForm}
            label={t('products.diluted.maximalDosage')}
            placeHolder="0,000"
            rightIcon={{
              titleString: unitAbbreviation || 'mg',
            }}
            disabled={disabledForm || form.values.seQualquerDosagem}
            onChange={onChangeMaximalDosage}
          />
        </Tooltip>
      </Col>
      <Col span={2}>
        <Tooltip
          title={`${t('products.diluted.originalDilution')}${
            initialValues?.diluicao
          }`}
          showMe={
            initialValues && initialValues.diluicao !== form.values?.diluicao
          }
        >
          <PercentageInput
            withStatusIcon
            required={!disabledForm}
            name="diluicao"
            label={'Diluição'}
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('supply.lot.create.toolTips.internalDilution'),
            }}
            placeHolder="0.0000"
            disabled={disabledForm}
          />
        </Tooltip>
      </Col>
      <Col span={2}>
        <TextInput
          name="ratio"
          label={t('products.diluted.ratio')}
          placeHolder="0.0000"
          disabled
          value={ratio}
        />
      </Col>
    </>
  );
};

import { IItems } from '@/Components/Inputs/InputWithSearch/Interface';
import { IListProviderData } from '../../../../../../Data/Interfaces/response/Provider/IProviderResponse';
import {
  ICfopListData,
  ICstListData,
  INcmListData,
} from '../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { INatureOfOperationListData } from '../../../../../../Data/Interfaces/response/Purchase/INatureOfOperationResponse';
import DateUtils from '../../../../../../Utils/DateUtils';

export const listProviderConfig = (listProvider: IListProviderData[]) => {
  const newListProvider: any = listProvider;

  if (!newListProvider) return [];
  if (newListProvider.data) {
    const mapResult = newListProvider.data.map((value: any) => ({
      id: value.externalId,
      label: value.nome,
      subLabel: `${value.tipoPessoaDescricao} - ${value.tipoFornecedor}`,
    }));

    return mapResult;
  }

  const mapResult = listProvider.map((value) => ({
    id: value.externalId,
    label: value.nome,
    subLabel: `${value.tipoPessoaDescricao} - ${value.tipoFornecedor}`,
  }));

  return mapResult;
};

export const configNcmList = (listNcm: INcmListData[]): IItems[] => {
  const newListNcm: any = listNcm;
  if (!listNcm) return [];

  if (newListNcm.data) {
    const mapResult = newListNcm.data.map((value: any) => ({
      id: value.id,
      label: `${value.codigo}`,
      subLabel: value.descricao,
    }));

    return mapResult;
  }

  const mapResult = listNcm.map((value) => ({
    id: value.id,
    label: `${value.codigo}`,
    subLabel: value.descricao,
  }));

  return mapResult;
};

export const configCfopList = (listCfop: ICfopListData[]): IItems[] => {
  const newListCfop: any = listCfop;

  if (!listCfop) return [];

  if (newListCfop.data) {
    const mapResult = newListCfop.data.map((value: any) => ({
      id: value.id,
      label: `${value.codigo}`,
      subLabel: value.descricao,
    }));

    return mapResult;
  }

  const mapResult = listCfop.map((value) => ({
    id: value.id,
    label: `${value.codigo}`,
    subLabel: value.descricao,
  }));

  return mapResult;
};

export const configCstList = (listCst: ICstListData[]): IItems[] => {
  const newListCst: any = listCst;

  if (!listCst) return [];

  if (newListCst.data) {
    const mapResult = newListCst.data.map((value: any) => ({
      id: value.id,
      label: `${value.codigo}`,
      subLabel: value.descricao,
    }));

    return mapResult;
  }

  const mapResult = listCst.map((value) => ({
    id: value.id,
    label: `${value.codigo}`,
    subLabel: value.descricao,
  }));

  return mapResult;
};

export const configNatureOfOperationList = (
  listNatureOfOperation: INatureOfOperationListData[]
): IItems[] => {
  const newListNatureOfOperation: any = listNatureOfOperation;
  if (!listNatureOfOperation) return [];

  if (newListNatureOfOperation.data) {
    const mapResult = newListNatureOfOperation.data.map((value: any) => ({
      id: value.id,
      label: `${value.descricao}`,
    }));

    return mapResult;
  }

  const mapResult = listNatureOfOperation.map((value) => ({
    id: value.id,
    label: `${value.descricao}`,
  }));

  return mapResult;
};

export const configInvoiceValues = (values: any) => {
  if (!values.valorBaseIcms) values.valorBaseIcms = 0;
  if (!values.valorIcms) values.valorIcms = 0;
  if (!values.valorBaseIcmsSubstituicao) values.valorBaseIcmsSubstituicao = 0;
  if (!values.valorIcmsSubstituicao) values.valorIcmsSubstituicao = 0;
  if (!values.valorFrete) values.valorFrete = 0;
  if (!values.valorSeguro) values.valorSeguro = 0;
  if (!values.valorDesconto) values.valorDesconto = 0;
  if (!values.valorOutrasDespesas) values.valorOutrasDespesas = 0;
  if (!values.valorIpi) values.valorIpi = 0;
  return values;
};

export const configInitialValues = (values: any) => {
  return {
    ...values,
    externalId: values?.externalId ?? '',
    cnpj: values?.cnpj ?? '',
    dataEmissao: values?.dataEmissao
      ? DateUtils.format(values?.dataEmissao, 'dd/MM/yyyy')
      : '',
    dataEntrega: values?.dataEntrega
      ? DateUtils.format(values?.dataEntrega, 'dd/MM/yyyy')
      : '',
    fornecedorExternalId: values?.fornecedorExternalId ?? '',
    informacoesComplementares: values?.informacoesComplementares ?? '',
    itens: values?.itens
      ? values.itens.map((e: any) => ({
          ...e,
          codProduto: e.produtoCodigo,
          unidadeId: e.unidadeMedidaId,
          ncmId: e.ncmId ? e.ncmId : undefined,
          cstCsosnId: e.cstCsosnId ? e.cstCsosnId : undefined,
          cfopId: e.cfopId ? e.cfopId : undefined,
        }))
      : [],
    naturezaOperacaoExternalId: values?.naturezaOperacaoExternalId ?? '',
    numero: values?.numero ? `${values.numero}` : '',
    razaoSocial: values?.razaoSocial ?? '',
    serie: values?.serie ?? '',
    tipoFreteId: values?.tipoFreteId ?? '',
    transportadoraExternalId: values?.transportadoraExternalId ?? '',
    valorBaseIcms: values?.valorBaseIcms ?? '',
    valorBaseIcmsSubstituicao: values?.valorBaseIcmsSubstituicao ?? '',
    valorDesconto: values?.valorDesconto ?? '',
    valorFrete: values?.valorFrete ?? '',
    valorIcms: values?.valorIcms ?? '',
    valorIcmsSubstituicao: values?.valorIcmsSubstituicao ?? '',
    valorIpi: values?.valorIpi ?? '',
    valorOutrasDespesas: values?.valorOutrasDespesas ?? '',
    valorSeguro: values?.valorSeguro ?? '',
    valorProdutos: values?.valorProdutos ?? '',
    valorTotalNotaFiscal: values?.valorTotalNotaFiscal ?? '',
    naturezaOperacaoId: values?.naturezaOperacaoId ?? '',
    pedidosExternalId: values?.pedidosVinculados.map(
      (e: any) => e.pedidoCompraExternalId
    ),
  };
};

export const formatUnitNumberOnPurchaseOrderList = (value: number) => {
  const formattedValue = value.toFixed(4);

  return formattedValue.replace(/\.(\d+)$/, (_, p1) => {
    const zerosToAdd = 4 - p1.length;
    const zeros = '0'.repeat(zerosToAdd);
    return `.${p1}${zeros}`;
  });
};

export const configInitialPurchaseOrders = (values: any) => {
  const mapResult = values.map((e: any) => {
    return {
      ...e,
      externalId: e.pedidoCompraExternalId,
      itens: [],
    };
  });

  return mapResult;
};

import { GET_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { IUseCallAPI } from './interfaces';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetClientResponse } from '@/Data/Interfaces/response/Client/IClientResponse';
import { ClientAPI } from '@/Data/API/People/ClientApi';

export const useEditClientCallAPI = ({ externalId }: IUseCallAPI) => {
  const { data: client } = useControllerQueryApiHook<IGetClientResponse>({
    uniqId: GET_CLIENT,
    entityApi: ClientAPI.getClient,
    externalId: [externalId]
  });

  return { client };
};
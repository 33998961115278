import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_PHARMACEUTICAL_FORM,
  LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
} from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IUseCallAPI } from './interfaces';
import { THERE_IS_POSOLOGY_TYPE } from '@/ControllerApiHook/UniqIds/Production/PosologyKeys';
import { PosologyAPI } from '@/Data/API/Production/Posology';

export const useCreateCallAPI = ({
  pharmaceuticaFormExternalId,
  form,
  t,
}: IUseCallAPI) => {
  const {
    data: listPharmaceuticalForm,
    isLoading: isLoadingPharmaceuticalFormList,
    fetchNewPage: fetchNewPharmaceuticalFormListPage,
    refetch: refetchSearchPharmaceuticalFormListPage,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
    },
  });

  const { data: pharmaceuticaFormDetail } = useControllerQueryApiHook({
    uniqId: FETCH_GET_PHARMACEUTICAL_FORM,
    entityApi: PharmaceuticalFormAPI.getCurrentPharmaceuticalForm,
    externalId: [pharmaceuticaFormExternalId],
  });

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_POSOLOGY_TYPE,
    entityApi: PosologyAPI.isPosologyNameAlreadyRegistred,
    options: {
      onSuccess: () => {
        form?.setErrors({
          ...form?.errors,
          descricao: undefined,
        });
      },
      onError: () => {
        form?.setErrors({
          ...form?.errors,
          descricao: t(
            'production.posology.create.errors.errorPosologyNameExist'
          ),
        });
      },
    },
  });

  return {
    listPharmaceuticalForm,
    isLoadingPharmaceuticalFormList,
    fetchNewPharmaceuticalFormListPage,
    refetchSearchPharmaceuticalFormListPage,
    pharmaceuticaFormDetail,
    mutateAsync,
  };
};

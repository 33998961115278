import { Button, Icon, Tag, Text, Tooltip } from '@/Components';
import { IconName } from '@/Components/Icon/IconClasses';
import { Divflex } from '@/Layouts/DivWhithFlex';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CreateSynonymForComponent } from './Modal/CreateSynonymForComponent';
import { FormikContextType } from 'formik';
import {
  IconTooltip,
  TextInputState,
} from '@/Components/StateInputs/TextInput';
import { Placements } from '@/Components/Tooltip';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { QSPType } from '../OptionsButton';
import { GET_STANDARD_FORMULA } from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { ComponentType, ComponentTypeIcon } from '../..';
import { Col } from 'antd';
import { AmountComponentOnStandardFormula } from '../AmountComponent';
import _ from 'lodash';
import { VALIDATE_SYNONYM } from '@/ControllerApiHook/UniqIds/Supply/SynonymKeys';
import { IValidateSynonymResponse } from '@/Data/Interfaces/response/Synonym/ISynonymResponse';
import { SynonymExistModal } from './Modal/SynonymExistModal';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SynonymAPI } from '@/Data/API/Supply/SynonymApi';

import styles from './SelectedComponent.module.scss';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetStandardFormula } from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';

interface ISelectedComponent {
  productExternalId: string;
  icon?: IconName;

  standardFormulaExternalId?: string;

  editingSynonymComponent?: boolean;
  setEditingSynonymComponent: Dispatch<SetStateAction<boolean>>;

  visibleCreateSynonymModal: boolean;
  setVisibleCreateSynonymModal: Dispatch<SetStateAction<boolean>>;

  index: number;

  form: FormikContextType<IPostManipulatedRecipeRequest>;

  showSubComponents?: boolean;
  setShowSubComponents?: Dispatch<SetStateAction<boolean>>;
  hideSubComponent: boolean;
  setHideSubComponent: Dispatch<SetStateAction<boolean>>;

  componentType?: number;
}

export const SelectedComponent: FC<ISelectedComponent> = ({
  productExternalId,
  icon,

  standardFormulaExternalId,

  editingSynonymComponent,
  setEditingSynonymComponent,

  visibleCreateSynonymModal,
  setVisibleCreateSynonymModal,

  index,

  form,

  showSubComponents,
  setShowSubComponents,
  hideSubComponent,
  setHideSubComponent,

  componentType,
}) => {
  useEffect(() => {
    _.delay(() => setHideSubComponent(!showSubComponents), 350);
  }, [showSubComponents, setHideSubComponent]);

  const standardFormula = useControllerQueryApiHook<IGetStandardFormula>({
    uniqId: GET_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormula,
    externalId: [standardFormulaExternalId],
  });

  return standardFormula.data ? (
    <div>
      <ComponentLineSelected
        form={form}
        index={index}
        productExternalId={productExternalId}
        setEditingSynonymComponent={setEditingSynonymComponent}
        setVisibleCreateSynonymModal={setVisibleCreateSynonymModal}
        visibleCreateSynonymModal={visibleCreateSynonymModal}
        editingSynonymComponent={editingSynonymComponent}
        icon={icon}
        setShowSubComponents={setShowSubComponents}
        dropdownRightIcon
        showSubComponents={showSubComponents}
      />
      {standardFormula.data.produtos.map((x) => {
        return (
          <Divflex
            className={`${styles['formula-component']} ${
              !showSubComponents ? styles['hide'] : ''
            } ${
              hideSubComponent && !showSubComponents
                ? styles['hide-display']
                : ''
            }`}
            key={x.externalId}
          >
            <Col flex={'5%'}>
              <div className={styles['formula-component-sub-item']} />
            </Col>

            <TextInputState
              withoutMarginBottom
              value={x.descricaoProduto}
              disabled
              leftIcon={{ icon: ComponentTypeIcon[x.tipoItem] }}
            />

            <Col flex={'22.5%'} className={styles['col-amount']}>
              <AmountComponentOnStandardFormula standardFormula={x} />
            </Col>
          </Divflex>
        );
      })}
    </div>
  ) : (
    <ComponentLineSelected
      form={form}
      index={index}
      productExternalId={productExternalId}
      setEditingSynonymComponent={setEditingSynonymComponent}
      setVisibleCreateSynonymModal={setVisibleCreateSynonymModal}
      visibleCreateSynonymModal={visibleCreateSynonymModal}
      editingSynonymComponent={editingSynonymComponent}
      icon={icon}
      componentType={componentType}
    />
  );
};

interface IComponentLineSelected
  extends Omit<ISelectedComponent, 'hideSubComponent' | 'setHideSubComponent'> {
  dropdownRightIcon?: boolean;
}

const ComponentLineSelected: FC<IComponentLineSelected> = ({
  editingSynonymComponent,
  setVisibleCreateSynonymModal,
  setEditingSynonymComponent,
  productExternalId,
  visibleCreateSynonymModal,
  index,
  form,
  icon,

  dropdownRightIcon,
  setShowSubComponents,
  showSubComponents,

  componentType,
}) => {
  const [synonymDescription, setSynonymDescription] = useState(
    form.values.itens[index].description
  );
  const [synonymExistModalVisible, setSynonymExistModalVisible] =
    useState(false);

  const validateSynonym: any = useControllerMutateApiHook({
    uniqId: VALIDATE_SYNONYM,
    entityApi: SynonymAPI.validateSynonym,
    options: {
      onSuccess: (x: IValidateSynonymResponse) => {
        if (x.descricaoExiste) {
          form.setFieldValue(
            `itens[${index}].produtoSinonimoExternalId`,
            x.produtoSinonimoExternalId
          );
          form.setFieldValue(`itens[${index}].itemReceitaDescricao`, undefined);
          setSynonymExistModalVisible(true);
        } else setVisibleCreateSynonymModal(true);
      },
    },
  });

  const selectedComponenti18n = useMemo(
    () =>
      'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components.selectedComponent',
    []
  );

  const { t } = useTranslation();

  const leftIcon: {
    icon?: IconName;
    tooltip?: {
      title: ReactNode;
      placement?: Placements;
    };
  } = useMemo(() => {
    return {
      icon,
      tooltip: {
        title: `${t('common.code')}: ${form.values.itens[index].code}`,
        placement: 'top',
      },
    };
  }, [form.values.itens, t, icon, index]);

  const rightIcon: IconTooltip[] = useMemo(() => {
    const synonym: IconTooltip[] = form?.values?.itens[index]
      .itemReceitaDescricao
      ? [
          {
            icon: 'rename',
            tooltip: {
              title: `${t(selectedComponenti18n + '.originalName')} ${
                form.values.itens[index].initialDescription
              }.`,
              placement: 'top',
            },
          },
        ]
      : form?.values?.itens[index].produtoSinonimoExternalId
      ? [
          {
            icon: 'synonym-square',
            tooltip: {
              title: `${t(selectedComponenti18n + '.synonymSquare')} ${
                form.values.itens[index].description
              }.`,
              placement: 'top',
            },
          },
        ]
      : [];

    const hideInLabel: IconTooltip[] = form?.values?.itens[index].ocultaRotulo
      ? [
          {
            icon: 'close-eye',
            tooltip: {
              title: t(selectedComponenti18n + '.closeEye'),
              placement: 'top',
            },
          },
        ]
      : [];

    return synonym?.concat(hideInLabel);
  }, [form?.values?.itens, index, selectedComponenti18n, t]);

  return (
    <Divflex>
      {editingSynonymComponent ? (
        <Tooltip
          style={{ width: '100%' }}
          title={`${t(selectedComponenti18n + '.originalName')} ${
            form.values.itens[index].description
          }.`}
          showMe={editingSynonymComponent}
          placement="top"
        >
          <TextInputState
            withoutMarginBottom
            withoutFocusCss
            defaultValue={form.values.itens[index].description}
            onChange={(x) => setSynonymDescription(x)}
            leftIcon={leftIcon}
          />
        </Tooltip>
      ) : (
        <SelectedComponentStatic
          value={form.values.itens[index].description}
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          index={index}
          form={form}
          setShowSubComponents={setShowSubComponents}
          showSubComponents={showSubComponents}
          dropdownRightIcon={dropdownRightIcon}
        />
      )}
      {editingSynonymComponent && (
        <Divflex>
          <Button
            className={styles['aprove-button']}
            type="secondary"
            leftIcon="check"
            status="success"
            loading={validateSynonym.isLoading}
            onClick={() => {
              if (
                componentType !== ComponentType.formula &&
                componentType !== ComponentType.rawMaterial
              ) {
                form.setFieldValue(
                  `itens[${index}].itemReceitaDescricao`,
                  synonymDescription
                );
                form.setFieldValue(
                  `itens[${index}].descricao`,
                  synonymDescription
                );

                setEditingSynonymComponent(false);
              } else
                synonymDescription &&
                  validateSynonym.mutateAsync(synonymDescription);
            }}
          />
          <Button
            type="secondary"
            leftIcon="close-x"
            status="danger"
            onClick={() => setEditingSynonymComponent(false)}
          />
        </Divflex>
      )}
      <CreateSynonymForComponent
        productExternalId={productExternalId}
        form={form}
        synonymDescription={synonymDescription}
        changeVisible={setVisibleCreateSynonymModal}
        visible={visibleCreateSynonymModal}
        index={index}
        setEditingSynonymComponent={setEditingSynonymComponent}
      />
      <SynonymExistModal
        visible={synonymExistModalVisible}
        form={form}
        index={index}
        synonymDescription={synonymDescription}
        setEditingSynonymComponent={setEditingSynonymComponent}
        setSynonymExistModalVisible={setSynonymExistModalVisible}
      />
    </Divflex>
  );
};

interface ISelectedComponentStatic {
  value?: string;
  leftIcon: IconTooltip;
  rightIcon: IconTooltip[];

  index: number;

  form: FormikContextType<IPostManipulatedRecipeRequest>;

  dropdownRightIcon?: boolean;
  setShowSubComponents?: Dispatch<SetStateAction<boolean>>;
  showSubComponents?: boolean;
}

const SelectedComponentStatic: FC<ISelectedComponentStatic> = ({
  leftIcon,
  rightIcon,
  value,

  index,

  form,

  dropdownRightIcon,
  setShowSubComponents,
  showSubComponents,
}) => (
  <Divflex className={styles['component-static']}>
    <Divflex>
      {form.values.itens[index].tipo ? (
        <div className={styles['left-Icon']}>
          <Tooltip
            title={leftIcon.tooltip?.title}
            showMe
            placement={leftIcon.tooltip?.placement}
          >
            <Icon icon={leftIcon.icon} size="M" color="text-50" />
          </Tooltip>
        </div>
      ) : null}
      <Text type="ui-tiny-content" children={value} />
      {(form.values.itens[index].tipo === QSPType.qsp ||
        form.values.itens[index].tipo === QSPType.qs) && (
        <Tag
          className={styles['tag-qsp']}
          children={
            form.values.itens[index].tipo === 0
              ? 'Q.S.P'
              : form.values.itens[index].tipo === 1 && 'Q.S'
          }
          type="secondary"
        />
      )}
    </Divflex>
    <Divflex className={styles['right-icon']}>
      {rightIcon
        ? rightIcon.map((x) => {
            return (
              <Tooltip
                title={x.tooltip?.title}
                showMe
                placement={x.tooltip?.placement}
              >
                <Icon
                  icon={x.icon}
                  size="M"
                  color="text-400"
                  onClick={x.onClick}
                />
              </Tooltip>
            );
          })
        : null}
      {dropdownRightIcon && (
        <Icon
          icon={showSubComponents ? 'chevron-up' : 'chevron-down'}
          size="M"
          color="text-400"
          onClick={() =>
            setShowSubComponents && setShowSubComponents((x) => !x)
          }
        />
      )}
    </Divflex>
  </Divflex>
);

import { IChangeSpecialtiesStatusRequest } from '@/Data/Interfaces/request/Specialties/ISpecialtiesRequest';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { cannotDeleteSpecialtyWithBondProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalSpecialties';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { IListSpecialtiesData } from '@/Data/Interfaces/response/Specialties/ISpecialtiesResponse';

export const useListFunctions = ({
  changeSpecialtiesStatus,
  deleteSpecialties,
  setSelectedRowsOnTable,
  setCreateModalOpen,
  createSpecialties,
  specialtiesName,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const changeStatus = useCallback(
    async (values: IChangeSpecialtiesStatusRequest) => {
      await changeSpecialtiesStatus(values);
    },
    [changeSpecialtiesStatus]
  );

  const deleteSpecialtiesCallback = useCallback(
    async (externalId: string[]) => {
      await deleteSpecialties(
        {
          especialidadePrescritorExternalId: externalId,
        },
        {
          onError: handleModalError(app, cannotDeleteSpecialtyWithBondProps(t)),
        }
      );
    },
    [app, t, deleteSpecialties]
  );

  const onEdit = useCallback(
    (specialties: IListSpecialtiesData[], values: any) =>
      changeStatus({
        especialidadePrescritorExternalId: specialties.map((x) => x.externalId),
        ativo: values.status === 'success',
      }),
    [changeStatus]
  );

  const onDelete = useCallback(
    (specialties: IListSpecialtiesData[]) =>
      deleteSpecialtiesCallback(specialties.map((value) => value.externalId)),
    [deleteSpecialtiesCallback]
  );

  const selectRowsOnTable = useCallback(
    (x: IListSpecialtiesData[]) => setSelectedRowsOnTable(x),
    [setSelectedRowsOnTable]
  );

  const onCancelClick = useCallback(
    () => setCreateModalOpen(false),
    [setCreateModalOpen]
  );

  const onClose = useCallback(
    () => setCreateModalOpen(false),
    [setCreateModalOpen]
  );

  const onOkClick = useCallback(
    () => setCreateModalOpen(false),
    [setCreateModalOpen]
  );

  const onOkClickForm = useCallback(
    async (_: any, helpers: any) => {
      await createSpecialties({
        descricao: specialtiesName,
        ativo: true,
        helpers,
      });
    },
    [createSpecialties, specialtiesName]
  );

  return {
    changeStatus,
    deleteSpecialtiesCallback,
    onEdit,
    onDelete,
    selectRowsOnTable,
    onCancelClick,
    onClose,
    onOkClick,
    onOkClickForm,
  };
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Card, Form } from '../../../../../Components';
import _ from 'lodash';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { FooterButtons } from './Components/FooterButtons';
import { DescriptionRow } from './Components/Rows/DescriptionRow';
import { CreateSchema } from '../Create/CreateForm.schema';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const CapsuleColorDetail: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { editCapsuleColor, isLoadingEditCapsuleColor, capsuleColor, loading } =
    useEditCallAPI({
      externalId,
      t,
    });

  const { onSubmit } = useEditFunctions({ editCapsuleColor, capsuleColor, t });

  return (
    <PageLoading loading={loading}>
      <Form
        initialValues={{
          ...capsuleColor,
          corCapsula: capsuleColor?.corCapsula,
          transparente: capsuleColor?.transparente,
        }}
        onSubmit={onSubmit}
        schema={CreateSchema}
      >
        {(props) => (
          <>
            <Card title={t('common.details')} withoutCollapse={true}>
              <DescriptionRow
                disabled={disabled}
                props={props}
                capsuleCor={capsuleColor?.corCapsula}
              />
            </Card>
            {!disabled && (
              <FooterButtons
                isLoadingEditCapsuleColor={isLoadingEditCapsuleColor}
                onChangeDisabled={onChangeDisabled}
              />
            )}
            <ConnectedFocusError />
          </>
        )}
      </Form>
    </PageLoading>
  );
};

export default CapsuleColorDetail;

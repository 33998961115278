import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY_BY_STOCK_LOCATION } from '@/ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { useParams } from 'react-router';

export const useListCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  //TODO: entender melhor para que está utilizando esse optionalParams
  const {
    data: inventoryBySupplyPlaceList,
    isLoading: isLoadingInventoryBySupplyPlaceList,
    refetch: refetchInventorySupplyPlaceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_GET_INVENTORY_BY_STOCK_LOCATION,
    entityApi: StorageLocationApi.getInventoryByStockLocationList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        {
          filterName: 'localEstoqueExternalId',
          value: externalId,
        },
      ],
    },
    optionalParams: [externalId],
    autoIncrement: true,
  });

  return {
    inventoryBySupplyPlaceList,
    isLoadingInventoryBySupplyPlaceList,
    refetchInventorySupplyPlaceList,
  };
};

import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Create.module.scss';

interface IFooterButtons {
  isLoadingEditPosology: boolean;
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditPosology,
  disabled,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(!disabled);
        }}
      />
      <Button
        type="primary"
        children={isLoadingEditPosology ? t('common.saving') : t('common.save')}
        loading={isLoadingEditPosology}
        htmlType="submit"
      />
    </div>
  );
};

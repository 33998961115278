import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../../../../../../Utils/DateUtils';
import StorageUtils from '@/Utils/StorageUtils';
import { FETCH_GET_LOSSE } from '../../../../../../../ControllerApiHook/UniqIds/Supply/LosseKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';
import { ILossDetailResponse } from '@/Data/Interfaces/response/Loss/ILossDetailResponse';

export const DynamicEditLosseBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();
  const preferences = StorageUtils.getPreference();

  const { data: loss } = useControllerQueryApiHook<ILossDetailResponse>({
    uniqId: FETCH_GET_LOSSE,
    entityApi: LossesAPI.getLoss,
    externalId: [match.params.externalId]
  });

  if (preferences?.padraoData === 'dd/mm/yyyy') {
    return (
      <span style={{ display: 'flex' }}>
        {`${loss?.descricaoProduto} | ${DateUtils.format(
          loss?.dataLancamento || '',
          'dd/MM/yyyy HH:mm'
        )} ${t('common.complementDateHour')} ${DateUtils.format(
          loss?.dataLancamento || '',
          'HH:mm'
        )}`}
      </span>
    );
  } else {
    return (
      <span style={{ display: 'flex' }}>
        {`${loss?.descricaoProduto} | ${DateUtils.format(
          loss?.dataLancamento || '',
          'MM/dd/yyyy HH:mm'
        )} ${t('common.complementDateHour')} ${DateUtils.format(
          loss?.dataLancamento || '',
          'HH:mm'
        )}`}
      </span>
    );
  }
};

export const DynamicEditLosseBreadcrumbTitle = ({ match }: any) => {
  const { t } = useTranslation();

  const { data: losse } = useControllerQueryApiHook<ILossDetailResponse>({
    uniqId: FETCH_GET_LOSSE,
    entityApi: LossesAPI.getLoss,
    externalId: [match.params.externalId]
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('supply.losses.detail.title')} | ${losse?.descricaoProduto}`}
      <PermissionWrapper
        permission={1005}
        tooltip="noPermission.administrative.users.statusEdit"
      ></PermissionWrapper>
    </div>
  );
};

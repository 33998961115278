import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { DeleteModal } from '../Modal/deleteModal';

export const useListMemorizations = ({
  posologyList,
  selectedRowsOnTable,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('production.posology.list.newButton'),
      onClick: () => history.push('/production/posology/create'),
      permission: {
        permission: PermissionEnum.Producao_Posologia_Cadastrar,
        tooltip: 'noPermission.production.posology.create',
      },
      dataTestId: 'production-posology-new-button',
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_Posologia_Excluir,
        tooltip: 'noPermission.production.posology.delete',
      },
    },
    editButton: {
      options: [
        {
          label: t('common.status'),
          isStatusOnly: true,
          permission: {
            permission: PermissionEnum.Producao_Posologia_AlterarStatus,
            tooltip: 'noPermission.production.posology.statusEdit',
          },
        },
      ],
      permission: {
        permission: PermissionEnum.Producao_Posologia_EditarDetalhes,
        tooltip: 'noPermission.production.posology.edit',
      },
    },
    searchPlaceholder: t('production.posology.list.searchPlaceholder'),
    nameKey: 'descricaoPosologia',
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/production/posology/${x.externalId}/details`),
    permission: {
      permission: PermissionEnum.Producao_Posologia_VerDetalhes,
      tooltip: 'noPermission.production.posology.details',
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('common.delete')} ${
          selectedRowsOnTable.length > 1
            ? `${t('common.theManyPosology')}?`
            : `${t(
                'production.posology.list.modalDelete.descriptionPosology'
              )} "${selectedRowsOnTable[0]?.descricaoPosologia}"?`
        }`,
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.deleteModal.many.title')} ${t('common.thePosology')}:`,
            'descricaoPosologia',
            t('common.posologies')
          )
        ) : (
          <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
    }),
    [selectedRowsOnTable, t]
  );

  const editModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('modal.editModal.many.title.before')} ${
          selectedRowsOnTable.length === 1
            ? `"${selectedRowsOnTable[0]?.descricaoPosologia}"`
            : `${selectedRowsOnTable.length} ${t('common.posologies')}`
        }`,
    }),
    [selectedRowsOnTable, t]
  );

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    editModal,
  };
};

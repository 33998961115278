import { Button } from '@/Components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IFChildren } from '@/Components/Form';
import { IGetProductMessage } from '@/Data/Interfaces/response/ProductMensage/IProductMensageResponse';
import { FormikContextType, useFormikContext } from 'formik';

import styles from './FooterButtons.module.scss';

interface IFooterButtons {
  isLoadingEditProductMessage: boolean;
  props: IFChildren;
  productMessage: IGetProductMessage | undefined;
  onChangeDisabled: (x: boolean) => void;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingEditProductMessage,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<any> = useFormikContext();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          onChangeDisabled(true);
          form.resetForm();
        }}
        style={{ marginRight: '10px' }}
      />
      <Button
        type="primary"
        children={
          isLoadingEditProductMessage ? t('common.saving') : t('common.save')
        }
        loading={isLoadingEditProductMessage}
        htmlType="submit"
      />
    </div>
  );
};

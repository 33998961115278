import { Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { ITechnicalInfoDilutionsRows } from './interfaces';
import { SupplierDilution } from './Components/SupplierDilution';
import { SupplierDilutionFactor } from './Components/SupplierDilutionFactor';
import { WaterConcentration } from './Components/WaterConcentration';
import { WaterConcentrationFactor } from './Components/WaterConcentrationFactor';
import { InternalDilution } from './Components/InternalDilution';
import { InternalDilutionFactor } from './Components/InternalDilutionFactor';
import { BatchSearch } from './Components/BatchSearch';
import { useTechnicalInfoDilutionsRowsCallAPI } from './Hooks/useTechnicalInfoDilutionsRowsCallAPI';
import { ICreateLotRequest } from '@/Data/Interfaces/request/Lot/ILotResquest';

export const TechnicalInfoDilutionsRows: FC<ITechnicalInfoDilutionsRows> = ({
  disabledForm,
}) => {
  const { t } = useTranslation();
  const form: FormikContextType<ICreateLotRequest> = useFormikContext();

  const { lotList, isLotLoading, fetchNewLotPage } =
    useTechnicalInfoDilutionsRowsCallAPI({ form });

  return (
    <>
      <Row gutter={[16, 0]} style={{ marginBottom: '22px' }}>
        <SupplierDilution />
        <SupplierDilutionFactor />
        <WaterConcentration />
        <WaterConcentrationFactor />
      </Row>
      <div
        style={{
          borderTop: '1px solid var(--color-background-04)',
          marginBottom: '5px',
        }}
      >
        <Card
          title={t('supply.lot.create.titleInternalDilution')}
          insideCard
          onClose
        >
          <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
            <InternalDilution />
            <InternalDilutionFactor />
            <BatchSearch
              lotList={lotList}
              disabledForm={disabledForm}
              isLotLoading={isLotLoading}
              fetchNewLotPage={fetchNewLotPage}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

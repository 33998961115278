import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '@/Layouts/PageLoading';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { FETCH_LIST_SERVICE_CHANNEL } from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { ticketsCollums } from './tickets.columns';
import StorageUtils from '@/Utils/StorageUtils';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import { List } from '@/Pages/Sectors/Sales/Tickets/List';

interface ICallCenterTab {
  externalId: string;
}
export const ClienteExternalId: FC<ICallCenterTab> = ({ externalId }) => {
  const preferences = StorageUtils.getPreference();
  const { t } = useTranslation();

  const { data: serviceChannel } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SERVICE_CHANNEL,
    entityApi: ServiceAPI.listServiceChannel,
    pagination: {
      pageSize: 50,
    },
  });

  return (
    <PageLoading>
      <ContainerPage>
        <List
          filterName="clienteExternalId"
          externalId={externalId}
          serviceCollums={ticketsCollums(t, preferences, serviceChannel?.data)}
        />
      </ContainerPage>
    </PageLoading>
  );
};

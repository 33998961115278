import { FC } from "react";
import {
  InputWithSearch,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IDcb } from "./interfaces";
import { useDcbFunctions } from "./Hooks/useDcbFunctions";
import { useDcbMemorizations } from "./Hooks/useDcbMemoriations";

export const Dcb: FC<IDcb> = ({
  product,
  disabledForm,
  isLoadingDcb,
  fetchNewDcbPage,
  refetchSearchDcbPage,
  dcbList
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    onSearch
  } = useDcbFunctions({ refetchSearchDcbPage });
  
  const {
    items
  } = useDcbMemorizations({ dcbList });

  return (
    <Col span={7}>
      <Tooltip
        title={`${t('products.toolTips.products.originalDcb')} ${
          product && product.materiaPrima
            ? product.materiaPrima?.dcbDescricao
            : ''
        }`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.dcbId !== form?.values?.dcbId
        }
      >
        <InputWithSearch
          name="dcbId"
          label={t('products.create.dcb')}
          placeHolder={t('products.create.dcbPlaceholder')}
          isLoading={isLoadingDcb}
          onScrollEnd={fetchNewDcbPage}
          onSearch={onSearch}
          withStatusIcon={!!product}
          items={items}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
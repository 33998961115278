import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  EDIT_LOSS,
  FETCH_GET_LOSSE,
  FETCH_LIST_LOSSES,
} from '@/ControllerApiHook/UniqIds/Supply/LosseKeys';
import { LossesAPI } from '@/Data/API/Supply/LossesApi';
import { ILossDetailResponse } from '@/Data/Interfaces/response/Loss/ILossDetailResponse';
import { IEditCallAPI } from './interfaces';
import StorageUtils from '@/Utils/StorageUtils';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '../../../../../../../../Store';

export const useEditCallAPI = ({ externalId }: IEditCallAPI) => {
  const queryClient = useQueryClient();

  const { data: getLossSingle, isLoading: isLoadingLosse } =
    useControllerQueryApiHook<ILossDetailResponse>({
      uniqId: FETCH_GET_LOSSE,
      entityApi: LossesAPI.getLoss,
      externalId: [externalId],
    });

  const editLoss: any = useControllerMutateApiHook({
    uniqId: EDIT_LOSS,
    entityApi: LossesAPI.updateLoss,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_LOSSES]);
        history.push('/inventory/losses');
      },
    },
  });

  const preferences = StorageUtils.getPreference();

  return { getLossSingle, isLoadingLosse, editLoss, preferences };
};

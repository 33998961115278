import { InputWithSearch, Tooltip } from '@/Components';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { FETCH_SIMPLE_LIST_PRESCRIBERS } from '@/ControllerApiHook/UniqIds/People/PrescribersKeys';
import { Col, Row } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import AddProductUtils from './AddProductUtils';
import { DiscountInput } from '@/Components/Inputs/DiscountInput';
import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  IFinishedProductListData,
  IProductResponse,
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { FormikContextType, useFormikContext } from 'formik';
import DateUtils from '@/Utils/DateUtils';
import { InsufficientFunds } from './InsufficientFundsModal';
import { UseMutationResult } from '@tanstack/react-query';
import {
  convertCurrencyToInteger,
  currencyFormater,
  getCurrencySymbol,
} from '@/Utils/Currency';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { CreateFastPrescriberModal } from './createFastPrescriberModal';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { ISimpleListPrescribersData } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IAddFinishedProductRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { DiscountTypes } from '@/Utils/DiscountTypeDefault';
import _ from 'lodash';
import { IPreferences } from '@/Utils/ThemeUtils';

interface IAddProductModalBody {
  productList: any;
  datePattern?: IPreferences;
  addProduct: UseMutationResult;
  editProduct: UseMutationResult;
  productSelected?: IFinishedProductListData;
  setProductSelected: (value: IFinishedProductListData) => void;
  insufficientFundsModalVisible: boolean;
  setInsufficientFundsModalVisible: (value: boolean) => void;
  editModal: boolean;
}

export const AddProductModalBody: FC<IAddProductModalBody> = ({
  productList,
  addProduct,
  editProduct,
  datePattern,
  productSelected,
  setProductSelected,
  insufficientFundsModalVisible,
  setInsufficientFundsModalVisible,
  editModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useFormikContext();

  const [createFastPrescriber, setCreateFastPrescriber] = useState(false);
  const [prescriberName, setPrescriberName] = useState('');

  const {
    data: prescriberList,
    fetchNewPage,
    refetch,
    isLoading,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_SIMPLE_LIST_PRESCRIBERS,
    entityApi: PrescribersAPI.listPrescribersForDropdown,
    pagination: {
      sorter: { column: 'nomeCompleto', direction: 'DESC' },
    },
  });

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 0]}>
        <Col flex="100%">
          <InputWithSearch
            name="produtoExternalId"
            label={t('saleOrder.editOrder.SaleOrder.addProductModal.product')}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.productPlaceholder'
            )}
            required
            items={productList.data?.data?.map(
              (x: IFinishedProductListData) => ({
                id: x.externalId,
                label: x.descricao,
                subLabel: `${t(
                  'saleOrder.editOrder.SaleOrder.addProductModal.code'
                )}: ${x.codigo} | ${
                  x.saldoEstoque > 0
                    ? `${x.saldoEstoque} un ${
                        x.saldoEstoque === 1
                          ? t(
                              'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaible'
                            )
                          : t(
                              'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaibles'
                            )
                      }`
                    : t(
                        'saleOrder.editOrder.SaleOrder.addProductModal.noBalanceAvailable'
                      )
                }`,
                allItem: x,
              })
            )}
            onChange={(_, item) => {
              form.setFieldValue('valorUnitario', item.valorUnitario);
              setProductSelected(item);
            }}
            onScrollEnd={productList.fetchNewPage}
            isLoading={productList.isLoading}
            onSearch={(search) =>
              productList.refetch({
                search: search,
              })
            }
            dataTestId="product-data-id"
          />
        </Col>
      </Row>
      <PriceRow t={t} productSelected={productSelected} />
      <Row gutter={[16, 0]}>
        <Col flex="70%">
          <InputWithSearch
            name="prescritorExternalId"
            label={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriber'
            )}
            placeHolder={t(
              'saleOrder.editOrder.SaleOrder.addProductModal.prescriberPlaceholder'
            )}
            items={prescriberList?.data?.data?.map(
              (x: ISimpleListPrescribersData) => ({
                id: x.externalId,
                label: x.nomeCompleto,
                subLabel: `${x.siglaRegistro}/${x.siglaRegistro} ${x.codigoRegistro}`,
              })
            )}
            onScrollEnd={fetchNewPage}
            isLoading={isLoading}
            onSearch={(search) =>
              refetch({
                search: search,
              })
            }
            onChangeInputText={(x) => setPrescriberName(x)}
            emptyState={{
              onClick: () => {
                dispatch(NotificationActions.setCurrentFormIsDirty(false));
                setCreateFastPrescriber(true);
              },
              suffix: ` (${t('common.newPrescriber')})`,
            }}
            withoutMarginBottom
          />
        </Col>
        <Col flex="30%">
          <PrescriptionDate datePattern={datePattern} t={t} />
        </Col>
      </Row>
      <InsufficientFunds
        visible={insufficientFundsModalVisible}
        BalanceStock={productSelected?.saldoEstoque}
        productName={productSelected?.descricao}
        addProduct={addProduct}
        editProduct={editProduct}
        editModal={editModal}
        datePattern={datePattern}
        changeVisible={() => setInsufficientFundsModalVisible(false)}
      />
      <CreateFastPrescriberModal
        visible={createFastPrescriber}
        changeVisible={() => setCreateFastPrescriber(false)}
        nameClient={prescriberName}
        t={t}
      />
    </div>
  );
};

interface IPrescriptionDate {
  datePattern?: IPreferences;
  t: TFunction;
}

const PrescriptionDate: FC<IPrescriptionDate> = ({ datePattern, t }) => {
  const [dateGreaterThanCurrentDay, setDateGreaterThanCurrentDay] =
    useState(false);

  const form: any = useFormikContext();

  useEffect(() => {
    const parsedDate = DateUtils.convertStringToDate(
      form.values.dataPrescricao
    );
    setDateGreaterThanCurrentDay(parsedDate > new Date());
  }, [form.values.dataPrescricao]);

  return (
    <Tooltip
      title={t(
        'saleOrder.editOrder.SaleOrder.addProductModal.dateGreaterThanCurrentDay'
      )}
      showMe={dateGreaterThanCurrentDay}
    >
      <TextInputWithMask
        name="dataPrescricao"
        placeHolder={
          datePattern
            ? DateUtils.getDatePlaceholder(
                datePattern?.idioma,
                datePattern.padraoData
              )
            : ''
        }
        label={t(
          'saleOrder.editOrder.SaleOrder.addProductModal.prescriptionDate'
        )}
        mask="dataMask"
        leftIcon="calendar"
        withoutMarginBottom
        error={dateGreaterThanCurrentDay}
      />
    </Tooltip>
  );
};

interface IPriceRow {
  t: TFunction;
  productSelected?: IFinishedProductListData;
}

interface IFormValues extends IAddFinishedProductRequest {
  total?: number;
}

const PriceRow: FC<IPriceRow> = ({ t, productSelected }) => {
  const form: FormikContextType<IFormValues> = useFormikContext();

  useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: [productSelected?.externalId],
    onSuccess: (product: IProductResponse) => {
      form.setFieldValue('valorUnitario', product?.valorCusto);
      form.setFieldValue('tipoDesconto', 2);
    },
  });

  const changeTotalInput = useCallback(
    (form: FormikContextType<IAddFinishedProductRequest>, total?: number) => {
      const currentTotal = total ?? 0;

      const unitValue = form.values.valorUnitario ?? 0;

      const amount = form.values.quantidade ?? 0;

      const discountType = form.values.tipoDesconto;

      const lastValueWithoutDiscount = unitValue * amount;
      if (!lastValueWithoutDiscount) return;

      if (lastValueWithoutDiscount <= currentTotal) {
        form.setFieldValue('total', lastValueWithoutDiscount);
        form.setFieldValue('desconto', undefined);
      } else {
        let discount = 0;
        const currentDiscount = lastValueWithoutDiscount - currentTotal;

        if (amount > 0) discount = currentDiscount / amount;
        else discount = currentDiscount;

        if (discountType === 2) {
          form.setFieldValue('desconto', discount);
        } else {
          form.setFieldValue('desconto', (discount * 100) / unitValue);
        }
      }
    },
    []
  );
  const changeTotalValue = useCallback(
    (
      form: FormikContextType<IAddFinishedProductRequest>,
      prop: {
        unitValue?: number;
        amount?: number;
        discount?: string;
      }
    ) => {
      const unitValue = prop.unitValue ?? form.values.valorUnitario ?? 0;

      const amount = prop.amount ?? form.values.quantidade ?? 1;

      const discount =
        prop.discount !== undefined
          ? convertCurrencyToInteger(prop.discount)
          : form.values.valorDesconto ?? 0;

      const discountType = form && form.values.tipoDesconto;

      const totalWithoutDiscount = unitValue * amount;

      if (!totalWithoutDiscount) return;

      if (_.isNil(discountType))
        form.setFieldValue('total', unitValue * amount);
      else if (discountType === DiscountTypes.currency) {
        const totalDiscount = discount * amount;
        form.setFieldValue('total', totalWithoutDiscount - totalDiscount);
      } else {
        const discountPercentage = discount * unitValue;
        let discountValue = 0;
        if (discountPercentage) discountValue = discountPercentage / 100;

        const totalDiscountPercetage = discountValue * amount;
        form.setFieldValue(
          'total',
          totalWithoutDiscount - totalDiscountPercetage
        );
      }
    },
    []
  );

  return (
    <Row gutter={[16, 0]}>
      <Col flex="25%">
        <NumberInput
          name="valorUnitario"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.value')}
          placeHolder={currencyFormater(0)}
          rightIcon={{ titleString: 'un' }}
          fixedDecimalScale
          decimalScale={2}
          withDecimalSeparator
          withThousandSeparator
          required
          disabled
          prefix={getCurrencySymbol()}
          dataTestId={'unitValue'}
        />
      </Col>
      <Col flex="25%">
        <NumberInput
          name="quantidade"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.amout')}
          placeHolder="0"
          dataTestId="amount-test-id"
          rightIcon={{ titleString: 'un' }}
          decimalScale={0}
          required
          onChange={(amount) => changeTotalValue(form, { amount })}
          disabled={!form.values.produtoExternalId}
          subText={
            productSelected &&
            `${productSelected?.saldoEstoque} un ${
              productSelected?.saldoEstoque === 1
                ? t(
                    'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaible'
                  )
                : t(
                    'saleOrder.editOrder.SaleOrder.addProductModal.balanceAvaibles'
                  )
            }`
          }
          subTextTestId="amount-sub-text"
          withoutMarginBottom
        />
      </Col>
      <Col flex="25%">
        <DiscountInput
          name="desconto"
          label={t(
            'saleOrder.editOrder.SaleOrder.addProductModal.unitDiscount'
          )}
          placeHolder={currencyFormater(0)}
          dataTestId="discount-test-id"
          dropdownLeft={{
            name: 'tipoDesconto',
            onChange: (discountType) =>
              form.setFieldValue(
                'desconto',
                AddProductUtils.getDiscountValue(form, parseInt(discountType))
              ),
            dataTestId: 'discountType',
          }}
          onChange={(discount) => changeTotalValue(form, { discount })}
          withoutPrefix
          withoutSuffix
        />
      </Col>
      <Col
        flex="25%"
        // onBlur={() =>
        //   form.values.valorUnitario * form.values.quantidade < form.values.total
        //     ? form.values.valorUnitario * form.values.quantidade
        //     : form.values.total
        // }
      >
        <NumberInput
          name="total"
          label={t('saleOrder.editOrder.SaleOrder.addProductModal.totalValue')}
          placeHolder={currencyFormater(0)}
          fixedDecimalScale
          decimalScale={2}
          withDecimalSeparator
          withThousandSeparator
          required
          onChange={(x) => changeTotalInput(form, convertCurrencyToInteger(x))}
          error={
            !!form.values.total &&
            form.values.valorUnitario * form.values.quantidade <
              form.values.total
          }
          disabled={!form.values.produtoExternalId}
          prefix={getCurrencySymbol()}
          dataTestId="total-test-id"
        />
      </Col>
    </Row>
  );
};

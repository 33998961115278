import React, { useEffect, useState } from 'react';
import { Form, i18n } from '../../../../../Components';
import { RemoveMask } from '../../../../../Utils/MasksUtils';
import { CompanyDataCard } from '../../../../../Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyCard } from '../../../../../Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyGroupsCardWrapper } from '../../../../../Layouts/CompanyFormLayout/CompanyGroupsCard';
import { CompanyCardSchema } from './CompanyForm.schema';
import { CREATE_COMPANY_TYPE } from '../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { CreateCompanyBillingsCard } from '../../../../../Layouts/CompanyFormLayout/CompanyBillingsCard/Components/CreateForm';
import { CREATE_CONGLOMERATE_TYPE } from '../../../../../ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { FooterButton } from '../Components/FooterButton';
import { CompanyResponsibleUserCardWrapperAdmin } from '../../../../../Layouts/CompanyFormLayout/CompanyResponsibleUserCard/EditAdmin';
import StorageUtils from '@/Utils/StorageUtils';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { history } from '@/Store';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

interface ICompanyForm {
  editForm?: boolean;
}

export const CompanyCreate: React.FC<ICompanyForm> = ({ editForm }) => {
  const [radioCompanyGroup, setRadioCompanyGroup] = useState(true);
  const [radioUser, setRadioUser] = useState(true);
  const [radioCompanyBillings, setRadioCompanyBillings] = useState(true);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [companyCardSchema, setCompanyCardSchema] = useState<any>();

  const createCompany = useControllerMutateApiHook<any>({
    uniqId: CREATE_COMPANY_TYPE,
    entityApi: CompanyAPI.createCompany,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(
          i18n.t('adminBootis.createCompany.messages.success')
        );
        history.push('/admin-bootis/company');
      },
    },
  });

  const createConglomerate = useControllerMutateApiHook<any>({
    uniqId: CREATE_CONGLOMERATE_TYPE,
    entityApi: ConglomerateAPI.createConglomerate,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(
          i18n.t('adminBootis.createCompany.messages.success')
        );
        history.push('/admin-bootis/company');
      },
    },
  });

  const preferences = StorageUtils.getPreference();

  useEffect(() => {
    setCompanyCardSchema(
      CompanyCardSchema({
        radioCompanyGroup,
        radioUser,
        radioCompanyBillings,
      })
    );
  }, [radioCompanyBillings, radioUser, radioCompanyGroup]);

  const onSubmit = async (values: any) => {
    values.endereco.cep = RemoveMask(values?.endereco?.cep) || '';

    const body = {
      ...values,
      cnpj: RemoveMask(values?.cnpj) || '',
      ...(values.usuario && {
        usuario: {
          nome: values.usuario.nome,
          sobrenome: values.usuario.sobrenome,
          email: values.usuario.email,
          celular: values.usuario.celular,
          dataNascimento:
            RemoveMask(
              values?.usuario?.dataNascimento,
              preferences?.padraoData
            ) || '',
          cpf: values.usuario.cpf,
          ativo: true,
        },
      }),
    };

    if (radioCompanyGroup) await createConglomerate.mutateAsync(body);
    else await createCompany.mutateAsync(body);
  };

  return (
    <Form
      onSubmit={onSubmit}
      schema={companyCardSchema}
      validateOnChange={validateAfterSubmit}
    >
      <>
        <ConnectedFocusError />
        <CompanyDataCard />
        <CompanyCard />
        <CompanyGroupsCardWrapper
          onChangeRadioCompanyGroup={(value) => setRadioCompanyGroup(value)}
          radioCompanyGroup={radioCompanyGroup}
        />
        <CompanyResponsibleUserCardWrapperAdmin
          radioCompanyGroup={radioCompanyGroup}
          radioUser={radioUser}
          onChangeRadioUser={setRadioUser}
          isLoading={createCompany.isLoading || createConglomerate.isLoading}
        />
        <CreateCompanyBillingsCard
          radioCompanyGroup={radioCompanyGroup}
          onChangeRadioBillings={setRadioCompanyBillings}
          radioBillings={radioCompanyBillings}
        />
        <FooterButton
          onClick={setValidateAfterSubmit}
          loading={createConglomerate.isLoading || createCompany.isLoading}
        />
      </>
    </Form>
  );
};

import { IUseUserDetailsCallAPI } from './interfaces';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  EDIT_USER_TYPE,
  GET_USER_TYPE,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const useUserDetailsCallAPI = ({
  changeEditDisabled,
  userExternalId,
}: IUseUserDetailsCallAPI) => {
  const { data: user } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_USER_TYPE,
    entityApi: UserAPI.getUser,
    externalId: [userExternalId],
  });
  const editUser = useControllerMutateApiHook({
    uniqId: EDIT_USER_TYPE,
    entityApi: UserAPI.editUser,

    options: { onSuccess: changeEditDisabled },
  });

  return {
    user,
    editUser,
  };
};

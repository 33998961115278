import { Col, Row } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Dropdown,
  InputWithSearch,
  TextInput,
} from '../../../../../../../Components';
import { getCardStatus } from '../../../../../../../Components/Card';
import { IFChildren } from '../../../../../../../Components/Form';
import { InputWithSearchLot } from '../BalanceAdjustmentLot';
import { uniqBy } from 'lodash';
import { useCreateStates } from './Hooks/useCreateStates';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { useCreateMemorizations } from './Hooks/useCreateMemorizations';

export interface IBalanceAdjustmentsInfo {
  form: IFChildren;
  onProductSelected: (product: string, classProductId: number) => void;
  onLotSelectedInfo: (lotExternalId: string) => void;
  onStorageLocationSelected: (storageLocationExternalId: string) => void;
}

export const BalanceAdjustmentsInfo: FC<IBalanceAdjustmentsInfo> = ({
  form,
  onProductSelected,
  onLotSelectedInfo,
  onStorageLocationSelected,
}) => {
  const { t } = useTranslation();

  const { setStorageLocations, storageLocations } = useCreateStates();

  const {
    fetchNewProductPage,
    isLoadingProduct,
    isLoadingStorageLocation,
    productBalanceList,
    refetchSearchProductPage,
    storageLocationDropDownList,
    fetchNewStorageLocationPage,
  } = useCreateCallAPI();

  const { onLotSelected } = useCreateFunctions({ onLotSelectedInfo });

  const { productBalanceListData, storageLocationListData } =
    useCreateMemorizations({
      productBalanceList,
      storageLocationDropDownList,
    });

  useEffect(() => {
    if (!storageLocationDropDownList?.data) return;
    setStorageLocations((prevState) =>
      uniqBy([...prevState, ...storageLocationDropDownList?.data], 'externalId')
    );
  }, [storageLocationDropDownList]);

  return (
    <Card
      title={t('supply.balanceAdjustment.info')}
      status={getCardStatus(['nome', 'sobrenome'], form)}
      withoutCollapse
    >
      <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
        <Col span={12}>
          <InputWithSearch
            items={productBalanceListData}
            name="produtoExternalId"
            placeHolder={t(
              'supply.balanceAdjustment.create.placeHolder.product'
            )}
            label={t('supply.balanceAdjustment.product')}
            required
            onChange={(_, value) => {
              form?.setFieldValue('loteExternalId', undefined);
              value &&
                onProductSelected(value.externalId, value.classeProdutoId);
            }}
            withoutMarginBottom
            isLoading={isLoadingProduct}
            onSearch={(search) => {
              refetchSearchProductPage({
                search: search,
              });
            }}
            onScrollEnd={fetchNewProductPage}
          />
        </Col>
        <Col span={12}>
          <Dropdown
            name="localEstoqueExternalId"
            items={storageLocationListData}
            placeHolder={t(
              'supply.balanceAdjustment.create.placeHolder.stockLocation'
            )}
            label={t('supply.balanceAdjustment.supplyPlace')}
            required
            withoutMarginBottom
            onChange={(location) => {
              const currentLocation = storageLocations.find(
                (x) => x.externalId === location
              );
              currentLocation?.externalId &&
                form?.setFieldValue(
                  'localEstoqueExternalId',
                  currentLocation.externalId
                );
              currentLocation?.externalId &&
                onStorageLocationSelected(currentLocation.externalId);
            }}
            onScrollEnd={fetchNewStorageLocationPage}
            isLoading={isLoadingStorageLocation}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={11}>
          <InputWithSearchLot form={form} onLotSelected={onLotSelected} />
        </Col>
        <Col span={6}>
          <TextInput
            name="fornecedor"
            disabled
            label={[
              {
                children: t('supply.balanceAdjustment.provider'),
              },
            ]}
            placeHolder={t('supply.balanceAdjustment.provider')}
            withoutMarginBottom
          />
        </Col>

        <Col span={5}>
          <TextInput
            name="numeroNf"
            disabled
            label={[
              {
                children: t('supply.balanceAdjustment.identifier'),
              },
            ]}
            placeHolder={t('supply.balanceAdjustment.identifier')}
            withoutMarginBottom
          />
        </Col>

        <Col span={2}>
          <TextInput
            name="serieNf"
            disabled
            type="number"
            label={[
              {
                children: t('supply.balanceAdjustment.nfSeries'),
              },
            ]}
            placeHolder={t('supply.balanceAdjustment.nfSeries')}
            withoutMarginBottom
          />
        </Col>
      </Row>
    </Card>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '@/Components';
import { InfoCard } from './CardsForm/InfoCard';
import { TechnicalInfoCard } from './CardsForm/TechnicalInfoCard';
import { InitialBalanceCard } from './CardsForm/InitialBalanceCard';
import { useLotStates } from './Hooks/useLotStates';
import { useLotCallAPI } from './Hooks/useLotCallAPI';
import { useLotFunctions } from './Hooks/useLotFunctions';
import { useLotMemorizations } from './Hooks/useLotMemorizations';
import StorageUtils from '@/Utils/StorageUtils';

import styles from './LotCreate.module.scss';

export const CreateLot: FC = () => {
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();
  const { errors, setErrors, classTypeState, setClassTypeState } =
    useLotStates();

  const { createLot } = useLotCallAPI({ setErrors });

  const { onClassTypeSelected, onSubmit, onClick } = useLotFunctions({
    setClassTypeState,
    preferences,
    createLot,
  });

  const { schema, initialValues, buttonChildren } = useLotMemorizations({
    createLot,
  });

  return (
    <Form onSubmit={onSubmit} schema={schema} initialValues={initialValues}>
      {(props) => (
        <>
          <InfoCard
            onClassTypeSelected={onClassTypeSelected}
            createError={errors.creatingLotError}
          />
          <TechnicalInfoCard />
          <InitialBalanceCard typeClassProduct={classTypeState} />
          <div className={styles['footer-button']}>
            <Button
              type="secondary"
              children={t('supply.lot.create.cancel')}
              onClick={onClick}
            />
            <Button
              type="primary"
              children={buttonChildren}
              htmlType="submit"
              loading={createLot.isLoading}
              disabled={!props.dirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default CreateLot;

import { FETCH_LIST_UNITS_BY_CLASS } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { IUsecallAPI } from './interfaces';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';

export const useInitialQuantityCallAPI = ({
  typeClassProduct,
}: IUsecallAPI) => {
  const { data: listunitsMeasurements } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNITS_BY_CLASS,
    entityApi: UnitMeasurementAPI.listUnitMeasurementByClass,
    autoIncrement: true,
    pagination: {
      filter: [
        {
          filterName: 'tipoClasseProduto',
          value: typeClassProduct,
        },
      ],
    },
  });

  return {
    listunitsMeasurements,
  };
};

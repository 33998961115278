import { IProductGroupListData } from '@/Data/Interfaces/response/Product/IProductRespose';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';

export const useProductCallMemorizations = ({
  productList,
  index,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const productListFormatted =
    productList && productList.data ? productList.data : [];

  const items = productListFormatted.map((x: IProductGroupListData) => ({
    id: x?.externalId,
    label: x?.descricao,
    subLabel: `${t('purchasing.purchaseOrder.create.subLabelProduct')} ${
      x.sequenciaGroupTenant
    }`,
    allItem: x,
  }));

  const label =
    index === 0
      ? t('purchasing.purchaseOrder.create.productsCardField.product')
      : '';

  return {
    items,
    label,
  };
};

import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const CreateTransferSchema = yup.object().shape({
  origin: yup.string().required(i18n.t('supply.transfers.create.errors.requiredOrigin')),
  destiny: yup.string().required(i18n.t('supply.transfers.create.errors.requiredDestiny')),
  transfers: yup
    .array()
    .transform((value, originalValue) => {
      return originalValue.length > 1 ? originalValue.slice(0, -1) : originalValue;
    })
    .of(
      yup.object().shape({
        originFunds: yup.number(),
        destinyFunds: yup.number(),
        product: yup.string().required(i18n.t('supply.transfers.create.errors.requiredProduct')),
        lot: yup.string().required(i18n.t('supply.transfers.create.errors.requiredLot')),
        qntTransfers: yup
          .string()
          .required(i18n.t('supply.transfers.create.errors.requiredAmountTransfers')),
      })
    )
});

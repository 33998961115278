import { useCallback } from "react";
import { IUseFunctions } from "./interfaces";
import { TextWithoutExtraBlankValue } from "@/Utils/MasksUtils";

export const useBodyFunctions = ({
  setClientName,
  refetchClientList
}: IUseFunctions) => {
  const handleInputChange = (value: string) => {
    const formattedValue = TextWithoutExtraBlankValue(value);

    setClientName(formattedValue);
  };

  const onChangeInputText = useCallback((value: string) => {
    handleInputChange(value);
  }, [handleInputChange]);

  const onSearch = useCallback((search?: string) => {
    const formattedSearchValue = search ? TextWithoutExtraBlankValue(search) : '';

    refetchClientList({
      search: formattedSearchValue,
    });
  }, [refetchClientList, TextWithoutExtraBlankValue]);

  return {
    onChangeInputText,
    onSearch
  };
};
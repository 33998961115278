import { FC } from 'react';
import { Modal } from '@/Components/Modal';
import { IInfoModal } from './interfaces';
import { useInfoModalCallAPI } from './Hooks/useInfoModalCallAPI';
import { useInfoModalMemorizations } from './Hooks/useInfoModalMemorizations';
import { useInfoModalFunctions } from './Hooks/useInfoModalFunctions';
import { InfoModalSchema } from './InfoModal.schema';

export const InfoModal: FC<IInfoModal> = ({
  visible,
  changeVisibleModal,
  prescriber,
  preferences,
}) => {
  const {
    recordTypeList,
    refetchRecordTypeList,
    fetchNewRecordPage,
    recordTypeListLoading,
    listState,
    refetchStateList,
    fetchNewStatePage,
    listStateLoading,

    editInfo,
  } = useInfoModalCallAPI({ changeVisibleModal });

  const { modalBody, initialValues } = useInfoModalMemorizations({
    prescriber,
    preferences,
    recordTypeList,
    refetchRecordTypeList,
    fetchNewRecordPage,
    recordTypeListLoading,
    listState,
    refetchStateList,
    fetchNewStatePage,
    listStateLoading,
  });

  const { onOkClickForm, onCancelClick, onClose } = useInfoModalFunctions({
    editInfo,
    preferences,
    changeVisibleModal,
  });

  return (
    <Modal
      body={modalBody}
      minWidth={800}
      maxWidth={900}
      title={'Editar detalhes'}
      visible={visible}
      withForm
      htmlType="submit"
      onCancelClick={onCancelClick}
      onClose={onClose}
      initialValues={initialValues}
      onOkClickForm={onOkClickForm}
      loading={editInfo.isLoading}
      schema={InfoModalSchema}
    />
  );
};

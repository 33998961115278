import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import {
  Dropdown,
  InputWithSearch,
} from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { listProviderConfig } from '../../../../../Create/utils/functions';
import { FETCH_LIST_DROPDOWN_SUPPLIER } from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import { FETCH_LIST_DROPDOWN_FREIGHT_TYPE } from '../../../../../../../../../ControllerApiHook/UniqIds/Purchase/PurchaseKeys';
import { useFormikContext } from 'formik';
import { Tooltip } from '../../../../../../../../../Components';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseApi';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

enum TypeEnum {
  shippingCompanyId = 2,
}

interface IFourthRowProps {
  initialValues: any;
}

const FourthRow: React.FC<IFourthRowProps> = ({ initialValues }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const {
    data: listShippingCompany,
    isLoading: isLoadingShippingCompany,
    fetchNewPage: fetchNewShippingCompanyPage,
    refetch: refetchSearchShippingCompanyPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
    entityApi: SupplierAPI.listDropdownSupplier,
    pagination: {
      filter: [
        { filterName: 'tipoFornecedorId', value: TypeEnum.shippingCompanyId },
      ],
    },
    autoIncrement: true,
  });

  const { data: freightTypeList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_FREIGHT_TYPE,
    entityApi: PurchaseAPI.listDropdownFreightType,
    autoIncrement: true,
  });

  const freightTypeListDropDown = freightTypeList?.data
    ? freightTypeList.data.map((e: any, i: number) => ({
        id: e.id,
        label: `${i} - ${e.descricao}`,
      }))
    : [];

  const checkInitialValues = useCallback(
    (key: string) => {
      if (form.values) {
        return form.values[key] !== initialValues[key];
      }
      return false;
    },
    [form.values]
  );

  const findFreightType = useCallback(
    (freightTypeDescription: string) => {
      const description = freightTypeListDropDown.filter((e: any) => {
        return e.label.includes(freightTypeDescription);
      });
      if (description.length === 0) {
        return freightTypeDescription;
      }
      return description[0].label;
    },
    [form.values]
  );

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <Tooltip
          showMe={checkInitialValues('transportadoraExternalId')}
          title={`${t('purchasing.invoice.edit.originalCarrier')}${
            initialValues.transportadoraDescricao
          }`}
          targetOffset={[100, 0]}
        >
          <InputWithSearch
            name="transportadoraExternalId"
            placeHolder={t(
              'purchasing.invoice.create.placeHolder.searchForCarrier'
            )}
            label={t('purchasing.invoice.create.carrierCode')}
            items={listProviderConfig(listShippingCompany)}
            onScrollEnd={fetchNewShippingCompanyPage}
            isLoading={isLoadingShippingCompany}
            onSearch={(search) =>
              refetchSearchShippingCompanyPage({
                search: search,
              })
            }
            withStatusIcon
          />
        </Tooltip>
      </Col>
      <Col span={6}>
        <Tooltip
          showMe={checkInitialValues('tipoFreteId')}
          title={`${t(
            'purchasing.invoice.edit.originalFrightType'
          )}${findFreightType(initialValues.tipoFreteDescricao)}`}
          targetOffset={[100, 0]}
        >
          <Dropdown
            name="tipoFreteId"
            items={freightTypeListDropDown}
            label={t('purchasing.invoice.create.frightType')}
            withStatusIcon
            required
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default FourthRow;

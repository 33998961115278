import { Button, Dropdown, Icon, Text } from '@/Components';
import { NumberInput } from '@/Components/Inputs/NumberInput';
import { TextInputNumber } from '@/Components/Inputs/TextInputNumber';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { RecipeCalculationModal } from '../RecipeCalculation/recipeCalculationModal';
import { v4 as uuidV4 } from 'uuid';

import styles from './DetailsManipulatedRecipe.module.scss';
import { useTranslation } from 'react-i18next';

import { InputsWithPopoverOfTheClientAndPrescriber } from './InputsWithPopover';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { IListPharmaceuticalFormDropdownData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';

const productDetailsExemple = [
  { component: 'SiliciuMax', amount: '150', un: 'G' },
  { component: 'Vitamina A', amount: '300', un: 'MCG' },
  { component: 'Vitamina B6', amount: '20', un: 'MG' },
];

export const DetailsManipulatedRecipeModalBody: FC = () => {
  const [pharmaceuticalSelected, setPharmaceuticalSelected] = useState<
    string | undefined
  >(undefined);

  const [openRecipeCalculationModal, setOpenRecipeCalculationModal] =
    useState(false);

  const form: any = useFormikContext();
  const { t } = useTranslation();

  const {
    data: pharmaceuticalList,
    isLoading: pharmaceuticalListLoading,
    fetchNewPage: fetchNewPagePharmaceuticalList,
  } = useControllerQueryListApiHook({
    uniqId: LIST_FOR_PHARMACEUTICAL_FORM_DROPDOWN_TYPE,
    entityApi: PharmaceuticalFormAPI.listPharmaceuticalFormForDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'DESC' },
    },
    autoIncrement: true,
  });

  useEffect(() => {
    form.setSubmitting(false);
  }, []);

  return (
    <div>
      <Divflex className={styles['container']}>
        <Row gutter={[12, 10]} className={styles['row']}>
          <InputsWithPopoverOfTheClientAndPrescriber form={form} />
          <div className={styles['horizontal-divider']} />
          <Col span={24}>
            <Dropdown
              name="formaFarmaceutica"
              label={t('common.pharmaceuticalForm')}
              required
              items={pharmaceuticalList.map(
                (x: IListPharmaceuticalFormDropdownData) => ({
                  id: x.externalId,
                  label: x.descricao,
                })
              )}
              isLoading={pharmaceuticalListLoading}
              onScrollEnd={fetchNewPagePharmaceuticalList}
              withoutMarginBottom
              onChange={(value) =>
                setPharmaceuticalSelected(
                  pharmaceuticalList.find(
                    (x: IListPharmaceuticalFormDropdownData) =>
                      x.externalId === value
                  )?.unidadeMedidaAbreviacao
                )
              }
            />
          </Col>
          <Col span={9}>
            <NumberInput
              name="quantidade"
              label={t(
                'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.amount'
              )}
              required
              placeHolder="0"
              rightIcon={{
                titleString: 'CAP',
              }}
              withoutMarginBottom
            />
          </Col>
          <div className={styles['separate-x']}>
            <Icon icon="close-x" size="S" color="text-300" />
          </div>
          <Col span={6} className={styles['repeat-col']}>
            <TextInputNumber
              name="repetir"
              label={t(
                'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.repeat'
              )}
              placeHolder="0"
              withoutMarginBottom
              min={1}
              withTooltip={{
                icon: 'question-mark',
                title: t(
                  'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.repeatTooltip'
                ),
              }}
            />
          </Col>
          <div className={styles['separate-x']}>
            <Icon icon="Equal" size="S" color="text-300" />
          </div>
          <Col span={9} className={styles['repeat-col']}>
            <NumberInput
              name="total"
              label={t('common.total')}
              withoutMarginBottom
              rightIcon={{
                titleString: 'CAP',
              }}
            />
          </Col>
          <Col span={24}>'Campo e Cards de Upsell'</Col>
          <div className={styles['horizontal-divider']} />

          <Col span={24} className={styles['last-col']}>
            <Dropdown
              name="posologia"
              label={t('production.posology.create.posology')}
              withoutMarginBottom
            />
          </Col>
          <Col span={24}>'Campo e Cards de Upsell'</Col>

          <div className={styles['horizontal-divider']} />

          <div className={styles['content-capsule']}>
            <div>
              <Col span={24}>
                <Dropdown
                  name="capsula"
                  label={t('common.capsule')}
                  withoutMarginBottom
                />
              </Col>
              <Col span={24}>
                <Dropdown
                  name="embalagem"
                  label={t('common.packing')}
                  withoutMarginBottom
                />
              </Col>
            </div>
            <div>
              <Text
                type="small-text-bold"
                color="text-400"
                children={t('common.noImageAvailable')}
              />
            </div>
          </div>
        </Row>
        <div className={styles['details-menu']}>
          <Text type="ui-sub-bold" color="text-50" children="Detalhamento" />
          <Divflex className={styles['details']}>
            <div>
              <Text
                type="ui-tiny-semibold"
                color="text-300"
                children={t('common.components')}
              />
              {productDetailsExemple.map((x) => (
                <Text
                  type="ui-tiny-semibold"
                  color="text-100"
                  children={x.component}
                  key={uuidV4()}
                />
              ))}
            </div>
            <div>
              <Text
                type="ui-tiny-semibold"
                color="text-300"
                children={t('common.amountWithDot')}
              />
              {productDetailsExemple.map((x) => (
                <Text
                  type="ui-tiny-semibold"
                  color="text-100"
                  children={`${x.amount} ${x.un}`}
                  key={uuidV4()}
                />
              ))}
            </div>
          </Divflex>
          <div className={styles['button-calculation']}>
            <Button
              type="secondary"
              leftIcon="calculator"
              children={t('common.viewCalculation')}
              size="xl"
              style={{ float: 'right' }}
              onClick={() => setOpenRecipeCalculationModal(true)}
            />
          </div>
        </div>
      </Divflex>
      <div className={styles['bottom']}>
        <Row gutter={[16, 0]}>
          <Col span={6}>
            <TextInputWithMask
              name="previsao"
              label={t('purchasing.invoice.create.deliveryForecast')}
            />
          </Col>
          <Col span={6}>
            <TextInputNumber name="receita" label={t('common.revenueValue')} />
          </Col>
          <Col span={6}>
            <NumberInput
              name="desconto"
              label={t(
                'purchasing.purchaseOrder.create.productsCardField.discount'
              )}
            />
          </Col>
          <Col span={6}>
            <NumberInput
              name="total"
              label={t('purchasing.purchaseOrder.create.totalCard.totalValues')}
            />
          </Col>
        </Row>
      </div>
      <RecipeCalculationModal
        changeVisibleModal={setOpenRecipeCalculationModal}
        visibleModal={openRecipeCalculationModal}
      />
    </div>
  );
};

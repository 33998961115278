import { FC } from 'react';
import { Modal } from '@/Components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { TextArea } from '@/Components';
import {
  FETCH_GET_SALES_ORDER,
  UPDATE_DELIVERY_SALES_ORDER
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import {
  FormikContextType,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from 'formik';
import { CurrencyInput } from '@/Components/Inputs/CurrencyInput';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';

interface IDeliveryModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrderExternalId?: string;
  saleOrder: IGetSaleOrderResponse;
}

export const DeliveryModal: FC<IDeliveryModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrderExternalId,
  saleOrder,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const form: FormikContextType<any> = useFormikContext();

  const updateDeliverySaleOrder = useControllerMutateApiHook({
    uniqId: UPDATE_DELIVERY_SALES_ORDER,
    entityApi: SalesOrderAPI.updateDeliverySaleOrder,
    options: {
      onSuccess: () => {
        changeVisibleModal(false);
        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        messageSucessToast(t('saleOrder.editOrder.deliveryModal.success'));
      },
      onError: (x) => messageErrorToast(x)
    }
  });

  const entregaEmEndereco = 2;

  return (
    <Modal
      title={t('saleOrder.editOrder.deliveryModal.deliveryFee')}
      body={
        <div style={{ padding: '20px' }}>
          <Row gutter={[16, 0]}>
            <Col flex="50%">
              <CurrencyInput
                placeHolder="R$ 0,00"
                name="taxa"
                label={t('saleOrder.editOrder.deliveryModal.deliveryFee')}
              />
            </Col>
            <Col flex="50%">
              <CurrencyInput
                placeHolder="R$ 0,00"
                name="troco"
                label={t('saleOrder.editOrder.deliveryModal.change')}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col flex="100%">
              <TextArea
                maxLength={150}
                label={t('saleOrder.editOrder.deliveryModal.deliveryNotes')}
                name="observacao"
                heightTextArea={104}
                placeholder={t('common.writeAComment')}
              />
            </Col>
          </Row>
        </div>
      }
      visible={visibleModal}
      okButtonName={
        updateDeliverySaleOrder.isLoading ? t('common.adding') : t('common.add')
      }
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      maxWidth={400}
      withForm
      initialValues={
        saleOrder.clienteEnderecoExternalId ===
        form.values.clienteEnderecoExternalId
          ? {
              ...saleOrder,
              taxa: saleOrder?.taxaEntrega,
              troco: saleOrder?.trocoEntrega,
              observacao: saleOrder.observacaoEntrega,
              pedidoVendaExternalId: saleOrderExternalId,
            }
          : {}
      }
      disabledButtonFormNames={['taxa']}
      onOkClickForm={async (x, formik: FormikHelpers<FormikValues>) => {
        try {
          await updateDeliverySaleOrder.mutateAsync({
            ...x,
            pedidoVendaExternalId: saleOrderExternalId,
            clienteEnderecoExternalId: form.values.clienteEnderecoExternalId,
            tipo: entregaEmEndereco,
          });
        } catch {
          formik.setSubmitting(false);
        }
      }}
      loading={updateDeliverySaleOrder.isLoading}
      htmlType="submit"
    />
  );
};

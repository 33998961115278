import { FC } from 'react';
import { InputWithSearch, Dropdown } from '@/Components';
import { SelectedAvatar } from '@/Components/SelectedAvatar';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateClientModal } from '../../CreateClient/CreateClientModal';
import { useBodyStates } from './Hooks/useBodyStates';
import { useBodyCallAPI } from './Hooks/useBodyCallAPI';
import { useBodyMemorizations } from './Hooks/useBodyMemorizations';
import { FormikContextType, useFormikContext } from 'formik';
import { ICreateServiceRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { useBodyFunctions } from './Hooks/useBodyFunctions';

export const ModalBody: FC = () => {
  const { t } = useTranslation();
  const form: FormikContextType<ICreateServiceRequest> = useFormikContext();
  const {
    clientName,
    setClientName,
    visibleClientModal,
    setVisibleClientModal,
  } = useBodyStates();

  const {
    clientList,
    fetchNewPageClientList,
    refetchClientList,
    isLoadingClientList,
    myUser,
    serviceChannelList,
    client,
  } = useBodyCallAPI({ form });

  const { clientDataList, clientEmptyState, serviceChannelDataList } =
    useBodyMemorizations({
      clientList,
      setVisibleClientModal,
      serviceChannelList: serviceChannelList?.data?.data,
    });

  const { onChangeInputText, onSearch } = useBodyFunctions({
    setClientName,
    refetchClientList,
  });

  return (
    <div style={{ padding: '20px' }}>
      {form.values?.clienteExternalId ? (
        <SelectedAvatar
          name={client?.nome}
          label={t('callCenter.createService.client')}
        />
      ) : (
        <InputWithSearch
          label={t('callCenter.createService.client')}
          required
          items={clientDataList}
          dataTestId="client-dropdown"
          inputDataTestId="client-input"
          name="clienteExternalId"
          withoutSearchIcon
          placeHolder={t('callCenter.createService.clientPlaceholder')}
          placeholderWithAvatar
          displayFormattedValue={clientName}
          isLoading={isLoadingClientList}
          onScrollEnd={fetchNewPageClientList}
          onSearch={onSearch}
          onChangeInputText={(x) => onChangeInputText(x)}
          emptyState={clientEmptyState}
        />
      )}

      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Dropdown
            name="canalAtendimentoId"
            items={serviceChannelDataList}
            isLoading={serviceChannelList.isLoading}
            label={t('callCenter.createService.channel')}
            required
            dataTestId="channel-dropdown"
            id="channel"
          />
        </Col>
        <Col span={12}>
          <SelectedAvatar
            name={myUser?.nome}
            lastName={myUser?.sobrenome}
            label={t('callCenter.createService.attendant')}
            withoutMarginBottom
          />
        </Col>
      </Row>
      <CreateClientModal
        visible={visibleClientModal}
        changeVisible={() => {
          setVisibleClientModal(false);
          clientList.refetch({ search: undefined });
        }}
        nameClient={clientName}
      />
    </div>
  );
};

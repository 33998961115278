import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { FETCH_LIST_STOCK_BALANCE_BY_LOT } from '@/ControllerApiHook/UniqIds/Supply/StockBalanceKeys';
import { StockBalanceAPI } from '@/Data/API/Supply/StockBalanceApi';
import { LotsAPI } from '@/Data/API/Supply/LotApi';
import { FETCH_GET_BALANCE_ORIGIN_DESTINY } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import {
  CONVERT_UNIT_OF_MEASURE,
  FETCH_LIST_UNITS_BY_CLASS,
} from '@/ControllerApiHook/UniqIds/Inventory/BalanceAdjustment';
import { ILotSaldosOriginAndDestinyResponse } from '@/Data/Interfaces/response/Lot/ILotSaldosOriginAndDestinyResponse';

export const useCreateCallAPI = ({
  selectedLotId,
  originSupplyPlaceId,
  destinySupplyPlaceId,
  form,
  index,
  selectedProductId,
}: IUseCallAPI) => {
  const {
    data: productsList,
    fetchNewPage: fetchNewProductPage,
    isLoading: isLoadingProduct,
    refetch: refetchProducts,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
    autoIncrement: true,
  });

  const {
    data: lotsList,
    isLoading: lotLoading,
    refetch: lotsRefetch,
    fetchNewPage: fetchLotsNewPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STOCK_BALANCE_BY_LOT,
    entityApi: StockBalanceAPI.stockBalanceByLotList,
    pagination: {
      filter: [
        { filterName: 'produtoExternalId', value: selectedProductId },
        {
          filterName: 'localEstoqueExternalId',
          value: originSupplyPlaceId,
        },
      ],
    },
  });

  const { mutateAsync: convertUnitOfMeasureAsync } = useControllerMutateApiHook(
    {
      uniqId: CONVERT_UNIT_OF_MEASURE,
      entityApi: UnitMeasurementAPI.convertUnitOfMeasure,
    }
  );

  const { data: balanceOriginDestiny } =
    useControllerQueryApiHook<ILotSaldosOriginAndDestinyResponse>({
      entityApi: LotsAPI.getSaldosOriginAndDestiny,
      uniqId: FETCH_GET_BALANCE_ORIGIN_DESTINY,
      refetchOnWindowFocus: false,
      externalId: [selectedLotId!, originSupplyPlaceId!, destinySupplyPlaceId!],
      onSuccess: (value) => {
        const values = form.values;
        let qntTransfers = Number(
          (form.values as any)?.transfers[index]?.qntTransfers || 0
        );
        const origin = value
          ? value.saldoLoteOrigem
          : form.values.transfers[index]?.originFunds;

        if (qntTransfers > (origin || 0)) {
          qntTransfers = origin || 0;
          form.setFieldValue(`transfers.${index}.qntTransfers`, qntTransfers);
        }

        const destinyBalanceValue =
          (value?.saldoLoteDestino || 0) + qntTransfers;

        if (values.originFunds !== origin) {
          form.setFieldValue(`transfers.${index}.originFunds`, origin);
          form.setFieldValue(`transfers.${index}.initialOriginFunds`, origin);
        }

        if (values.destinyFunds !== destinyBalanceValue) {
          form.setFieldValue(
            `transfers.${index}.destinyFunds`,
            destinyBalanceValue
          );
          form.setFieldValue(
            `transfers.${index}.initialDestinyFunds`,
            destinyBalanceValue
          );
        }
      },
    });

  const { data: unitsMeasurements, refetch: refetchUnitsMeasurements } =
    useControllerQueryListApiHook({
      uniqId: FETCH_LIST_UNITS_BY_CLASS,
      entityApi: UnitMeasurementAPI.listUnitMeasurementByClassAny,
      options: {
        staleTime: 1000 * 60 * 60 * 24 * 3,
      },
    });

  return {
    productsList,
    fetchNewProductPage,
    convertUnitOfMeasureAsync,
    lotsList,
    lotLoading,
    lotsRefetch,
    fetchLotsNewPage,
    balanceOriginDestiny,
    unitsMeasurements: Array.isArray(unitsMeasurements)
      ? unitsMeasurements
      : unitsMeasurements.data,
    refetchUnitsMeasurements,
    isLoadingProduct,
    refetchProducts,
  };
};

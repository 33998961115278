import { FC, useEffect, useState, useMemo } from 'react';
import { BodyOrderContent } from './BodyOrderContent';
import { SideRightMenu } from './SideRightMenu';
import { BottomMenu } from './BottomMenu';
import { Form } from '../../../../../../../Components';
import {
  FETCH_GET_SALES_ORDER,
  PATCH_SALES_ORDER,
} from '../../../../../../../ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import _ from 'lodash';
import { Loading } from '../../../../../../../Components/Loading';
import DateUtils from '../../../../../../../Utils/DateUtils';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { convertCurrencyToInteger } from '@/Utils/Currency';
import StorageUtils from '@/Utils/StorageUtils';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { messageErrorToast } from '@/Utils/MessageErrorToast';

import styles from './WithSaleOrder.module.scss';

interface IWithSaleOrder {
  saleOrder?: IGetSaleOrderResponse;
}

export const WithSaleOrder: FC<IWithSaleOrder> = ({ saleOrder }) => {
  const { t } = useTranslation();

  const preferences = StorageUtils.getPreference();
  const queryClient = useQueryClient();

  const [contentPageHeight, setContentPageHeight] = useState<
    undefined | number
  >(undefined);

  const bottomMenuHeight = 130;

  useEffect(() => {
    const pageHeight = document.getElementById(
      'container-oder-sale'
    )?.clientHeight;
    pageHeight && setContentPageHeight(pageHeight);
  }, [contentPageHeight]);

  const { mutateAsync, isLoading, isSuccess, variables }: any =
    useControllerMutateApiHook({
      uniqId: PATCH_SALES_ORDER,
      entityApi: SalesOrderAPI.patchSaleOrder,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        },
        onError: (x) => messageErrorToast(x),
      },
    });

  const patchSaleOrder = useMemo(
    () =>
      _.debounce(async (value: any, propName: string) => {
        if (!saleOrder?.externalId) return;

        let data: any = {};

        if (typeof value === 'string')
          data[propName] = convertCurrencyToInteger(value);
        else {
          data[propName] =
            typeof value !== 'object' ? value : value?.target.value;
        }
        console.log(data)
        return await mutateAsync({
          externalId: saleOrder?.externalId,
          data,
        });
      }, 500),
    [mutateAsync, saleOrder?.externalId]
  );

  return (
    <Form
      className={styles['content']}
      initialValues={
        saleOrder && {
          ...saleOrder,
          codigoPedido: `#${saleOrder.codigoPedido}`,
          dataLancamento: `${DateUtils.convertDate(
            saleOrder?.dataLancamento,
            preferences?.padraoData
          )} ${t('common.complementDateHour')} ${DateUtils.convertDate(
            saleOrder?.dataLancamento,
            preferences?.padraoHora
          )}`,
          desconto: saleOrder.valorDescontoTotal,
          taxa: saleOrder.taxaEntrega,
        }
      }
    >
      {saleOrder ? (
        <div className={styles['body-content']}>
          <div className={styles['body-order-content']}>
            <div
              style={
                contentPageHeight
                  ? { height: contentPageHeight - bottomMenuHeight }
                  : undefined
              }
              id="content-oder-item"
            >
              <BodyOrderContent saleOrder={saleOrder} />
            </div>
            <div style={{ height: '25' }}>
              <div className={styles['divider-horizontal']} />
              <BottomMenu
                saleOrder={saleOrder}
                patchSaleOrder={patchSaleOrder}
                patchSaleOrderLoading={isLoading}
                patchSaleOrderSuccess={isSuccess}
                patchVariables={variables?.data}
              />
            </div>
          </div>

          <div className={styles['divider']} />

          <div className={styles['right-menu-content']}>
            <SideRightMenu
              saleOrder={saleOrder}
              patchSaleOrder={patchSaleOrder}
              patchSaleOrderLoading={isLoading}
              patchSaleOrderSuccess={isSuccess}
              patchVariables={variables?.data?.observacao}
            />
          </div>
        </div>
      ) : (
        <Loading className={styles['loadingPage']} size="50px" />
      )}
    </Form>
  );
};

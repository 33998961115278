import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { 
  FETCH_GET_ORDER_PURCHASE 
} from "@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys";
import { PurchaseAPI } from "@/Data/API/Purchase/PurchaseOrderApi";
import { 
  IPurchaseOrderGetResponse 
} from "@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse";
import { IUseCallAPI } from "./interfaces";

export const useProductCardCallAPI = ({
  externalId
}: IUseCallAPI) => {
  const { data: orderPurchase } = useControllerQueryApiHook<IPurchaseOrderGetResponse>({
    uniqId: FETCH_GET_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOrderPurchase,
    externalId: [externalId]
  });

  return {
    orderPurchase
  };
};
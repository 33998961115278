import React, { useEffect, useRef, useState } from 'react';
import { Text } from '../..';
import CurrencyFormat from 'react-currency-format';
import { VisualInput } from './VisualInput';
import { useTranslation } from 'react-i18next';
import { INumberInput } from './types';
import { useField } from 'formik';
import { getCurrencySymbol } from '@/Utils/Currency';
import { InputRef } from 'antd';
import { formatAsNumber } from '@/Utils/numberUtils';
import {
  DEFAULT_DECIMAL_SCALE,
  FLAT_DISCOUNT_ID,
  decimalSeparator,
  dropdownLeftConfig,
  thousandSeparator,
} from './config';

import styles from './VisualInput/VisualInput.module.scss';

export const DiscountInput = ({
  name,
  prefix,
  suffix,
  onChange,
  onBlur,
  subText,
  dropdownLeft,
  dataTestId,
  ...props
}: Omit<
  INumberInput,
  | 'fixedDecimalScale'
  | 'withCurrencyPrefix'
  | 'decimalScale'
  | 'withDecimalSeparator'
  | 'withThousandSeparator'
  | 'dropdownLeft'
> & {
  withoutPrefix?: boolean;
  withoutSuffix?: boolean;
  dropdownLeft: {
    name: string;
    onChange?: (value: string) => void;
    dataTestId?: string;
  };
}) => {
  const { t } = useTranslation();
  const ref = useRef<React.Ref<InputRef> | null>();
  const [numberFieldConfig, _, numberFieldHelpers] = useField(name);
  const [metadataConfig, metadataProps, metadataHelpers] = useField(
    `metadata.${name}`
  );
  const [_1, dropdownField, dropdownHelpers] = useField(dropdownLeft.name);
  const [isFlatDiscount, setIsFlatDiscount] = useState(true);

  // The main reason of this useEffect is to detect changes in the input value that
  // are out of sync with metadata (means that you changed the input value manualy with 'setFieldValue')
  useEffect(() => {
    const currentFieldValue = numberFieldConfig.value;
    const currentMetadataFloatValue = metadataConfig.value?.floatValue;

    const hasDifference = currentFieldValue !== currentMetadataFloatValue;
    const isStartingNegativeNumber =
      metadataConfig?.value?.formattedValue === '-';
    if (!hasDifference || isStartingNegativeNumber) {
      return;
    }

    if (
      !currentFieldValue &&
      (metadataConfig?.value?.formattedValue !== '' ||
        metadataConfig?.value?.value !== '' ||
        metadataConfig?.value?.floatValue !== undefined)
    ) {
      metadataHelpers.setValue({
        formattedValue: '',
        value: '',
        floatValue: undefined,
      });
      return;
    }

    const strValue = currentFieldValue?.toString();
    let decimalScale: number | undefined = DEFAULT_DECIMAL_SCALE;

    const formattedValue = formatAsNumber(
      strValue,
      decimalScale,
      thousandSeparator,
      decimalSeparator,
      prefix,
      suffix,
      isFlatDiscount
    );

    metadataHelpers.setValue({
      formattedValue,
      value: strValue,
      floatValue: currentFieldValue,
    });
  }, [
    numberFieldConfig,
    metadataConfig,
    metadataHelpers,
    thousandSeparator,
    decimalSeparator,
    prefix,
    suffix,
    isFlatDiscount,
  ]);

  const placeHolder = `${formatAsNumber('0', 2, undefined, decimalSeparator)}${
    !isFlatDiscount
      ? t('purchasing.purchaseOrder.create.discountType.discountPercentage')
      : ''
  }`;

  return (
    <>
      <CurrencyFormat
        dataTestId={dataTestId}
        decimalScale={DEFAULT_DECIMAL_SCALE}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        fixedDecimalScale={isFlatDiscount}
        placeHolder={placeHolder}
        customInput={VisualInput}
        value={metadataProps?.value?.formattedValue}
        prefix={
          props.withoutPrefix
            ? ''
            : isFlatDiscount
            ? getCurrencySymbol()
            : undefined
        }
        suffix={
          props.withoutSuffix
            ? ''
            : !isFlatDiscount
            ? t(
                'purchasing.purchaseOrder.create.discountType.discountPercentage'
              )
            : undefined
        }
        onBlur={onBlur}
        onChange={(x) => onChange && onChange(x.target.value)}
        onValueChange={(x) => {
          const valueWithoutSuffix = x?.formattedValue?.replace(
            suffix || '',
            ''
          );
          const lastChar = valueWithoutSuffix[valueWithoutSuffix.length - 1];

          const availableChars = [',', '.', '-'];
          if (availableChars.includes(lastChar)) {
            metadataHelpers.setValue(x);
            return x?.formattedValue;
          }

          metadataHelpers.setValue(x);
          return numberFieldHelpers.setValue(
            isNaN(x?.floatValue) ? undefined : x.floatValue
          );
        }}
        {...({
          ...props,
          dropdownLeft: dropdownLeftConfig(
            {
              ...dropdownLeft,
              onChange: (x) => {
                setIsFlatDiscount(Number(x) === FLAT_DISCOUNT_ID);
                const currentFieldValue = numberFieldConfig.value;
                const strValue = currentFieldValue?.toString();
                let decimalScale: number | undefined = DEFAULT_DECIMAL_SCALE;

                const formattedValue = formatAsNumber(
                  strValue,
                  decimalScale,
                  thousandSeparator,
                  decimalSeparator,
                  prefix,
                  suffix,
                  Number(x) === FLAT_DISCOUNT_ID
                );
                metadataHelpers.setValue({
                  formattedValue,
                  value: strValue,
                  floatValue: currentFieldValue,
                });
                dropdownLeft?.onChange && dropdownLeft?.onChange(x);
              },
            },
            t
          ),
          prefix,
          currentSuffix: suffix,
          outsideRef: ref,
          separators: {
            decimalSeparator,
            thousandSeparator,
          },
        } as any)}
      />
      {subText && (
        <Text
          type="ui-tiny-content"
          color="text-300"
          children={subText}
          className={styles['sub-text']}
        />
      )}
    </>
  );
};

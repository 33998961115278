import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs, Tag, Text } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useParams } from 'react-router';
import { GET_SERVICE } from '../../../../../ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { HeaderSaleOrder } from './Header';
import { Order } from './Order';
import { FETCH_GET_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import StorageUtils from '@/Utils/StorageUtils';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { IGetServiceResponse } from '@/Data/Interfaces/response/Sales/Service/IServiceResponse';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';

import styles from './EditTickets.module.scss';

export const EditTickets: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: service, isLoading: getServiceLoading } =
    useControllerQueryApiHook<IGetServiceResponse>({
      uniqId: GET_SERVICE,
      entityApi: ServiceAPI.getService,
      externalId: [externalId],
    });

  const { data: saleOrder } = useControllerQueryApiHook<IGetSaleOrderResponse>({
    uniqId: FETCH_GET_SALES_ORDER,
    entityApi: SalesOrderAPI.getSaleOrder,
    externalId: [service?.pedidoVendaExternalId],
  });

  const preferences = StorageUtils.getPreference();

  const { t } = useTranslation();

  return (
    <PageLoading loading={getServiceLoading}>
      <HeaderSaleOrder service={service} preferences={preferences} t={t} />
      <div>
        <HorizontalTabs
          className={styles['horizontal-tab']}
          type="secondary"
          items={[
            {
              label: t('saleOrder.editOrder.header.tabs.messages'),
              key: 'message',
              children: <></>,
              disabled: true,
            },
            {
              label: (
                <div style={{ display: 'flex' }}>
                  <Text
                    type="ui-tiny-bold"
                    children={t('saleOrder.editOrder.header.tabs.order')}
                    style={{ marginRight: '4px' }}
                  />
                  {saleOrder && (
                    <Tag
                      type="primary"
                      children={`#${saleOrder.codigoPedido}`}
                    />
                  )}
                </div>
              ),
              key: 'order',
              children: service && externalId && (
                <Order service={service} saleOrder={saleOrder} />
              ),
            },
            {
              label: t('saleOrder.editOrder.header.tabs.attachments'),
              key: 'attachments',
              children: <></>,
              disabled: true,
            },
            {
              label: t('saleOrder.editOrder.header.tabs.notes'),
              key: 'Notes',
              children: <></>,
              disabled: true,
            },
            {
              label: t('saleOrder.editOrder.header.tabs.historic'),
              key: 'Historic',
              children: <></>,
              disabled: true,
            },
          ]}
        />
      </div>
    </PageLoading>
  );
};

import React, { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../..';
import { Color, Colors } from '../../../../Utils/ColorUtils';
import { isFormError } from '../../../../Utils/ErrorUtils';
import { IFChildren } from '../../../Form';
import { IconName } from '../../../Icon/IconClasses';
import { ButtonStatus, ButtonType } from '../../../Button';

import styles from './Footer.module.scss';

export interface IFooter {
  buttonCancelWidth?: number;
  footerText?: ReactNode;
  buttonOkWidth?: number;
  okButtonClassName?: string;

  htmlType?: 'submit' | 'button' | 'reset';
  okButtonName?: string;
  okButtonColor?: Color;
  cancelButtonName?: string;
  onOkClick?: () => void;
  onCancelClick?: () => void;

  className?: string;
  disabledButton?: boolean;
  withForm?: boolean;
  withoutOkButton?: boolean;
  style?: CSSProperties;
  leftIcon?: IconName;
  propsForm?: IFChildren;
  status?: ButtonStatus;

  loadingOkButton?: boolean;
  withoutOkCancel?: boolean;

  dropdownRight?: {
    label: string;
    onClick: (value: any) => void;
  }[];

  onReturn?: () => void;
  showReturnButton?: boolean;
  returnButtonName?: string;
  returnButtonIcon?: IconName;

  dataTestId?: string;
  cancelDataTestId?: string;
  okButtonType?: ButtonType;
}

export const Footer: React.FC<IFooter> = ({
  buttonCancelWidth,
  footerText,
  buttonOkWidth,
  cancelButtonName,
  okButtonName,
  okButtonColor,
  okButtonClassName,
  onOkClick,
  onCancelClick,
  className,
  htmlType,
  disabledButton,
  withForm,
  withoutOkButton,
  style,
  propsForm,
  loadingOkButton,
  withoutOkCancel,
  leftIcon,
  status,
  dropdownRight,
  onReturn,
  showReturnButton,
  returnButtonIcon,
  returnButtonName,
  dataTestId,
  okButtonType,
  cancelDataTestId,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} ${styles['footer']}`} style={style}>
      {showReturnButton && (
        <Button
          type="secondary"
          children={returnButtonName ? returnButtonName : t('modal.back')}
          leftIcon={returnButtonIcon ? returnButtonIcon : 'chevron-left'}
          onClick={onReturn}
        />
      )}
      <div>{footerText}</div>

      <div style={{ display: 'flex' }}>
        <div
          style={
            buttonCancelWidth
              ? { width: buttonCancelWidth, marginRight: 15 }
              : { marginRight: 0 }
          }
        >
          {!withoutOkCancel && (
            <Button
              dataTestId={cancelDataTestId}
              className={styles['buttonCancel']}
              type="secondary"
              children={t(cancelButtonName || 'modal.cancel')}
              onClick={() => {
                onCancelClick && onCancelClick();
                propsForm?.resetForm();
              }}
            />
          )}
        </div>

        {!withoutOkButton && (
          <div
            style={
              buttonOkWidth
                ? { width: buttonOkWidth, marginLeft: 15 }
                : { marginLeft: 10 }
            }
          >
            <Button
              className={`${okButtonClassName} ${styles['buttonOk']}`}
              type={okButtonType ? okButtonType : 'primary'}
              children={t(okButtonName || 'modal.save')}
              onClick={() => onOkClick && onOkClick()}
              style={
                okButtonColor && {
                  backgroundColor: `${Colors[okButtonColor]} !important`,
                }
              }
              status={status}
              leftIcon={leftIcon}
              htmlType={htmlType}
              disabled={disabledButton}
              loading={loadingOkButton}
              dropdownRight={dropdownRight}
              dataTestId={dataTestId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

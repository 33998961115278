import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import {
  FETCH_DROPDOWN_LIST_CLIENT,
  GET_CLIENT,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import { FETCH_LIST_SERVICE_CHANNEL } from '@/ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { GET_ME } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { IGetClientResponse } from '@/Data/Interfaces/response/Client/IClientResponse';
import { IUseCallAPI } from './interfaces';

export const useBodyCallAPI = ({ form }: IUseCallAPI) => {
  const {
    data: clientList,
    fetchNewPage: fetchNewPageClientList,
    refetch: refetchClientList,
    isLoading: isLoadingClientList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_DROPDOWN_LIST_CLIENT,
    entityApi: ClientAPI.listDropdownClient,
    autoIncrement: true,
    pagination: { sorter: { column: 'nome', direction: 'DESC' } },
  });

  const serviceChannelList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SERVICE_CHANNEL,
    entityApi: ServiceAPI.listServiceChannel,
    autoIncrement: true,
  });

  const { data: myUser } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
  });

  const { data: client } = useControllerQueryApiHook<IGetClientResponse>({
    uniqId: GET_CLIENT,
    entityApi: ClientAPI.getClient,
    externalId: [form?.values?.clienteExternalId],
  });

  return {
    clientList,
    fetchNewPageClientList,
    refetchClientList,
    isLoadingClientList,
    serviceChannelList,
    myUser,
    client,
  };
};

import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IHistoricSaleOrder } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_HISTORIC_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';

export const useHistoricCallAPI = (saleOrderExternalId?: string) => {
  const historicSaleOrder = useControllerQueryApiHook<IHistoricSaleOrder>({
    uniqId: FETCH_GET_HISTORIC_SALES_ORDER,
    entityApi: SalesOrderAPI.getHistoricSaleOrder,
    externalId: [saleOrderExternalId],
  });

  return { historicSaleOrder };
};

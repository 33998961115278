import { Button } from '@/Components';
import { history } from '@/Store';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DetailsForm.module.scss';

export const FooterButtons: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('common.cancel')}
        onClick={() => {
          history.push('/production/packing-classification');
        }}
      />
      <Button type="primary" children={t('common.save')} htmlType="submit" />
    </div>
  );
};

import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form } from '@/Components';
import { SubGroupDetailsSchema } from './SubgroupDetails.schema';
import { InputDescriptionSubGroup } from './Components/InputDescriptionSubGroup';
import { IDetailsForm } from './interfaces';
import { useDetailsStates } from './Hooks/useDetailsStates';
import { useDetailsCallAPI } from './Hooks/useDetailsCallAPI';
import { useDetailsFunctions } from './Hooks/useDetailsFunctions';
import { useDetailsMemorizations } from './Hooks/useDetailsMemorizations';

import styles from './DetailsForm.module.scss';

export const DetailsForm: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { t } = useTranslation();

  const {
    isErrorValidateDescription,
    setIsErrorValidateDescription,
    groupIsEdited,
    setGroupIsEdited,
  } = useDetailsStates();

  const { data, editSubGroup } = useDetailsCallAPI();

  const { onSubmit, onClick } = useDetailsFunctions({
    data,
    editSubGroup,
    onChangeDisabled,
    disabled,
  });

  const { initialValues } = useDetailsMemorizations({
    data,
  });

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        schema={SubGroupDetailsSchema}
      >
        {(props) => {
          return (
            <Card title={t('common.details')} withoutCollapse={true}>
              <Row gutter={[16, 0]}>
                <InputDescriptionSubGroup
                  disabled={disabled}
                  setIsErrorValidadeDescription={setIsErrorValidateDescription}
                  initialDescription={data?.subGrupoDescricao}
                  setGroupIsEdited={setGroupIsEdited}
                />
              </Row>
              {!disabled && (
                <div className={styles['footer-button']}>
                  <Button
                    type="secondary"
                    children={t('common.cancel')}
                    onClick={onClick}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    children={
                      editSubGroup.isLoading
                        ? t('products.create.saveLoading')
                        : t('products.create.save')
                    }
                    loading={editSubGroup.isLoading}
                    disabled={
                      groupIsEdited
                        ? false
                        : !props.values.descricao ||
                          !props.values.grupoPaiExternalId ||
                          props.values.descricao === data?.subGrupoDescricao ||
                          isErrorValidateDescription
                    }
                    dataTestId="submit-edit-subgroup-button"
                  />
                </div>
              )}
            </Card>
          );
        }}
      </Form>
    </>
  );
};

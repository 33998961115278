import { useCallback } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../Components/Modal';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { useQueryClient } from '@tanstack/react-query';
import { IGetPackingClassification } from '../../../../../Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';
import { App } from 'antd';
import { handleModalError } from '../../../../../Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { cannotDeletePackingClassificationWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalPackingClassification';
import { useEditCallAPI } from './Hooks/useEditCallAPI';

interface IDeleteProductModal {
  packingClassification: IGetPackingClassification;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteProductModal: FC<IDeleteProductModal> = ({
  packingClassification,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const app = App.useApp();

  const { deletePackingClassification, isLoadingDeletePackingClassification } =
    useEditCallAPI({ externalId: packingClassification.externalId });

  const onDelete = useCallback(async () => {
    await deletePackingClassification(
      { externalId: [packingClassification.externalId] },
      {
        onError: handleModalError(
          app,
          cannotDeletePackingClassificationWithMovementsInTheSystemProps(t)
        ),
      }
    );
  }, [deletePackingClassification, queryClient, packingClassification, app]);

  return (
    <Modal
      title={`${t('common.deletePackingClassificationDetail')}  "${
        packingClassification?.descricao
      }"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex style={{ flexDirection: 'column', marginBottom: 10 }}>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t(
                'production.packingClassification.details.modal.messageQuestionDelete'
              )} `}
            />

            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${packingClassification?.descricao}?`}
            />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={() => {
        onDelete();
        changeVisibleModal(false);
      }}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={410}
      okButtonType="danger"
      loading={isLoadingDeletePackingClassification}
    />
  );
};

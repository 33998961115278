import { Col, Row } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { GET_LIST_CITY } from '@/ControllerApiHook/UniqIds/Locality/CityKeys';
import { GET_LIST_STATE } from '@/ControllerApiHook/UniqIds/Locality/StateKeys';
import { IGetCEPResponse } from '@/Data/Interfaces/response/CEP/ICEPResponse';
import { InputWithSearch, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import {
  useControllerQueryApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { CityAPI } from '@/Data/API/Locality/CityApi';
import { IGetCityResponse } from '@/Data/Interfaces/response/Locality/ICityResponse';
import { StateAPI } from '@/Data/API/Locality/StateApi';
import { IGetStateResponse } from '@/Data/Interfaces/response/Locality/IStateResponse';
import { GET_CEP } from '@/ControllerApiHook/UniqIds/Locality/CEPKeys';
import { CEPAPI } from '@/Data/API/CEP/CEPApi';

export const CreateDeliveryModalBody: FC = () => {
  const [CEP, setCEP] = useState('');
  const { t } = useTranslation();
  const form = useFormikContext<FormikValues>();

  const {
    data: listCity,
    fetchNewPage: fetchNewCityPage,
    refetch: refetchCityList,
    isLoading: cityListLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_CITY,
    entityApi: CityAPI.getListCity,
    pagination: {
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'DESC' },
      filter: [
        {
          filterName: 'estadoExternalId',
          value: form.values.estadoExternalId,
        },
      ],
    },
  });

  const {
    data: listState,
    fetchNewPage: fetchNewStatePage,
    refetch: refetchStateList,
    isLoading: stateListLoading,
  } = useControllerQueryListApiHook({
    uniqId: GET_LIST_STATE,
    entityApi: StateAPI.getListState,
    pagination: {
      pageSize: 50,
      sorter: { column: 'descricao', direction: 'DESC' },
    },
  });

  const { isLoading: cepLoading } = useControllerQueryApiHook<IGetCEPResponse>({
    uniqId: GET_CEP,
    entityApi: CEPAPI.getCEP,
    externalId: [CEP],
    onSuccess: (x: IGetCEPResponse) => {
      if (!x) return;
      form.setFieldValue('logradouro', x.logradouro);
      form.setFieldValue('bairro', x.bairro);
      form.setFieldValue('cidadeExternalId', x.cidadeExternalId);
      form.setFieldValue('estadoExternalId', x.estadoExternalId);
      form.setFieldValue('cep', x.cep);
      form.setFieldValue('paisExternalId', x.paisExternalId);
    },
    options: { refetchOnWindowFocus: false },
  });

  const changeCEP = useMemo(
    () => _.debounce((value: string) => setCEP(value), 600),
    []
  );

  return (
    <div style={{ width: '800px', padding: '20px' }}>
      <Row gutter={[16, 0]} justify="start">
        <Col flex="26%">
          <TextInput
            name="descricao"
            label={t('address.modal.description')}
            placeHolder={t('address.modal.descriptionPlaceholder')}
            required
          />
        </Col>
        <Col flex="26%">
          <TextInputWithMask
            name="cep"
            label={t('address.modal.CEP')}
            mask="cepMask"
            placeHolder={'00000-000'}
            loading={cepLoading}
            onChange={changeCEP}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={9}>
          <InputWithSearch
            name="estadoExternalId"
            label={t('address.modal.state')}
            placeHolder={t('address.modal.statePlaceholder')}
            items={listState?.map((x: IGetStateResponse) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            onScrollEnd={fetchNewStatePage}
            isLoading={stateListLoading}
            onSearch={(search) =>
              refetchStateList({
                search: search,
              })
            }
            withoutSearchIcon
            onChange={() => form.setFieldValue('cidadeExternalId', undefined)}
          />
        </Col>
        <Col span={9}>
          <InputWithSearch
            name="cidadeExternalId"
            label={t('address.modal.city')}
            placeHolder={t('address.modal.cityPlaceholder')}
            items={listCity?.map((x: IGetCityResponse) => ({
              id: x.externalId,
              label: x.descricao,
            }))}
            onScrollEnd={fetchNewCityPage}
            isLoading={cityListLoading}
            onSearch={(search) => {
              refetchCityList({
                search: search,
              });
            }}
            withoutSearchIcon
            disabled={!form.values.estadoExternalId}
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="bairro"
            label={t('address.modal.district')}
            placeHolder={t('address.modal.districtPlaceholder')}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="60%">
          <TextInput
            name="logradouro"
            withoutMarginBottom
            label={t('address.modal.street')}
            placeHolder={t('address.modal.streetPlaceholder')}
          />
        </Col>
        <Col flex="20%">
          <TextInput
            name="numero"
            withoutMarginBottom
            label={t('address.modal.streetNumber')}
            placeHolder={t('address.modal.streetNumberPlaceholder')}
          />
        </Col>
        <Col flex="20%">
          <TextInput
            name="complemento"
            withoutMarginBottom
            label={t('address.modal.complement')}
            placeHolder={t('address.modal.complementPlaceholder')}
          />
        </Col>
      </Row>
    </div>
  );
};

import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IQueryListParams } from '@/Utils/Http';
import {
  IBasicMutationOptions,
  IBasicQueryOptions,
} from './interfaces/IBasicOptions';
import _ from 'lodash';

interface IControllerQueryApiHook {
  uniqId: string;
  entityApi: any;
  successToast?: string;
  externalId?: (string | undefined)[];
  onSuccess?: (data: any) => void;
  enabled?: boolean;
  options?: IBasicQueryOptions;
  refetchOnWindowFocus?: boolean;
}

export const useControllerQueryApiHook = <T>({
  entityApi,
  uniqId,
  externalId,
  successToast,
  onSuccess,
  enabled,
  options,
  refetchOnWindowFocus = true,
}: IControllerQueryApiHook): UseQueryResult<T, unknown> => {
  return useQuery([uniqId, ...(externalId ?? [])], entityApi, {
    onSuccess: (data) => {
      successToast && messageSucessToast(successToast);
      onSuccess && onSuccess(data);
    },
    onError: (err) => messageErrorToast(err),
    enabled: _.isNil(enabled)
      ? externalId
        ? externalId?.every((x: any) => x)
        : true
      : enabled,
    refetchOnWindowFocus: refetchOnWindowFocus,
    ...options,
  });
};

export interface IPagination
  extends Omit<IQueryListParams, 'pageIndex' | 'pageSize'> {
  pageSize?: number;
  pageIndex?: number;
}

interface IControllerQueryListApiHook
  extends Omit<IControllerQueryApiHook, 'externalId'> {
  pagination?: IPagination;
  autoIncrement?: boolean;
  autoIncrementCustomId?: string;
  options?: IBasicQueryOptions;
  optionalParams?: string[];
}

export const useControllerQueryListApiHook = ({
  uniqId,
  entityApi,
  pagination,
  autoIncrement,
  autoIncrementCustomId,
  successToast,
  options,
  optionalParams = [],
  enabled,
}: IControllerQueryListApiHook) => {
  const [data, setData] = useState<any>([]);
  const [autoIncrementing, setAutoIncrementing] = useState(false);
  const [lastPage, setLastPage] = useState(false);

  const [paginationData, setPaginationData] = useState<IQueryListParams>({
    pageIndex: 0,
    pageSize: 10,
    ...pagination,
  });

  const query = useQuery<any>(
    [uniqId, paginationData, ...optionalParams],
    entityApi,
    {
      onSuccess: (newData) => {
        if (!lastPage)
          if (autoIncrement && autoIncrementing)
            setData((prevState: any) => ({
              count: newData.count,
              pageIndex: newData.pageIndex,
              pageSize: newData.pageSize,
              data: uniqBy(
                [...prevState.data, ...newData.data],
                autoIncrementCustomId ?? 'externalId'
              ),
            }));
          else setData(newData);

        successToast && messageSucessToast(successToast);
        options?.onSuccess && options?.onSuccess(newData);
      },
      onError: (err) => messageErrorToast(err),
      enabled: paginationData?.filter?.every((x: any) => x.value),
    }
  );

  const refetch = useCallback((newPagination?: IPagination) => {
    setPaginationData((value) => ({
      pageIndex: 0,
      pageSize: value.pageSize,
      filter: value.filter,
      sorter: value.sorter,
      ...newPagination,
    }));
  }, []);

  const fetchNewPage = useCallback(() => {
    if (query?.data?.pageIndex * query?.data?.pageSize < query?.data?.count) {
      setPaginationData((prevState) => ({
        ...prevState,
        pageIndex: prevState.pageIndex + 1,
      }));
      setAutoIncrementing(true);
      setLastPage(false);
    } else if (query?.data?.data !== undefined) {
      setLastPage(true);
    }
  }, [query?.data]);

  useEffect(() => {
    setLastPage(false);
    setAutoIncrementing(false);
    return;
  }, [paginationData.search]);
  return { ...query, data, refetch, fetchNewPage };
};

interface IControllerMutateApiHook
  extends Omit<
    IControllerQueryApiHook,
    'externalId' | 'options' | 'onSuccess'
  > {
  options?: IBasicMutationOptions;
  withoutToastError?: boolean;
}

export const useControllerMutateApiHook = <T>({
  uniqId,
  entityApi,
  successToast,
  options,
  withoutToastError,
}: IControllerMutateApiHook): UseMutationResult<T> =>
  useMutation([uniqId], entityApi as any, {
    onError: (err, variables, context) => {
      if (
        err &&
        typeof err === 'object' &&
        'errorCode' in err &&
        err.errorCode !== null
      )
        return;

      !withoutToastError && messageErrorToast(err);
      options?.onError && options.onError(err, variables, context);
    },
    onSuccess: (data, variables, context) => {
      successToast && messageSucessToast(successToast);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });

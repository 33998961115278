import { useState } from 'react';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useTranslation } from 'react-i18next';
import { lotStatusTypes } from '../../../../../../../Pages/Sectors/Inventory/Lot/List/lot.columns';
import { Modal } from '../../../../../../Modal';
import { i18n } from '../../../../../..';
import {
  EDIT_STATUS_LOT,
  FETCH_GET_LOT,
  FETCH_LIST_STATUS_LOTS
} from '../../../../../../../ControllerApiHook/UniqIds/Supply/LotKeys';
import { IPatchLotStatusRequest } from '../../../../../../../Data/Interfaces/request/Lot/IPatchLotStatusRequest';
import { useQueryClient } from '@tanstack/react-query';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook, 
  useControllerQueryListApiHook 
} from '@/ControllerApiHook/Controller';
import { IGetLotResponse, IListStatusLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

export const DynamicEditLotBreadcrumb = ({ match }: any) => {
  const { data: lot } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: [match.params.externalId]
  });

  return <span style={{ display: 'flex' }}>{lot?.numero}</span>;
};

export const DynamicEditLotStatusBreadcrumb = ({ match }: any) => {
  const { data: lot } = useControllerQueryApiHook<IGetLotResponse>({
    uniqId: FETCH_GET_LOT,
    entityApi: LotsAPI.getLot,
    externalId: [match.params.externalId]
  });

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const editStatusLot: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_LOT,
    entityApi: LotsAPI.patchLotStatus
  });

  const { data: situationLot } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_STATUS_LOTS,
    entityApi: LotsAPI.statusLotList
  });

  const [status, setStatus] = useState<number | undefined>(undefined);

  const patchLotStatus = async (values: IPatchLotStatusRequest) => {
    var body: IPatchLotStatusRequest = { ...values };

    const res = await editStatusLot.mutateAsync({
      ...body,
    });
    if (res.success) queryClient.invalidateQueries([FETCH_GET_LOT]);
    setStatus(undefined);
  };

  return lot && situationLot ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('breadCrumb.lots.detail.title')} | ${lot?.numero}`}
      <PermissionWrapper
        permission={2005}
        tooltip="noPermission.supply.lot.statusEdit"
      >
        <Status
          situation={situationLot[lot.situacao - 1]}
          type={lotStatusTypes[lot?.situacao - 1]}
          onClick={(value) => {
            setStatus(value);
          }}
        />
      </PermissionWrapper>
      <Modal
        title={`${i18n.t('modal.editModal.single.title.before')} "${
          lot.numero
        }"?`}
        body={
          <div style={{ padding: '20px', maxWidth: 400 }}>
            <ReactMarkdown>
              {t('modal.editModal.single.confirm.areYouSureLotStatus', {
                numero: `**${lot.numero}**`,
                produto: `**${lot.produto}**`,
                status: `**${
                  situationLot?.find((x: IListStatusLotResponse) => x.id === status)?.descricao
                }**`,
              })}
            </ReactMarkdown>
          </div>
        }
        visible={!!status}
        onOkClick={() =>
          status &&
          patchLotStatus({
            lotesExternalId: [match.params.externalId],
            situacao: status,
          })
        }
        okButtonName={
          editStatusLot.isLoading
            ? i18n.t('modal.editModal.single.confirm.editStatusLoading')
            : i18n.t('modal.editModal.single.confirm.editStatus')
        }
        loading={editStatusLot.isLoading}
        onCancelClick={() => setStatus(undefined)}
        onClose={() => setStatus(undefined)}
      />
    </div>
  ) : (
    <></>
  );
};

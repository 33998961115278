import React, { useCallback, useState } from 'react';
import {
  CHANGE_PRESCRIBER_STATUS,
  GET_PRESCRIBER
} from '../../../../../../../../ControllerApiHook/UniqIds/People/PrescribersKeys';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { PermissionWrapper } from '../../../../../../../PermissionWrapper';
import { i18n, Skeleton, Text } from '../../../../../../../';
import { Status } from '../../../../Status';
import { Modal } from '../../../../../../../Modal';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { PermissionEnum } from '../../../../../../../../Data/Interfaces/model/permissionEnum';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { PrescribersAPI } from '@/Data/API/People/PrescribersApi';
import { IGetPrescriberResponse } from '@/Data/Interfaces/response/Prescribers/IPrescribersResponse';
import { messageErrorToast, messageSucessToast } from '@/Utils/MessageErrorToast';

export const DynamicEditPrescribertBreadcrumb = ({ match }: any) => {
  const { data: prescriber } = useControllerQueryApiHook<IGetPrescriberResponse>({
    uniqId: GET_PRESCRIBER,
    entityApi: PrescribersAPI.getPrescribers,
    externalId: [match.params.externalId]
  });

  const { t } = useTranslation();

  const changeStatus: any = useControllerMutateApiHook({
    uniqId: CHANGE_PRESCRIBER_STATUS,
    entityApi: PrescribersAPI.changePrescriberStatus,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(i18n.t('prescribers.messages.changeStatus'))
    }
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);
  const queryClient = useQueryClient();

  const onEditStatus = useCallback(
    async (value: any) => {
      const res = await changeStatus.mutateAsync({
        prescritoresExternalId: [match.params.externalId],
        ativo: value,
      });
      if (res.success) {
        queryClient.invalidateQueries([GET_PRESCRIBER]);
      }
    },
    [changeStatus, match.params.externalId, queryClient]
  );

  if (!prescriber) {
    return (
      <div style={{ width: 400 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        type="heading3-bold"
        children={`${t('prescribers.prescriberTitle')} | ${
          prescriber.nomeCompleto
        } | #${prescriber.codigoRegistro}`}
      />
      <PermissionWrapper
        permission={PermissionEnum.Vendas_Prescritores_AlterarStatus}
        tooltip="noPermission.sale.prescriber.editStatus"
      >
        <Status
          type={prescriber?.ativo ? 'success' : 'disabled'}
          ative={prescriber?.ativo || false}
          onClick={(value) => {
            value ? onEditStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
        />
      </PermissionWrapper>
      <Modal
        body={
          <Divflex style={{ padding: '20px', maxWidth: 400 }}>
            <Text type="ui-tiny-content" withoutMarginBottom>
              <ReactMarkdown>
                {t('prescribers.editPage.statusModal.confirmation', {
                  prescriber: `**${prescriber.nomeCompleto}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        title={t('prescribers.editPage.statusModal.inactivate')}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => {
          status !== undefined && onEditStatus(status);
          setModalVisible(false);
        }}
        onClose={() => setModalVisible(false)}
        okButtonName={t('common.inactivate')}
        loading={changeStatus.isLoading}
      />
    </div>
  );
};

export const EditPrescribertBreadcrumb = ({ match }: any) => {
  const { data: prescriber } = useControllerQueryApiHook<IGetPrescriberResponse>({
    uniqId: GET_PRESCRIBER,
    entityApi: PrescribersAPI.getPrescribers,
    externalId: [match.params.externalId]
  });

  return <Text type="ui-tiny-bold" children={prescriber?.nomeCompleto} />;
};

import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { StockBalanceAPI } from '@/Data/API/Supply/StockBalanceApi';
import { FETCH_GET_STOCK_BALANCE_BY_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/StockBalanceKeys';
import { useParams } from 'react-router';

export const useBatchControlCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: stockBalance }: any = useControllerQueryApiHook({
    uniqId: FETCH_GET_STOCK_BALANCE_BY_PRODUCT,
    entityApi: StockBalanceAPI.getStockBalanceByProductGet,
    externalId: [externalId],
    enabled: !!externalId,
  });

  return {
    stockBalance,
  };
};

import { useControllerQueryApiHook } from "@/ControllerApiHook/Controller";
import { FETCH_GET_PRODUCT } from "@/ControllerApiHook/UniqIds/Supply/ProductKeys";
import { ProductAPI } from "@/Data/API/Supply/Product";
import { IProductResponse } from "@/Data/Interfaces/response/Product/IProductRespose";
import { IUseCallAPI } from "./interfaces";

export const useProductCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: [productExternalId]
  });

  return {
    currentProduct
  };
};
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_POSOLOGY,
  EDIT_POSOLOGY,
  FETCH_GET_POSOLOGY,
  FETCH_LIST_POSOLOGY,
} from '@/ControllerApiHook/UniqIds/Production/PosologyKeys';
import { PosologyAPI } from '@/Data/API/Production/Posology';
import { IGetPosology } from '@/Data/Interfaces/response/Posology/IPosologyResponse';
import { IUseCallAPI } from './interfaces';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editPosology, isLoading: isLoadingEditPosology }: any =
    useControllerMutateApiHook({
      uniqId: EDIT_POSOLOGY,
      entityApi: PosologyAPI.editPosology,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
          queryClient.invalidateQueries([FETCH_GET_POSOLOGY]);
          history.push('/production/posology');
        },
      },
    });

  const { data: posology, isLoading: loadingPosology } =
    useControllerQueryApiHook<IGetPosology>({
      uniqId: FETCH_GET_POSOLOGY,
      entityApi: PosologyAPI.getPosology,
      externalId: [externalId],
    });

  const { mutateAsync: deletePosology } = useControllerMutateApiHook({
    uniqId: DELETE_POSOLOGY,
    entityApi: PosologyAPI.deletePosology,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
        queryClient.invalidateQueries([FETCH_GET_POSOLOGY]);
        history.push('/production/posology');
      },
    },
  });

  return {
    editPosology,
    isLoadingEditPosology,
    posology,
    loadingPosology,
    deletePosology,
  };
};

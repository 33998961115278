import { Link, Text } from '@/Components';
import { statusTypes } from '@/Utils/StatusUtils';
import DateUtils from '@/Utils/DateUtils';
import { LinkButton } from '@/Components/Layouts/LinkButton';
import { IListStatusLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { Status } from '@/Components/HeaderMenu/Components/BreadCrumb/Status';
import { CurrencyText } from '@/Components/CurrencyText';
import { IPreferences } from '@/Utils/ThemeUtils';

export const lotColumns = (
  t: any,
  preferences?: IPreferences,
  statusLot?: IListStatusLotResponse[]
) => [
  {
    title: t('supply.lot.list.lot'),
    dataIndex: 'numero',
    key: 'numero',
  },
  {
    title: t('supply.lot.list.product'),
    dataIndex: 'produto',
    width: '25%',
    key: 'produto',
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-content"
        to={`/inventory/lot/${row?.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('supply.lot.list.supplier'),
    dataIndex: 'fornecedor',
    key: 'fornecedor',
    render: (x: any, row: any) => {
      return (
        <LinkButton
          link={`/order/supplier/${row?.fornecedorExternalId}/details`}
          text={x}
        />
      );
    },
  },
  {
    title: t('supply.lot.list.date'),
    dataIndex: 'dataLancamento',
    key: 'dataLancamento',
    sortColumn: 'dataLancamento',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (x: string) => (
      <Text
        type="ui-tiny-bold"
        children={`${DateUtils.convertDate(x, preferences?.padraoData)} ${t(
          'common.dateBreakApart'
        )} ${DateUtils.convertDate(x, preferences?.padraoHora)}`}
      />
    ),
  },
  {
    title: t('supply.lot.list.validity'),
    dataIndex: 'dataValidade',
    key: 'dataValidade',
    render: (x: string) => DateUtils.convertDate(x, preferences?.padraoData),
  },
  {
    title: t('supply.lot.list.quantity'),
    dataIndex: 'quantidade',
    key: 'quantidade',
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        <CurrencyText
          value={x}
          suffix={` ${row?.unidadeAbreviacao}`}
          decimalScale={2}
        />
      </Text>
    ),
  },
  {
    title: t('supply.lot.list.status'),
    dataIndex: 'situacao',
    key: 'situacao',
    width: '13%',
    render: (x: any) =>
      statusLot && (
        <Status
          situation={statusLot[x - 1]}
          type={lotStatusTypes[x - 1]}
          withoutDropdown
        />
      ),
  },
];

export const lotStatusTypes = [
  'success' as statusTypes,
  'attention' as statusTypes,
  'danger' as statusTypes,
];

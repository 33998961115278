import { useCallback, useState } from 'react';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Text';
import { history } from '../../../../../../../Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  EDIT_STATUS_PACKING_CLASSIFICATION,
  FETCH_GET_PACKING_CLASSIFICATION,
  FETCH_LIST_PACKING_CLASSIFICATION
} from '../../../../../../../ControllerApiHook/UniqIds/Production/PackingClassificationKeys';
import { PermissionEnum } from '../../../../../../../Data/Interfaces/model/permissionEnum';
import ReactMarkdown from 'react-markdown';
import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { PackingClassificationAPI } from '@/Data/API/Production/PackingClassification';
import { IGetPackingClassification } from '@/Data/Interfaces/response/PackingClassification/IPackingClassificationResponse';

export const DynamicEditPackingClassificationBreadcrumb = ({ match }: any) => {
  const { 
    data: packingClassification 
  } = useControllerQueryApiHook<IGetPackingClassification>({
    uniqId: FETCH_GET_PACKING_CLASSIFICATION,
    entityApi: PackingClassificationAPI.getCurrentClassificationPacking,
    externalId: [match.params.externalId]
  });

  return (
    <div style={{ display: 'flex' }}>{packingClassification?.descricao}</div>
  );
};

export const DynamicEditPackingClassificationStatusBreadcrumb = ({
  match,
}: any) => {
  const { data: packingClassification } = useControllerQueryApiHook<IGetPackingClassification>({
    uniqId: FETCH_GET_PACKING_CLASSIFICATION,
    entityApi: PackingClassificationAPI.getCurrentClassificationPacking,
    externalId: [match.params.externalId]
  });

  const editStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_PACKING_CLASSIFICATION,
    entityApi: PackingClassificationAPI.patchPackingClassificationStatus
  });

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);

  const changeStatus = useCallback(
    async (value: boolean) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: value,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_PACKING_CLASSIFICATION]);
        history.push('/production/packing-classification');
      }
    },
    [editStatus, match.params.externalId, queryClient]
  );

  const onEditStatus = useCallback(
    async (status: any) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: status,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_PACKING_CLASSIFICATION]);
        history.push('/production/packing-classification');
      }
    },
    [queryClient, match.params.externalId, editStatus]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t(
        'breadCrumb.production.packingClassification.packingClassificationDetail'
      )} |  ${packingClassification?.descricao}`}
      <PermissionWrapper
        permission={
          PermissionEnum.Producao_ClassificacaoEmbalagem_AlterarStatus
        }
        tooltip="noPermission.production.packingClassification.statusEdit"
      >
        <Status
          type={packingClassification?.ativo ? 'success' : 'disabled'}
          ative={packingClassification?.ativo || false}
          onClick={(value) => {
            value ? changeStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
          permission={{
            permission:
              PermissionEnum.Producao_ClassificacaoEmbalagem_AlterarStatus,
            tooltip: 'noPermission.production.packingClassification.statusEdit',
          }}
        />
      </PermissionWrapper>
      <Modal
        title={`${t('modal.inactive')} ${t(
          'production.packingClassification.details.changeStatusDescriptionTitle'
        )} "${packingClassification?.descricao}"?`}
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text type="ui-tiny-content" withoutMarginBottom color="text-50">
              <ReactMarkdown>
                {t(
                  'production.packingClassification.details.changeStatusDescription',
                  {
                    description: `**${packingClassification?.descricao}** ?`,
                  }
                )}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t(
          'production.packingClassification.details.statusTypes.inactive'
        )}
      />
    </div>
  );
};

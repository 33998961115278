import Spinner from '@/Components/Spinner';
import { IItems } from '../../../Interface';
import { AvatarOption } from './Avatar';
import { DescriptionOption } from './Description';

import styles from './OptionsLabel.module.scss';

export const Options = ({
  item,
  currentSearch,
  dataTestId
}: {
  item: IItems;
  currentSearch: string;
  dataTestId?: string
}) => {
  if (item.loading)
    return (
      <div className={styles['spinner']}>
        <Spinner />
      </div>
    );

  return item?.avatar ? (
    <AvatarOption dataTestId={dataTestId} item={item} currentSearch={currentSearch} />
  ) : (
    <DescriptionOption dataTestId={dataTestId} item={item} currentSearch={currentSearch} />
  );
};

import { i18n, Icon, Tooltip } from '@/Components';
import { ListTooltip } from '../../PurchaseOrderInput';

export const usePurchaseOrdersMemorizations = () => {
  const headerPurchaseOrderModalTable = {
    hasSearch: true,
    searchPlaceholder: i18n.t('purchasing.invoice.create.searchForPurchase'),
  };

  const purchaseOrderModalTableColumns = [
    {
      title: i18n.t('purchasing.invoice.create.requestNumber'),
      dataIndex: 'sequenciaGroupTenant',
      sortColumn: 'sequenciaGroupTenant',
      defaultSortOrder: 'ascend',
      key: '1',
      render: (text: string, purchase: any) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <strong>#{text}</strong>
          <Tooltip
            showMe
            title={<ListTooltip hasTotal={false} purchaseOrder={purchase} />}
          >
            <Icon color="status-disabled-base" icon="exclamation-upside-down" />
          </Tooltip>
        </div>
      ),
      sorter: (a: any, b: any) => {
        return a.sequenciaGroupTenant - b.sequenciaGroupTenant;
      },
    },
    {
      title: i18n.t('purchasing.invoice.create.releaseDate'),
      dataIndex: 'dataLancamento',
      key: '2',
    },
    {
      title: i18n.t('purchasing.invoice.create.deliveryForecast'),
      dataIndex: 'previsaoEntrega',
      key: '3',
    },
    {
      title: i18n.t('purchasing.invoice.create.totalValuePurchaseOrder'),
      dataIndex: 'totalPedido',
      key: '4',
    },
  ];

  return { purchaseOrderModalTableColumns, headerPurchaseOrderModalTable };
};

import { IUseMemorizations } from './interfaces';

export const useDetailMemorizations = ({
  standardFormula,
  reason,
}: IUseMemorizations) => {
  const initialValues = standardFormula
    ? {
        ...standardFormula,
        produtoExternalId: standardFormula.produtoExternalId,
        productLabelInitialValue: {
          id: standardFormula?.produtoExternalId,
          label: standardFormula?.descricaoProdutoFormula,
        },
        formaFarmaceutica: {
          id: standardFormula?.formaFarmaceuticaExternalId,
          label: standardFormula?.descricaoFormaFarmaceutica,
        },
        quantidadePadrao: standardFormula?.quantidadePadrao,
        unidadeMedidaId: standardFormula?.unidadeMedidaAbreviacao,
        formulaPadraoDesmembramento:
          standardFormula?.formulaPadraoDesmembramentoId,
        diasValidade: standardFormula?.diasValidade,
        diluicao: standardFormula?.diluicao,
        razao: reason,
        densidade: standardFormula?.densidade,
        procedimento: standardFormula?.procedimento,
        rodape: standardFormula?.rodape,
      }
    : undefined;

  return {
    initialValues,
  };
};

import { useMemo } from 'react';
import { Text } from '@/Components';
import { FooterThisActionIsPermanent } from '@/Components/Table/components/Modals/DeleteModal';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { Col, Row } from 'antd';
import { TextArea, TextInput } from '@/Components';
import { TextInputWithMask } from '@/Components/Inputs/TextInputWithMask';
import { NumberInput } from '@/Components/Inputs/NumberInput';

import DateUtils from '@/Utils/DateUtils';
import { PersonType } from '../../Tabs/Details/Components/InfoCard';

export const useModalMemorizations = ({
  clientName,
  client,
  preferences,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const deleteModalBody = useMemo(
    () => (
      <div style={{ padding: '20px' }}>
        <Text
          type="ui-tiny-content"
          withoutMarginBottom
          style={{ marginBottom: 20 }}
        >
          <ReactMarkdown>
            {t('client.listPage.deleteModal.description', {
              client: `**${clientName}**`,
            })}
          </ReactMarkdown>
        </Text>
        <FooterThisActionIsPermanent />
      </div>
    ),
    [t, clientName]
  );

  const infoModalBody = useMemo(
    () => (
      <div style={{ padding: '20px' }}>
        <Row gutter={[16, 0]}>
          <Col flex="60%">
            <TextInput
              name="nome"
              placeHolder={t('client.createPage.fullNamePlaceholder')}
              label={t('client.createPage.fullName')}
              withStatusIcon
              required
            />
          </Col>
          <Col flex="20%">
            {client?.tipoPessoa === PersonType.legalEntity ? (
              <TextInputWithMask
                name="CPF"
                placeHolder={'000.000.000-00'}
                label={'CPF'}
                withStatusIcon
                required
                mask="cpfMask"
              />
            ) : (
              <TextInputWithMask
                name="CNPJ"
                placeHolder={'00.000.000/0000-00'}
                label={'CNPJ'}
                withStatusIcon
                required
                mask="cnpjMask"
              />
            )}
          </Col>
          <Col flex="20%">
            <TextInputWithMask
              name="dataNascimento"
              placeHolder={`${t('common.exampleAbbreviation')}: 01/03/1988`}
              label={t('client.editPage.birthDate')}
              mask="dataMask"
              withStatusIcon
              leftIcon="calendar"
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col flex="23%">
            <NumberInput
              name="descontoProdutosAcabados"
              label={[{ children: t('client.editPage.descProduct') }]}
              placeHolder="0,00"
              leftIcon={{ titleString: '%' }}
              fixedDecimalScale
              decimalScale={2}
              withDecimalSeparator
              withStatusIcon
              withThousandSeparator
            />
          </Col>
          <Col flex="23%">
            <NumberInput
              name="descontoFormulas"
              label={[{ children: t('client.editPage.descFormula') }]}
              placeHolder="0,00"
              leftIcon={{ titleString: '%' }}
              fixedDecimalScale
              decimalScale={2}
              withDecimalSeparator
              withStatusIcon
              withThousandSeparator
            />
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <TextArea
              name="observacao"
              label={t('client.createPage.observation')}
              maxLength={500}
              heightTextArea={104}
              withStatusIcon
              placeholder={t('client.createPage.observationPlaceholder')}
            />
          </Col>
        </Row>
      </div>
    ),
    [t, client?.tipoPessoa]
  );

  const initialValuesInfoModal = useMemo(
    () => ({
      externalId: client?.externalId,
      nome: client?.nome,
      dataNascimento: DateUtils.convertDate(
        client?.dataNascimento,
        preferences?.padraoData
      ),
      observacao: client?.observacao,
      descontoProdutosAcabados: client?.descontoProdutosAcabados,
      descontoFormulas: client?.descontoFormulas,
      ...(client?.tipoPessoa === PersonType.legalEntity
        ? { CPF: client?.cpf }
        : { CNPJ: client?.cnpj }),
      pessoa: client?.tipoPessoa,
    }),
    [preferences, client]
  );

  const disabledButtonFormNames = useMemo(
    () =>
      client?.tipoPessoa === PersonType.legalEntity
        ? ['nome', 'CPF']
        : ['nome', 'CNPJ'],
    [client]
  );

  return {
    deleteModalBody,
    infoModalBody,
    initialValuesInfoModal,
    disabledButtonFormNames,
  };
};

import { FC } from 'react';
import { Icon, Text } from '@/Components';

import styles from './SelectItem.module.scss';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { GET_CLIENT } from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetClientAdressResponse } from '@/Data/Interfaces/response/Client/IClientResponse';
import { ClientAPI } from '@/Data/API/People/ClientApi';

export interface AfterSelectedClientAddressProps {
  saleOrder?: IGetSaleOrderResponse;
  onClick?: () => void;
}

export const AfterSelectedClientAddressModal: FC<
  AfterSelectedClientAddressProps
> = ({ saleOrder, onClick }) => {
  const { t } = useTranslation();

  const { data: addressClient } =
    useControllerQueryApiHook<IGetClientAdressResponse>({
      uniqId: GET_CLIENT,
      entityApi: ClientAPI.getClientAddress,
      externalId: [saleOrder?.clienteEnderecoExternalId],
    });

  return (
    <div className={styles['body']} onClick={onClick}>
      <div className={styles['content']}>
        <button className={styles['info']} type="button">
          <Divflex>
            <Text
              type="ui-tiny-content"
              color="text-50"
              className={styles['text-contents']}
            >
              {addressClient?.descricao}
            </Text>
            {addressClient?.principal && (
              <Icon
                icon="favorite"
                size="S"
                color="users09"
                style={{ margin: '3px 4px' }}
              />
            )}
          </Divflex>
          <Text
            type="ui-tiny-content"
            color="text-200"
            className={styles['text-contents']}
          >
            {`${addressClient?.logradouro || ``}, ${
              addressClient?.numero || ``
            }, ${addressClient?.bairro || ``}, ${
              addressClient?.cidadeDescricao || ``
            }/${addressClient?.estadoAbreviacao || ``}, ${
              addressClient?.paisDescricao || ``
            }`}
          </Text>
          <Text
            type="ui-tiny-content"
            color="text-200"
            className={styles['text-contents']}
          >
            {saleOrder?.taxaEntrega && saleOrder?.trocoEntrega
              ? `${t(
                  'saleOrder.editOrder.sideRightMenu.infosSaleOrder.subTextDelivery'
                )} ${currencyFormater(
                  saleOrder?.taxaEntrega
                )} (${currencyFormater(saleOrder?.trocoEntrega)})`
              : ``}
          </Text>
          <Text
            type="ui-tiny-content"
            style={{ textAlign: 'left', fontStyle: 'italic' }}
            color="text-300"
          >
            {saleOrder?.observacaoEntrega
              ? `Obs: ${saleOrder?.observacaoEntrega}`
              : ``}
          </Text>
        </button>

        <div className={styles['actions']}>
          <div className={styles['icon-button']}>
            <Icon icon="chevron-right" size="M" color="text-300" />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../../Components';
import { ConglomerateEditForm } from './Tabs/Details';
import { useParams } from 'react-router';
import {
  DeleteModalBody,
  getDeleteModalTitle,
} from '../List/Modals/DeleteModal';
import { Modal } from '../../../../../Components/Modal';
import { GET_CONGLOMERATE_TYPE } from '../../../../../ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { CompanyAdminListTab } from './Tabs/Company';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetConglomerateResponse } from '@/Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';

export const ConglomerateForm: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const params: Readonly<
    Partial<{ externalId: string | undefined; tab: string }>
  > = useParams();

  const { data: conglomerate, isLoading: conglomerateLoading } =
    useControllerQueryApiHook<IGetConglomerateResponse>({
      uniqId: GET_CONGLOMERATE_TYPE,
      entityApi: ConglomerateAPI.getConglomerate,
      externalId: [params.externalId],
    });

  const { t } = useTranslation();

  return (
    <PageLoading loading={conglomerateLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('adminBootis.editConglomerate.tab.details'),
            key: 'details',
            children: params.externalId && (
              <ConglomerateEditForm
                disabled={disabled}
                externalId={params.externalId}
                onDisabledEditButton={setDisabled}
              />
            ),
          },
          {
            label: t('adminBootis.editConglomerate.tab.company'),
            key: 'companys',
            children: params?.externalId && (
              <CompanyAdminListTab UrlExternalId={params.externalId} />
            ),
          },
        ]}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled &&
            params.tab !== 'companys' && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
            }),
          dataTestId: 'right-button-edit',
        }}
      />

      <Modal
        visible={visibleModal}
        onCancelClick={() => setVisibleModal(false)}
        onClose={() => setVisibleModal(false)}
        title={getDeleteModalTitle(t, [conglomerate], true)}
        okButtonColor={'status-danger-base'}
        okButtonName={t('common.delete')}
        body={
          <DeleteModalBody
            rows={[
              { ...conglomerate, empresas: conglomerate?.empresas.length },
            ]}
            hasCompany={false}
          />
        }
        withoutOkButton
      />
    </PageLoading>
  );
};

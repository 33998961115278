import { useParams } from 'react-router-dom';
import { FETCH_GET_SUBGROUP } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IProductSubGroupResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';

export const useEditCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { 
    data, 
    isLoading 
  } = useControllerQueryApiHook<IProductSubGroupResponse>({
    uniqId: FETCH_GET_SUBGROUP,
    entityApi: ProductAPI.getproductSubGroup,
    externalId: [externalId]
  });

  return {
    data,
    isLoading
  };
};
import { messageSucessToast } from '@/Utils/MessageErrorToast';
import { IUsePermissionFormCallAPI } from './interfaces';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  GET_USER_TYPE,
  UPDATE_USER_PERMISSION,
} from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';

export const usePermissionFormCallAPI = ({
  userExternalId,
  changeModalVisible,
  queryClient,
  t,
}: IUsePermissionFormCallAPI) => {
  const user = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_USER_TYPE,
    entityApi: UserAPI.getUser,
    externalId: [userExternalId],
  });

  const updateUserPermission: any = useControllerMutateApiHook({
    uniqId: UPDATE_USER_PERMISSION,
    entityApi: UserAPI.updateUserPermission,
    options: {
      onError: () => changeModalVisible(true),
      onSuccess: () => {
        messageSucessToast(t('admin.editUserPage.permission.sucessAlert'));
        queryClient.invalidateQueries([GET_USER_TYPE]);
      },
    },
  });

  return {
    updateUserPermission,
    user,
  };
};

import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../../../../../../../Components';
import _ from 'lodash';
import styles from '../../../../../createPharmaceuticalForm.module.scss';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { PharmaceuticalFormAPI } from '@/Data/API/Production/PharmaceuticalForm';
import { THERE_IS_PHARMACEUTICAL_FORM_TYPE } from '@/ControllerApiHook/UniqIds/Production/PharmaceuticalFormKeys';

const DescriptionPharmaceuticalForm: React.FC = () => {
  const { t } = useTranslation();
  const form = useFormikContext();

  const { mutateAsync: mutateAsyncPharmaceutical } = useControllerMutateApiHook(
    {
      entityApi: PharmaceuticalFormAPI.pharmaceuticalFormDescription,
      uniqId: THERE_IS_PHARMACEUTICAL_FORM_TYPE,
      options: {
        onError: () =>
          form.setFieldError(
            'description',
            t(
              'production.pharmaceuticalForm.create.errors.errorPharmaceuticalFormDescriptionExist'
            )
          ),
        onSuccess: () => form.setFieldError('description', undefined),
      },
    }
  );

  const fetchInputPharmaceuticalFormDescription = useMemo(
    () =>
      _.debounce(async (value: string) => {
        try {
          return await mutateAsyncPharmaceutical(value);
        } catch {
          return undefined;
        }
      }, 500),
    [mutateAsyncPharmaceutical]
  );

  return (
    <TextInput
      className={styles['text-input']}
      name="descricao"
      placeHolder={t(
        'production.pharmaceuticalForm.create.descriptionPlaceholder'
      )}
      required
      label={t('production.pharmaceuticalForm.create.description')}
      onChange={fetchInputPharmaceuticalFormDescription}
    />
  );
};

export default DescriptionPharmaceuticalForm;

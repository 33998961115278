import { CSSProperties, FC, useEffect, useState } from 'react';
import { Loading } from '@/Components/Loading';
import { Color } from '@/Utils/ColorUtils';
import { Icon, Text } from '../..';
import { IText, TextType } from '../../Text';
import { IInputLabel } from '../Dropdown';
import { IField } from '../interfaces/IField';

import styles from './Label.module.scss';

export const patternLabelStyle: {
  typeLabel: TextType;
  colorLabel: Color;
} = { typeLabel: 'ui-tiny-semibold', colorLabel: 'text-50' };

export interface ILabel extends IText {
  children: string;
  className?: string;
  required?: boolean;
  withBottomMargin?: boolean;
}

export const Label: FC<ILabel> = ({
  type,
  color,
  children,
  className,
  required,
  withBottomMargin,
  favoriteIcon,
}) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={`${styles['Label']} ${className} ${
        required ? styles['label-required'] : ''
      } ${withBottomMargin ? styles['bottom-margin'] : ''}`}
    >
      <Text type={type} color={color} children={children} />
      {favoriteIcon && (
        <Icon
          icon="favorite"
          style={{ marginLeft: '5px' }}
          color="users09"
          size="S"
        />
      )}
    </div>
  );
};

export interface ILabelInput {
  label?: IInputLabel[] | string;
  required?: boolean;
  withStatusIcon?: boolean | 'show';
  props?: IField;
  withLoadingIcon?: boolean;
  isSuccessIcon?: boolean;
  style?: CSSProperties;
}

export const InputLabel: FC<ILabelInput> = ({
  label,
  required,
  withStatusIcon,
  props,
  withLoadingIcon,
  isSuccessIcon,
  style,
}) => {
  const [showIconStatus, setShowIconStatus] = useState(false);

  useEffect(() => {
    isSuccessIcon !== undefined && setShowIconStatus(isSuccessIcon);
    setTimeout(() => setShowIconStatus(false), 3000);
  }, [isSuccessIcon]);

  if (typeof label === 'string')
    return (
      <div className={styles['div-label']}>
        <>
          <Label
            className={styles['label']}
            type={'ui-tiny-semibold'}
            color={'text-50'}
            required={required}
            style={style}
          >
            {label}
          </Label>
          {
            withStatusIcon && 
            props?.meta.value !== props?.meta.initialValue && 
            props?.meta.initialValue !== undefined &&
            (
              <Icon
                icon="status-attention"
                size="SS"
                className={styles['icon-status']}
              />
            )
          }
          {withStatusIcon === 'show' && (
            <Icon
              icon="status-attention"
              size="SS"
              className={styles['icon-status']}
            />
          )}
          {showIconStatus && (
            <Icon
              icon="check"
              backgroundColor="status-success-base"
              size="M"
              className={styles['icon-check']}
            />
          )}
          {withLoadingIcon && (
            <Loading size="M" className={styles['icon-loading']} />
          )}
        </>
      </div>
    );

  return (
    <div className={styles['div-label']}>
      {label?.map((lab, index) => (
        <Label
          className={styles['label']}
          type={lab.typeLabel || 'ui-tiny-semibold'}
          color={lab.colorLabel || 'text-50'}
          required={required}
          key={`input-with-mask-label-${index}`}
          style={style}
        >
          {lab.children}
        </Label>
      ))}
      {withStatusIcon && props?.meta.value !== props?.meta.initialValue && (
        <Icon
          icon="status-attention"
          size="SS"
          className={styles['icon-status']}
        />
      )}
    </div>
  );
};

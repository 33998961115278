import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_CAPSULE_COLORS,
  EDIT_CAPSULE_COLORS,
  FETCH_GET_CAPSULE_COLORS,
  FETCH_LIST_CAPSULE_COLORS,
  THERE_IS_CAPSULE_COLORS_TYPE,
} from '@/ControllerApiHook/UniqIds/Production/CapsuleColorsKeys';
import { CapsulseColorsAPI } from '@/Data/API/Production/CapsuleColors';
import { IGetCapsuleColor } from '@/Data/Interfaces/response/CapsuleColors/ICapsuleColorsResponse';
import { IUseCallAPI } from './interfaces';

export const useEditCallAPI = ({ externalId, props, t }: IUseCallAPI) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: editCapsuleColor,
    isLoading: isLoadingEditCapsuleColor,
  }: any = useControllerMutateApiHook({
    uniqId: EDIT_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.editCapsuleColor,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_CAPSULE_COLORS]);
        queryClient.invalidateQueries([FETCH_GET_CAPSULE_COLORS]);
        history.push('/production/capsule-colors');
      },
    },
  });

  const {
    mutateAsync: deleteCapsuleColor,
    isLoading: isLoadingDeleteCapsuleColor,
  }: any = useControllerMutateApiHook({
    uniqId: DELETE_CAPSULE_COLORS,
    entityApi: CapsulseColorsAPI.deleteCapsuleColors,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_LIST_CAPSULE_COLORS]);
        history.push('/production/capsule-colors');
      },
    },
  });

  const { data: capsuleColor, isLoading: loading } =
    useControllerQueryApiHook<IGetCapsuleColor>({
      uniqId: FETCH_GET_CAPSULE_COLORS,
      entityApi: CapsulseColorsAPI.getCurrentCapsuleColor,
      externalId: [externalId],
    });

  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_CAPSULE_COLORS_TYPE,
    entityApi: CapsulseColorsAPI.isCapsuleColorNameAlreadyRegistred,
    options: {
      onError: () => {
        props?.setErrors({
          ...props?.errors,
          corCapsula: t(
            'production.capsuleColors.errors.errorCapsuleColorNameExist'
          ),
        });
      },
      onSuccess: () => {
        props?.setErrors({
          ...props?.errors,
          corCapsula: undefined,
        });
      },
    },
  });

  return {
    editCapsuleColor,
    isLoadingEditCapsuleColor,
    capsuleColor,
    loading,
    mutateAsync,
    deleteCapsuleColor,
    isLoadingDeleteCapsuleColor,
  };
};

import { ICreatePharmaceuticalForm } from '@/Data/Interfaces/request/PharmaceuticalForm/IPharmaceuticalFormRequest';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  createPharmaceuticalForm,
}: IUseFunctions) => {
  const onSubmit = async (values: ICreatePharmaceuticalForm) =>
    await createPharmaceuticalForm(values);

  return {
    onSubmit,
  };
};

import { useMemo } from 'react';
import { Row } from 'antd';
import { Name } from '../Components/Name';
import { Birthday } from '../Components/Birthday';
import { RegisterType } from '../Components/RegisterType';
import { UfRegister } from '../Components/UfRegister';
import { RegisterNumber } from '../Components/RegisterNumber';
import { Specialties } from '../Components/Specialties';
import { ProductDiscount } from '../Components/ProductDiscount';
import { FormulaDiscount } from '../Components/FormulaDiscount';
import { Observation } from '../Components/Observation';
import { ISpecialtites, IUseMemorizations } from './interfaces';
import DateUtils from '@/Utils/DateUtils';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';

export const useInfoModalMemorizations = ({
  prescriber,
  preferences,
  recordTypeList,
  refetchRecordTypeList,
  fetchNewRecordPage,
  recordTypeListLoading,
  listState,
  refetchStateList,
  fetchNewStatePage,
  listStateLoading,
}: IUseMemorizations) => {
  const modalBody = useMemo(
    () => (
      <div style={{ padding: '20px' }}>
        <ConnectedFocusError />
        <Row gutter={[16, 0]}>
          <Name />
          <Birthday />
        </Row>
        <Row gutter={[16, 0]}>
          <RegisterType
            recordTypeList={recordTypeList}
            fetchNewRecordPage={fetchNewRecordPage}
            recordTypeListLoading={recordTypeListLoading}
            refetchStateList={refetchStateList}
          />
          <UfRegister
            listState={listState}
            fetchNewStatePage={fetchNewStatePage}
            listStateLoading={listStateLoading}
            refetchRecordTypeList={refetchRecordTypeList}
          />
          <RegisterNumber />
        </Row>
        <Row gutter={[16, 0]}>
          <Specialties />
        </Row>
        <Row gutter={[16, 0]}>
          <ProductDiscount />
          <FormulaDiscount />
        </Row>
        <Row gutter={[16, 0]}>
          <Observation />
        </Row>
      </div>
    ),
    [
      recordTypeList,
      fetchNewRecordPage,
      recordTypeListLoading,
      refetchStateList,
      listState,
      fetchNewStatePage,
      listStateLoading,
      refetchRecordTypeList,
    ]
  );

  const initialValues = useMemo(
    () => ({
      externalId: prescriber?.externalId,
      specialtiesLabel: prescriber?.prescritorEspecialidades.map((x) => ({
        label: x.descricao,
        id: x.especialidadeExternalId,
      })),
      nomeCompleto: prescriber?.nomeCompleto,
      tipoRegistroId:
        prescriber?.tipoRegistroId && parseInt(prescriber.tipoRegistroId),
      ufRegistroExternalId: prescriber?.ufRegistroExternalId,
      codigoRegistro: prescriber?.codigoRegistro,
      dataNascimento: DateUtils.convertDate(
        prescriber?.dataNascimento,
        preferences?.padraoData
      ),
      observacao: prescriber?.observacao,
      descontoProdutosAcabados: prescriber?.descontoProdutosAcabados,
      descontoFormulas: prescriber?.descontoFormulas,
      especialidadesExternalId: prescriber?.prescritorEspecialidades.map(
        (x: ISpecialtites) => x.especialidadeExternalId
      ),
    }),
    [prescriber, preferences]
  );

  return {
    modalBody,
    initialValues,
  };
};

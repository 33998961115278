import _ from 'lodash';
import { isNumeric } from './IsNumericUtils';
import StorageUtils from './StorageUtils';

export const currencyTypes = ['pt-br', 'en-US']; //TIPO MOEDA:  1 = PT-BR, 2 = EN-US
const currencyTypesTag = ['BRL', 'USD'];
const currencySymbolTag = ['R$', '$'];
export const currencyType = {
  real: 0,
  dolar: 1,
};

export const currencyFormater = (value: number, unitMeasurement?: string) => {
  const preference = StorageUtils.getPreference();

  return !_.isNil(preference.tipoMoedaId)
    ? `${value?.toLocaleString(currencyTypes[preference.tipoMoedaId], {
        style: 'currency',
        currency: currencyTypesTag[preference.tipoMoedaId],
      })}${unitMeasurement ? ` /${unitMeasurement}` : ''}`
    : undefined;
};

export const getCurrencySymbol = () => {
  const preference = StorageUtils.getPreference();
  if (preference?.tipoMoedaId)
    return currencySymbolTag[preference?.tipoMoedaId];
};

export const convertCurrencyToInteger = (value?: string) => {
  if (typeof value === 'number') return value;
  const preference = StorageUtils.getPreference();
  if (value === undefined) return 0;
  let number = 0;

  if (preference.tipoMoedaId === currencyType.real)
    number = parseFloat(
      value.replace('.', '').replace(',', '.').replace('R$', '')
    );
  //TODO: arrumar replaces após os componentes aceitarem o padrão em dolar
  else
    number = parseFloat(
      value.replace('.', '').replace(',', '.').replace('$', '')
    );

  return isNumeric(number) ? number : 0;
};

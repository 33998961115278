import {
  CREATE_INCOMPATIBLE_PRODUCT,
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { 
  useControllerMutateApiHook, 
  useControllerQueryApiHook 
} from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IUseCallAPI } from './interfaces';

export const useCreateSynonymCallAPI = ({
  productExternalId
}: IUseCallAPI) => {
  const { data: currentProduct } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: [productExternalId]
  });

  const createSynonym = useControllerMutateApiHook({
    uniqId: CREATE_INCOMPATIBLE_PRODUCT,
    entityApi:  ProductAPI.createSynonym
  });

  return {
    currentProduct,
    createSynonym
  };
};
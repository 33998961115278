import { 
  GET_STANDARD_FORMULA 
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { 
  IGetStandardFormula 
} from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { useParams } from 'react-router';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';

export const useStandardFormulaSpecificationsDetailsCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: standardFormula } = useControllerQueryApiHook<IGetStandardFormula>({
    uniqId: GET_STANDARD_FORMULA,
    entityApi: StandardFormulaAPI.getStandardFormula,
    externalId: [externalId]
  });

  return {
    standardFormula
  };
};
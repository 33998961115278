import { useTranslation } from 'react-i18next';
import { FETCH_GET_ORDER_PURCHASE } from '../../../../../../../ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { Skeleton } from '../../../../../../Skeleton';
import { Status } from '../../../../../../../Pages/Sectors/Order/Purchase/List/Components/Status';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseOrderApi';
import { IPurchaseOrderGetResponse } from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';

export const DynamicInfosPurchaseOrderBreadcrumb = ({ match }: any) => {
  const { data: orderPurchase } = useControllerQueryApiHook<IPurchaseOrderGetResponse>({
    uniqId: FETCH_GET_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOrderPurchase,
    externalId: [match.params.externalId]
  });
  
  const { t } = useTranslation();
  return (
    <span style={{ display: 'flex' }}>
      {`${t('breadCrumb.purchasing.purchaseOrder.detail')} | #${
        orderPurchase ? orderPurchase?.numeroPedido : ''
      }`}
    </span>
  );
};

export const DynamicEditInfosPurchaseOrderBreadcrumb = ({ match }: any) => {
  const { data: orderPurchase } = useControllerQueryApiHook<IPurchaseOrderGetResponse>({
    uniqId: FETCH_GET_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOrderPurchase,
    externalId: [match.params.externalId]
  });

  const { t } = useTranslation();

  if (!orderPurchase) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('breadCrumb.purchasing.purchaseOrder.detail')} | #${
        orderPurchase ? orderPurchase?.numeroPedido : ''
      }`}
      <Status
        descriptionType="ui-tiny-bold"
        typePurchase={orderPurchase.status}
        withoutIcon
        style={{ marginLeft: 12, borderRadius: 5 }}
      >
        {t(
          `purchasing.purchaseOrder.list.statusType.${orderPurchase.status - 1}`
        )}
      </Status>
    </div>
  );
};

export const DynamicPurchaseOrderBreadcrumb = ({ match }: any) => {
  const { data: orderPurchase } = useControllerQueryApiHook<IPurchaseOrderGetResponse>({
    uniqId: FETCH_GET_ORDER_PURCHASE,
    entityApi: PurchaseAPI.getOrderPurchase,
    externalId: [match.params.externalId]
  });

  return (
    <span style={{ display: 'flex' }}>{`#${orderPurchase?.numeroPedido}`}</span>
  );
};

import { FC, useState } from 'react';
import { PageLoading } from '../../../../../../../Layouts/PageLoading';
import { i18n, Table } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { FETCH_LIST_SALES_ORDER } from '../../../../../../../ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { orderCollums } from './order.columns';
import { ContainerPage } from '../../../../../../../Layouts/ContainerPage';
import { useQueryClient } from '@tanstack/react-query';
import StorageUtils from '@/Utils/StorageUtils';
import { CREATE_SERVICE } from '../../../../../../../ControllerApiHook/UniqIds/Sales/ServiceKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { CreateServiceModal } from '@/Pages/Sectors/Sales/Tickets/List/Modal/CreateOrder';

interface IList {
  externalId: string;
}

export const OrderList: FC<IList> = ({ externalId }) => {
  const [visibleCreateModal, setVisibleCreateModal] = useState(false);

  const { t } = useTranslation();

  const {
    data: serviceDataList,
    isLoading: loadingServiceList,
    refetch: refetchServiceList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_SALES_ORDER,
    entityApi: SalesOrderAPI.listSalesOrder,
    pagination: {
      sorter: { column: 'codigoPedidoVenda', direction: 'DESC' },

      filter: [{ filterName: 'clienteExternalId', value: externalId }],
    },
  });

  const preferences = StorageUtils.getPreference();

  const queryClient = useQueryClient();

  const createSaleOrder = useControllerMutateApiHook({
    uniqId: CREATE_SERVICE,
    entityApi: ServiceAPI.createService,
    options: {
      onSuccess: () => {
        setVisibleCreateModal(false);
        queryClient.invalidateQueries([FETCH_LIST_SALES_ORDER]);
        messageSucessToast(i18n.t('callCenter.messages.create'));
      },
      onError: (x) => messageErrorToast(x),
    },
  });

  const onSubmit = async (values: any) => {
    await createSaleOrder.mutateAsync({
      ...values,
      criarPedidoVenda: true,
    });
  };

  return (
    <>
      {externalId && (
        <PageLoading loading={loadingServiceList}>
          <ContainerPage>
            <Table
              columns={orderCollums(t, preferences)}
              headerOptions={{
                nameKey: 'codigoPedidoVenda',
                hasSearch: true,
                searchPlaceholder: t('saleOrder.list.searchPlaceholder'),
                newButton: {
                  label: t('saleOrder.list.new'),
                  onClick: () => setVisibleCreateModal(true),
                },
              }}
              loading={loadingServiceList}
              pagination={serviceDataList}
              onRefetch={refetchServiceList}
            />
          </ContainerPage>

          <CreateServiceModal
            changeVisible={(x) => setVisibleCreateModal(x)}
            visible={visibleCreateModal}
            clienteExternalId={externalId}
            onSubmit={onSubmit}
            isLoading={createSaleOrder.isLoading}
            t={t}
          />
        </PageLoading>
      )}
    </>
  );
};

import { useEffect, Fragment } from 'react';
import { FormikContextType, FormikValues } from 'formik';

export interface FocusErrorProps {
  formik: Pick<
    FormikContextType<FormikValues>,
    'isSubmitting' | 'isValidating' | 'errors'
  >;
  focusDelay?: number;
  onFocus?: () => void;
}

export function FocusError({
  formik: { isSubmitting, isValidating, errors },
  focusDelay = 100,
  onFocus,
}: FocusErrorProps) {
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorNames = Object.keys(errors).flatMap((key) => {
        const errorValue = errors[key];
      
        if (Array.isArray(errorValue)) {
          return errorValue.flatMap((error, index) => {
            if (typeof error === 'object' && error !== null) {
              return Object.keys(error).map((nestedKey) => `${key}\\.${index}\\.${nestedKey}`);
            }
            return [];
          });
        } else if (typeof errorValue === 'object' && errorValue !== null) {
          return Object.keys(errorValue).map((nestedKey) => `${key}\\.${nestedKey}`);
        } else {
          return [key];
        }
      });

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement: HTMLElement | null = null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) errorElement = document.querySelector(selector);
          if (!errorElement)
            errorElement = document.querySelector(`#${errorKey}`);
        });

        if (errorElement) {
          setTimeout(() => {
            if (errorElement?.classList.contains('ant-select')) {
              const selectInput = errorElement.querySelector('input');
              if (selectInput) {
                selectInput.focus();
              }
            } else {
              errorElement?.focus();
            }

            if (onFocus) {
              onFocus();
            }
          }, focusDelay);
        }
      }
    }
  }, [isSubmitting, isValidating, errors, focusDelay, onFocus]);

  return <Fragment />;
}

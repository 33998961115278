import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import {
  LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  THERE_IS_BIND_LABORATORY_TO_COMPANY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';

export const useEditCallAPI = ({ form, t }: IUseCallAPI) => {
  const {
    data: companyList,
    isLoading: isLoadingCompany,
    refetch: refetchSearchCompanyPage,
    fetchNewPage: fetchInputCompany,
  } = useControllerQueryListApiHook({
    uniqId: LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listCompanyForDropdownToLaboratory,
    pagination: {
      sorter: { column: 'nomeFantasia', direction: 'ASC' },
    },
  });

  const {
    data: supplyPlaceList,
    isLoading: isLoadingSupplyPlace,
    refetch: refetchSupplyPlace,
    fetchNewPage: fetchNewPageSupplyPlace,
  } = useControllerQueryListApiHook({
    uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listSupplyPlaceForDropdownToLaboratory,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        {
          filterName: 'empresaExternalId',
          value: form.values.empresaExternalId,
        },
      ],
    },
    autoIncrement: true,
  });

  const { mutateAsync: fetchDescriptionLaboratory }: any =
    useControllerMutateApiHook({
      uniqId: THERE_IS_BIND_LABORATORY_TO_COMPANY,
      entityApi: LaboratoryAPI.laboratoryBindCompany,
      options: {
        onSuccess: () => {
          form?.setFieldError('nome', undefined);
        },
        onError: () => {
          form?.setFieldError(
            'nome',
            t('production.laboratory.create.validation')
          );
        },
      },
    });

  return {
    companyList,
    isLoadingCompany,
    refetchSearchCompanyPage,
    fetchInputCompany,
    supplyPlaceList,
    isLoadingSupplyPlace,
    refetchSupplyPlace,
    fetchDescriptionLaboratory,
    fetchNewPageSupplyPlace,
  };
};

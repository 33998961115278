import { FC } from "react";
import {
  Dropdown,
  Tooltip
} from '@/Components';
import { Col } from 'antd';
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IUnitPrescription } from "./interfaces";
import { useUnitPrescriptionMemorizations } from "./Hooks/useUnitPrescriptionMemorizations";

export const UnitPrescription: FC<IUnitPrescription> = ({
  product,
  disabledForm,
  listUnitsMeasurementsPrescription,
  isUnitOfMeasurePrescriptionLoading,
  fetchNewDcbPage
})  => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { items } = useUnitPrescriptionMemorizations({
    listUnitsMeasurementsPrescription
  });

  return (
    <Col span={4}>
      <Tooltip
        title={`${t(
          'products.toolTips.products.originalUnitPrescription'
        )} ${
          product && product.materiaPrima
            ? product.materiaPrima?.unidadePrescricaoDescricao
            : ''
        } (${
          product && product.materiaPrima
            ? product.materiaPrima?.unidadePrescricaoAbreviacao
            : ''
        })`}
        showMe={
          product &&
          !disabledForm &&
          product.materiaPrima?.unidadePrescricaoId !==
            form?.values?.unidadePrescricaoId
        }
      >
        <Dropdown
          withStatusIcon={!!product}
          name="unidadePrescricaoId"
          label={t('products.create.unitPrescription')}
          placeHolder={t('products.create.unitPrescriptionPlaceholder')}
          isLoading={isUnitOfMeasurePrescriptionLoading}
          onScrollEnd={fetchNewDcbPage}
          items={items}
          disabled={disabledForm}
        />
      </Tooltip>
    </Col>
  );
};
import { FC, useCallback, useState } from 'react';
import { i18n, Modal, Text } from '@/Components';
import { useTranslation } from 'react-i18next';
import { FETCH_GET_SALES_ORDER } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues } from 'formik';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { CreateDeliveryModalBody } from './createDeliveryModalBody';
import {
  ADD_ADRESS_CLIENT,
  CHANGE_FAVORITE_ADRESS_CLIENT,
  FETCH_ADDRESS_CLIENT_LIST,
} from '@/ControllerApiHook/UniqIds/People/ClientKeys';
import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

interface IEditDeliveryModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  saleOrder: IGetSaleOrderResponse;
}

export const CreateDeliveryModal: FC<IEditDeliveryModal> = ({
  changeVisibleModal,
  visibleModal,
  saleOrder,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [openFavoriteWarningModal, setOpenFavoriteWarningModal] =
    useState(false);

  const { mutateAsync, isLoading }: any = useControllerMutateApiHook({
    uniqId: ADD_ADRESS_CLIENT,
    entityApi: ClientAPI.addAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.createAddress'));

        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);

        queryClient.invalidateQueries([FETCH_ADDRESS_CLIENT_LIST]);

        setOpenFavoriteWarningModal(true);
      },
    },
  });

  const [newAddressExternalId, setNewAddressExternalId] = useState('');

  return (
    <>
      <Modal
        title={t('common.newAddress')}
        body={<CreateDeliveryModalBody />}
        visible={visibleModal}
        okButtonName={isLoading ? t('common.adding') : t('common.add')}
        onCancelClick={() => changeVisibleModal(false)}
        onClose={() => changeVisibleModal(false)}
        minWidth={800}
        withForm
        disabledButtonFormNames={['descricao']}
        onOkClickForm={async (x) => {
          if (saleOrder.clienteExternalId) {
            const res = await mutateAsync({
              ...x,
              clienteExternalId: saleOrder.clienteExternalId,
            });
            if (res.success) {
              setNewAddressExternalId(res.externalId);
            }
          }
        }}
        loading={isLoading}
        htmlType="submit"
      />
      {openFavoriteWarningModal && (
        <ConfirmFavoriteWarningDeliveryModal
          changeVisibleModal={setOpenFavoriteWarningModal}
          visibleModal={openFavoriteWarningModal}
          changeVisibleAddressModal={changeVisibleModal}
          clientNewAddressExternalId={newAddressExternalId}
        />
      )}
    </>
  );
};

interface IFavoriteDeliveryWarningModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  changeVisibleAddressModal: (value: boolean) => void;
  clientNewAddressExternalId: string;
}

export const ConfirmFavoriteWarningDeliveryModal: FC<
  IFavoriteDeliveryWarningModal
> = ({
  changeVisibleModal,
  visibleModal,
  changeVisibleAddressModal,
  clientNewAddressExternalId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading }: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_ADRESS_CLIENT,
    entityApi: ClientAPI.changeFavoriteAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeFavoriteAddress'));

        queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);

        queryClient.invalidateQueries([FETCH_ADDRESS_CLIENT_LIST]);

        changeVisibleModal(false);

        changeVisibleAddressModal(false);
      },
    },
  });

  const favoriteAddress = useCallback(
    async (_?: any, formik?: FormikHelpers<FormikValues>) => {
      try {
        await mutateAsync({
          clienteEnderecoExternalId: clientNewAddressExternalId,
          principal: true,
        });
      } catch {
        formik?.setSubmitting(false);
      }
    },
    [mutateAsync, clientNewAddressExternalId]
  );

  return (
    <Modal
      title={t('common.favoriteAddressQuestion')}
      body={
        <Divflex style={{ padding: '20px' }}>
          <Text color="text-50" type="ui-tiny-content">
            {t('common.favoriteAddressClientQuestion')}
          </Text>
        </Divflex>
      }
      visible={visibleModal}
      okButtonName={
        isLoading ? t('common.toDefineLoading') : t('common.toDefineFavorite')
      }
      cancelButtonName={t('common.notDefine')}
      onCancelClick={() => {
        changeVisibleModal(false);
        changeVisibleAddressModal(false);
      }}
      onClose={() => {
        changeVisibleModal(false);
        changeVisibleAddressModal(false);
      }}
      minWidth={400}
      loading={isLoading}
      htmlType="submit"
      onOkClick={() => favoriteAddress()}
    />
  );
};

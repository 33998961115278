import { FETCH_GET_PRODUCT } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IProductResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useParams } from 'react-router';

export const useLotControlModalCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: product } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: [externalId]
  });

  return {
    product
  };
};
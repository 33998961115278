import {
  Button,
  Icon,
  InputWithSearch,
} from '../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useFormikContext } from 'formik';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import { IIListPackagingClassificationData } from '@/Data/Interfaces/response/PharmaceuticalForm/IPharmaceuticalFormResponse';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditFunctions } from './Hooks/useEditFunctions';

interface IBodyModal {
  index: number;
  onDelete: (index: number) => void;
  totalRows: number;
  setProductRowAmount: React.Dispatch<React.SetStateAction<string[]>>;

  visible: boolean;
}

const BodyModal: React.FC<IBodyModal> = ({
  index,
  onDelete,
  totalRows,
  setProductRowAmount,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { setPackingClassificationRowAmount } = useEditStates();

  const {
    linkPackingClassification,
    mutateAsync,
    packingClassification,
    RefetchSearchPackingClassification,
  } = useEditCallAPI({ t, externalId, form, index });

  const {
    addRow,
    deleteRow,
    disabledSelectedItems,
    fetchInputPharmaceuticalFormDescription,
  } = useEditFunctions({
    setPackingClassificationRowAmount,
    linkPackingClassification,
    index,
    totalRows,
    setProductRowAmount,
    onDelete,
    form,
    externalId,
    mutateAsync,
  });

  return (
    <div
      style={
        index === form?.values?.embalagemClassificacaoExternalId?.length - 1
          ? { padding: '0px 0px 5px 0px' }
          : {}
      }
    >
      <Row gutter={[16, 0]}>
        <Col span={21}>
          <InputWithSearch
            name={`embalagemClassificacaoExternalId.${index}.externalId`}
            placeHolder={t(
              'production.pharmaceuticalForm.create.packingClassificationPlaceholder'
            )}
            items={packingClassification?.data?.map(
              (x: IIListPackagingClassificationData) => ({
                id: x?.embalagemClassificacaoExternalId,
                label: x?.embalagemClassificacaoDescricao,
                disabledItem:
                  x?.seVinculoFormaFarmaceutica ||
                  disabledSelectedItems(x.embalagemClassificacaoExternalId),
                titleTooltipItem: t('common.packagingAlreadyBound'),
              })
            )}
            onChange={(x) => {
              addRow();
              fetchInputPharmaceuticalFormDescription(x);
            }}
            onScrollEnd={packingClassification.fetchNewPage}
            onSearch={(search) =>
              RefetchSearchPackingClassification({
                search: search,
              })
            }
            getPopupContainerId="dropdown-itens-container"
          />
        </Col>
        <Col span={3}>
          {index !== totalRows - 1 && (
            <Button type="secondary" onClick={() => deleteRow(index)}>
              <Icon icon="trash" size="M" />
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BodyModal;

import { useControllerMutateApiHook, useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { IProductGroupResponse } from '@/Data/Interfaces/response/Product/IProductRespose';
import { 
  CREATE_SUBGROUP, EDIT_SUBGROUP, 
  FETCH_GET_GROUP 
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { useParams } from 'react-router';

export const useSubGroupModalCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data: productGroup } = useControllerQueryApiHook<IProductGroupResponse>({
    uniqId: FETCH_GET_GROUP,
    entityApi: ProductAPI.getProductGroup,
    externalId: [externalId]
  });

  const createSubGroup: any = useControllerMutateApiHook({
    uniqId: CREATE_SUBGROUP,
    entityApi: ProductAPI.createSubGroup
  });
  
  const editSubGroup: any = useControllerMutateApiHook({
    uniqId: EDIT_SUBGROUP,
    entityApi: ProductAPI.editProductSubGroup
  });

  return {
    productGroup,
    createSubGroup,
    editSubGroup
  };
};
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_TRANSFER } from '@/ControllerApiHook/UniqIds/Supply/TransferKeys';
import { TransferAPI } from '@/Data/API/Supply/TransferApi';
import { IUseCallAPI } from './interfaces';
import { IGetTransfer } from '@/Data/Interfaces/response/Transfer/ITransferList';

export const useEditCallAPI = ({ externalId }: IUseCallAPI) => {
  const { data: transfers, isLoading: isLoadingTransfers } =
    useControllerQueryApiHook<IGetTransfer>({
      uniqId: FETCH_GET_TRANSFER,
      entityApi: TransferAPI.getTransfer,
      externalId: [externalId],
    });

  return {
    transfers,
    isLoadingTransfers,
  };
};

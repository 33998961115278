import React, { useEffect, useState } from 'react';
import { Card } from '../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { FETCH_LIST_DROPDOWN_SUPPLIER } from '../../../../../../../ControllerApiHook/UniqIds/Purchase/SupplierKeys';
import FirstRow from './rows/FirstRow';
import ThirdRow from './rows/ThirdRow';
import FourthRow from './rows/FourthRow';
import FormBreakLine from '../../../../../../../Components/FormBreakLine';
import FifthRow from './rows/FifthRow';
import { FETCH_LIST_DROPDOWN_FREIGHT_TYPE } from '../../../../../../../ControllerApiHook/UniqIds/Purchase/PurchaseKeys';
import SecondRow from './rows/SecondRow';
import { FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION } from '../../../../../../../ControllerApiHook/UniqIds/Purchase/NatureOfOperationKeys';
import { useFormikContext } from 'formik';
import { IPurchaseOrderOpenGetResponseData } from '../../../../../../../Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import SixthRow from './rows/SixthRow';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { NatureOfOperationAPI } from '@/Data/API/Purchase/NatureOfOperationApi';
import { PurchaseAPI } from '@/Data/API/Purchase/PurchaseApi';
import { SupplierAPI } from '@/Data/API/Purchase/SupplierApi';

enum TypeEnum {
  distributorId = 1,
  shippingCompanyId = 2,
  serviceId = 3,
}

interface IInfoSectionProps {
  setImportedProducts: React.Dispatch<React.SetStateAction<any[]>>;
  initialPurchaseOrders: any[];
}

const InfoSection: React.FC<IInfoSectionProps> = ({
  setImportedProducts,
  initialPurchaseOrders,
}) => {
  const form: any = useFormikContext();
  const [modalTablePurchaseOrderVisible, setModalTablePurchaseOrder] =
    useState<boolean>(false);
  const [purchaseOrderSelected, setPurchaseOrderSelected] = useState<
    IPurchaseOrderOpenGetResponseData[]
  >([]);

  const { t } = useTranslation();

  const {
    data: listProvider,
    isLoading: isLoadingProvider,
    fetchNewPage: fetchNewProviderPage,
    refetch: refetchSearchProviderPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
    entityApi: SupplierAPI.listDropdownSupplier,
    pagination: {
      filter: [
        { filterName: 'tipoFornecedorId', value: TypeEnum.distributorId },
        { filterName: 'tipoFornecedorId', value: TypeEnum.serviceId },
      ],
    },
    autoIncrement: true,
  });

  const { data: freightTypeList } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_FREIGHT_TYPE,
    entityApi: PurchaseAPI.listDropdownFreightType,
    autoIncrement: true,
  });

  const {
    data: listShippingCompany,
    isLoading: isLoadingShippingCompany,
    fetchNewPage: fetchNewShippingCompanyPage,
    refetch: refetchSearchShippingCompanyPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_SUPPLIER,
    entityApi: SupplierAPI.listDropdownSupplier,
    pagination: {
      filter: [
        { filterName: 'tipoFornecedorId', value: TypeEnum.shippingCompanyId },
      ],
    },
    autoIncrement: true,
  });

  const {
    data: listNatureOfOperation,
    isLoading: isLoadingNatureOfOperation,
    fetchNewPage: fetchNewNatureOfOperationPage,
    refetch: refetchSearchNatureOfOperationPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_DROPDOWN_NATURE_OF_OPERATION,
    entityApi: NatureOfOperationAPI.listDropDownNatureOfOperation,
    autoIncrement: true,
  });

  useEffect(() => {
    if (purchaseOrderSelected.length > 0) {
      const list = purchaseOrderSelected.map((e) => e.externalId);
      form.values.pedidosExternalId = list;
    }
  }, [purchaseOrderSelected]);

  useEffect(() => {
    if (initialPurchaseOrders.length > 0) {
      setPurchaseOrderSelected(initialPurchaseOrders);
    }
  }, [initialPurchaseOrders]);

  return (
    <Card title={t('purchasing.invoice.create.info')} withoutCollapse>
      <FirstRow
        fetchNewNatureOfOperationPage={fetchNewNatureOfOperationPage}
        natureOfOperationList={listNatureOfOperation}
        isLoadingNatureOfOperation={isLoadingNatureOfOperation}
        refetchSearchNatureOfOperationPage={(search?: string) =>
          refetchSearchNatureOfOperationPage({
            search: search,
          })
        }
      />
      <SecondRow />
      <ThirdRow
        listProvider={listProvider ?? []}
        isLoading={isLoadingProvider}
        onScrollEnd={fetchNewProviderPage}
        onSearch={(search?: string) =>
          refetchSearchProviderPage({
            search: search,
          })
        }
        setPurchaseOrderSelected={setPurchaseOrderSelected}
        modalTablePurchaseOrderVisible={modalTablePurchaseOrderVisible}
        setModalTablePurchaseOrder={setModalTablePurchaseOrder}
        purchaseOrderSelected={purchaseOrderSelected}
      />
      <FourthRow
        listShippingCompany={listShippingCompany}
        freightTypeList={freightTypeList ?? []}
        isLoading={isLoadingShippingCompany}
        onScrollEnd={fetchNewShippingCompanyPage}
        onSearch={(search?: string) =>
          refetchSearchShippingCompanyPage({
            search: search,
          })
        }
      />
      <FifthRow />
      <FormBreakLine />
      <SixthRow
        purchaseOrderSelected={purchaseOrderSelected}
        setPurchaseOrderSelected={setPurchaseOrderSelected}
        setImportedProducts={setImportedProducts}
        setModalTablePurchaseOrder={setModalTablePurchaseOrder}
      />
    </Card>
  );
};

export default InfoSection;

import { useTranslation } from 'react-i18next';
import { GET_SERVICE } from '../../../../../../../ControllerApiHook/UniqIds/Sales/ServiceKeys';
import { FETCH_GET_TRANSFER } from '../../../../../../../ControllerApiHook/UniqIds/Supply/TransferKeys';
import { Text } from '../../../../../../Text';
import { Skeleton } from '../../../../../..';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetServiceResponse } from '@/Data/Interfaces/response/Sales/Service/IServiceResponse';
import { ServiceAPI } from '@/Data/API/Sales/ServiceApi';
import { TransferAPI } from '@/Data/API/Supply/TransferApi';
import { IGetTransfer } from '@/Data/Interfaces/response/Transfer/ITransferList';

export const DynamicInfosTransfersBreadcrumb = ({ match }: any) => {
  const { 
    data: transfers 
  } = useControllerQueryApiHook<IGetTransfer>({
    uniqId: FETCH_GET_TRANSFER,
    entityApi: TransferAPI.getTransfer,
    externalId: [match.params.externalId]
  });

  const { t } = useTranslation();
  return (
    <span style={{ display: 'flex' }}>
      {`${t('breadCrumb.transfers.detail.title')} | #${
        transfers ? transfers?.sequenciaNumeroTransferencia : ''
      }`}
    </span>
  );
};

export const DynamicTransfersBreadcrumb = ({ match }: any) => {
  const { 
    data: transfers 
  } = useControllerQueryApiHook<IGetTransfer>({
    uniqId: FETCH_GET_TRANSFER,
    entityApi: TransferAPI.getTransfer,
    externalId: [match.params.externalId]
  });

  return (
    <span style={{ display: 'flex' }}>
      {transfers?.sequenciaNumeroTransferencia}
    </span>
  );
};
export const DynamicTicketsBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();

  const { 
    data: service, 
    isLoading: getServiceLoading 
  } = useControllerQueryApiHook<IGetServiceResponse>({
    uniqId: GET_SERVICE,
    entityApi: ServiceAPI.getService,
    externalId: [match.params.externalId]
  });

  if (getServiceLoading) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }
  return (
    <Text
      type="heading3-bold"
      color="text-50"
      children={`#${service?.codigo} | ${t(
        'breadCrumb.salesRoute.serviceOrder.service'
      )}`}
    />
  );
};
export const DynamicTicketCodeBreadcrumb = ({ match }: any) => {
  const { 
    data: service, 
    isLoading: getServiceLoading 
  } = useControllerQueryApiHook<IGetServiceResponse>({
    uniqId: GET_SERVICE,
    entityApi: ServiceAPI.getService,
    externalId: [match.params.externalId]
  });

  if (getServiceLoading) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }
  return <span children={`#${service?.codigo}`} />;
};

import {
  FETCH_GET_PRODUCT
} from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import {
  GET_STANDARD_FORMULA
} from '@/ControllerApiHook/UniqIds/Production/StandardFormulaKeys';
import { useParams } from 'react-router';
import { 
  useControllerQueryApiHook
 } from '@/ControllerApiHook/Controller';
import { 
  IGetStandardFormula 
} from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { StandardFormulaAPI } from '@/Data/API/Production/StandardFormula';
import { 
  IProductResponse 
} from '@/Data/Interfaces/response/Product/IProductRespose';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useFormikContext } from 'formik';

export const useStandardFormulaDetailCallAPI = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const form: any = useFormikContext();

  const { data: standardFormula } =
    useControllerQueryApiHook<IGetStandardFormula>({
      uniqId: GET_STANDARD_FORMULA,
      entityApi: StandardFormulaAPI.getStandardFormula,
      externalId: [externalId],
    });

  const { 
    data: unitsMeasurementsDefault 
  } = useControllerQueryApiHook<IProductResponse>({
    uniqId: FETCH_GET_PRODUCT,
    entityApi: ProductAPI.getProduct,
    externalId: form.values.produtoExternalId
  });

  return {
    standardFormula,
    unitsMeasurementsDefault
  };
};
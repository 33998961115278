import * as yup from 'yup';
import i18n from '../../../../../../../Components/i18n';
import DateUtils from '../../../../../../../Utils/DateUtils';

export const DetailSchema = () =>
  yup.object().shape({
    nome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredName')
      ),

    email: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .nullable(),
    emailAlternativo: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .notOneOf(
        [yup.ref('email')],
        i18n.t(
          'admin.editUserPage.personalDataForm.errors.invalidAlternativeEmail'
        )
      )
      .nullable(),

    dataNascimento: yup
      .string()
      .test('date validation', i18n.t('common.dateInvalid'), (val) =>
        DateUtils.isDateValid(val)
      )
      .nullable(),
  });

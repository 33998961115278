import { FC } from 'react';
import { Form } from '../../../../../Components';
import { InfoCard } from './Components/Sections/InfoSection';
import { ProviderCreateSchema } from './ProviderCreate.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunctions';
import { FooterButtons } from './Components/FooterButton';
import { ContactCard } from './Components/Cards/ContactCard';
import { AddressCard } from './Components/Cards/AddressCard';
import { DocumentCard } from './Components/Cards/DocumentCard';

export const CreateSupplier: FC = () => {
  const {
    createProvider,
    isLoadingCreateProvider,
    documentList,
    refetchDocument,
  } = useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({ createProvider });

  return (
    <Form
      initialValues={{ tipoPessoa: 1 }}
      onSubmit={onSubmit}
      schema={ProviderCreateSchema}
    >
      {(props) => (
        <>
          <InfoCard refetch={refetchDocument} />
          <ContactCard props={props} />
          <AddressCard props={props} />
          <DocumentCard props={props} documentList={documentList} />
          <FooterButtons
            isLoadingCreateProvider={isLoadingCreateProvider}
            props={props}
          />
        </>
      )}
    </Form>
  );
};

import { useTranslation } from 'react-i18next';
import { StatusSalesOrder, StatusSalesOrderEnum } from './Components';
import { Col, Row } from 'antd';
import {
  TextInput,
  TextArea,
  Dropdown,
  Link,
} from '../../../../../../../../Components';
import { SelectedAvatar } from '../../../../../../../../Components/SelectedAvatar';
import { FC, useCallback, useMemo, useState } from 'react';
import { IGetSaleOrderResponse } from '../../../../../../../../Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import {
  FETCH_GET_SALES_ORDER,
  UPDATE_DELIVERY_SALES_ORDER,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { useQueryClient } from '@tanstack/react-query';
import { EditDeliveryModal } from './Modals/EditAddressDeliveryModal/editDeliveryModal';
import { ApproveSaleOrderModal } from './Modals/approveOrderModal';
import { ReproveSaleOrderModal } from './Modals/reproveOrderModal';
import { CancelSaleOrderModal } from './Modals/cancelOrderModal';
import { ReverseSaleOrderModal } from './Modals/reverseOrderModal';
import { CreateDeliveryModal } from './Modals/CreateAddressDeliveryModal/createDeliveryModal';
import { StatusSaleOrderType } from '@/Utils/StatusUtils';
import { SelectCardModal } from '@/Components/SelectCardModal';
import { DeliveryModal } from './Modals/deliveryModal';
import { useAddressModal } from '@/Hooks/ModalsHooks/addressModal';
import { AfterSelectedClientAddressModal } from './Components/SelectedAddressCard';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { GET_ME } from '@/ControllerApiHook/UniqIds/Accountmanager/UserKeys';
import { UserAPI } from '@/Data/API/Accountmanager/UserApi';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';

import styles from './sideRightMenu.module.scss';

interface ISideRightMenu {
  saleOrder: IGetSaleOrderResponse;
  patchSaleOrder: _.DebouncedFunc<
    (value: any, propName: string) => Promise<any>
  >;
  patchSaleOrderLoading: boolean;
  patchSaleOrderSuccess: boolean;
  patchVariables: string;
}

export const SideRightMenu: FC<ISideRightMenu> = ({
  saleOrder,
  patchSaleOrder,
  patchSaleOrderLoading,
  patchSaleOrderSuccess,
  patchVariables,
}) => {
  const form: any = useFormikContext();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess }: any = useControllerMutateApiHook(
    {
      uniqId: UPDATE_DELIVERY_SALES_ORDER,
      entityApi: SalesOrderAPI.updateDeliverySaleOrder,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_GET_SALES_ORDER]);
        },
        onError: (x) => messageErrorToast(x),
      },
    }
  );

  const retiradaNoBalcao = 1;
  const entregaEmDomicilo = 2;

  const [approveSaleOrderModal, setApproveSaleOrderModal] = useState(false);
  const [reproveSaleOrderModal, setReproveSaleOrderModal] = useState(false);
  const [cancelSaleOrderModal, setCancelSaleOrderModal] = useState(false);
  const [reverseSaleOrderModal, setReverseSaleOrderModal] = useState(false);

  const { t } = useTranslation();

  const statusSalesOrder = useMemo(
    () => saleOrder?.statusPedido,
    [saleOrder?.statusPedido]
  );

  const { data: myUser } = useControllerQueryApiHook<IGetUserResponse>({
    uniqId: GET_ME,
    entityApi: UserAPI.getMe,
  });

  const updateDeliveryCounter = useCallback(
    async (x: any, formik?: FormikHelpers<FormikValues>) => {
      try {
        await mutateAsync({
          pedidoVendaExternalId: saleOrder.externalId,
          tipo: x,
        });
      } catch {
        formik?.setSubmitting(false);
      }
    },
    [mutateAsync, saleOrder.externalId, saleOrder.tipoEntregaId]
  );

  const updateDeliveryForHome = useCallback(
    async (x?: any, formik?: FormikHelpers<FormikValues>) => {
      try {
        const res = await mutateAsync({
          pedidoVendaExternalId: saleOrder.externalId,
          tipo: entregaEmDomicilo,
          clienteEnderecoExternalId: x,
        });
        if (res.success) {
          messageSucessToast(
            t('saleOrder.editOrder.listAddressDeliveryModal.success')
          );
        }
      } catch {
        formik?.setSubmitting(false);
      }
    },
    [mutateAsync, saleOrder.externalId, saleOrder.tipoEntregaId, t]
  );

  const updateDeliveryFee = useCallback(
    (x: string | number) => {
      if (x === retiradaNoBalcao) {
        form.setFieldValue('taxa', 0);
        updateDeliveryCounter(x);
      } else setIsAddressModalOpen(true);
    },
    [form, updateDeliveryCounter]
  );

  const noItemsSelected = saleOrder.pedidoVendaItens.every(
    (item) => item.selecionado === false
  );

  const statusSaleOrder = saleOrder?.statusPedido;

  const {
    addresses,
    select,
    edit,
    create,
    setOpenDeliveryFeeModal,
    openDeliveryFeeModal,
    setIsAddressModalOpen,
    openAddressModalOpen,
    setOpenEditAddressModal,
    openEditAddressModal,
    setOpenCreateAddressModal,
    openCreateAddressModal,
    setSelectedAddressExternalId,
    selectedAddressExternalId,
  } = useAddressModal(saleOrder.clienteExternalId);

  const deliveryInfos =
    !!form.values.taxaEntrega ||
    !!form.values.trocoEntrega ||
    !!form.values.observacaoEntrega;

  const [currentSearch, setCurrentSearch] = useState('');

  return (
    <>
      <div
        id="container-side-right-menu-sale-order"
        className={styles['content-side-right-menu']}
        style={{ height: '100%' }}
      >
        <StatusSalesOrder
          typePurchase={statusSalesOrder}
          withBigSize
          withButtons
          onOkClick={() =>
            statusSalesOrder === StatusSalesOrderEnum.Orcado &&
            setApproveSaleOrderModal(true)
          }
          onCancelClick={() =>
            statusSalesOrder === StatusSalesOrderEnum.Orcado
              ? setReproveSaleOrderModal(true)
              : setReverseSaleOrderModal(true)
          }
          onClickDropdownItem={() =>
            statusSalesOrder === StatusSalesOrderEnum.Orcado &&
            setCancelSaleOrderModal(true)
          }
          disabledButton={noItemsSelected}
        >
          {StatusSaleOrderType({ statusId: statusSalesOrder, t }).children}
        </StatusSalesOrder>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <TextInput
              name="codigoPedido"
              label={t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.orderNumber'
              )}
              disabled
              withBoldContent
              withoutMarginBottom
            />
          </Col>
          <Col span={14}>
            <TextInput
              name="dataLancamento"
              label={t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.releaseDate'
              )}
              disabled
              withoutMarginBottom
            />
          </Col>

          <Col span={24}>
            <SelectedAvatar
              name={myUser?.nome}
              lastName={myUser?.sobrenome}
              label={t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.seller'
              )}
              withoutMarginBottom
            />
          </Col>

          <Col span={24}>
            <Dropdown
              name="tipoEntregaId"
              onChange={(x) => {
                updateDeliveryFee(x);
              }}
              allowClear
              withoutMarginBottom
              withLoadingLabel={isLoading}
              isSuccessIcon={isSuccess}
              items={[
                {
                  id: retiradaNoBalcao,
                  label: t(
                    'saleOrder.editOrder.sideRightMenu.infosSaleOrder.pickUpATheCounter'
                  ),
                  leftIcon: 'counter',
                },
                {
                  id: entregaEmDomicilo,
                  label: t(
                    'saleOrder.editOrder.sideRightMenu.infosSaleOrder.homeDelivery'
                  ),
                  leftIcon: 'shipping-truck',
                },
              ]}
              label={t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.delivery'
              )}
              disabled={statusSaleOrder !== StatusSalesOrderEnum.Orcado}
              getPopupContainerId="container-side-right-menu-sale-order"
            />
          </Col>
          {form.values.tipoEntregaId !== retiradaNoBalcao &&
            !!form.values.clienteEnderecoExternalId && (
              <>
                <Col span={24}>
                  <AfterSelectedClientAddressModal
                    saleOrder={saleOrder}
                    onClick={() => setIsAddressModalOpen(true)}
                  />
                </Col>
                <Col span={24} style={{ marginTop: '-13px' }}>
                  <Link
                    type="ui-tiny-semibold"
                    color="primary-base"
                    className={styles['link-delivery-button']}
                    onClick={() => setOpenDeliveryFeeModal(true)}
                    style={deliveryInfos ? { width: 158 } : { width: 179 }}
                    children={
                      deliveryInfos
                        ? t(
                            'saleOrder.editOrder.sideRightMenu.infosSaleOrder.editChangeOrNote'
                          )
                        : t(
                            'saleOrder.editOrder.sideRightMenu.infosSaleOrder.addChangeOrNote'
                          )
                    }
                  />
                </Col>
              </>
            )}

          <Col span={24}>
            <TextArea
              name="observacao"
              maxLength={120}
              label={t(
                'saleOrder.editOrder.sideRightMenu.infosSaleOrder.observations'
              )}
              heightTextArea={125}
              withLoadingLabel={!!patchVariables && patchSaleOrderLoading}
              isSuccessIcon={!!patchVariables && patchSaleOrderSuccess}
              onChange={(value) => patchSaleOrder(value, 'observacao')}
              placeholder={t('common.writeAComment')}
            />
          </Col>
        </Row>
      </div>
      {
        <SelectCardModal
          title={t('saleOrder.editOrder.deliveryModal.selectAddress')}
          translations={{
            footerButton: t('saleOrder.editOrder.deliveryModal.newAddress'),
            emptyDataLabel: t('address.addressEmpyt'),
            inputPlaceHolder: t('address.searchForAddress'),
          }}
          onClose={() => {
            setIsAddressModalOpen(false);
            !saleOrder.clienteEnderecoExternalId &&
              form.setFieldValue('tipoEntregaId', retiradaNoBalcao);
          }}
          onSelect={(x) => {
            select();
            updateDeliveryForHome(x);
          }}
          saleOrder={saleOrder}
          onEdit={(x) => {
            edit();
            setSelectedAddressExternalId(x);
          }}
          currentSearch={currentSearch}
          onFooterButtonClick={create}
          onSearch={(x) => {
            setCurrentSearch(x);
            addresses.refetch({
              search: x,
            });
          }}
          items={addresses?.data?.data?.map((item: any) => ({
            externalId: item.clienteEnderecoExternalId,
            title: item.descricao,
            favorite: item.principal,
            body: `${item.logradouro}, ${item.numero}, ${item.bairro}, ${item.estadoDescricao}, ${item.paisDescricao}`,
          }))}
          visible={
            openAddressModalOpen &&
            form.values.tipoEntregaId !== retiradaNoBalcao
          }
        />
      }
      {
        <DeliveryModal
          changeVisibleModal={() => {
            setOpenDeliveryFeeModal(false);
          }}
          saleOrderExternalId={saleOrder.externalId}
          visibleModal={openDeliveryFeeModal}
          saleOrder={saleOrder}
        />
      }
      {
        <EditDeliveryModal
          changeVisibleModal={() => {
            setOpenEditAddressModal(false);
            setIsAddressModalOpen(true);
          }}
          visibleModal={openEditAddressModal}
          saleOrder={saleOrder}
          selectedAddressExternalId={selectedAddressExternalId}
        />
      }
      {
        <CreateDeliveryModal
          changeVisibleModal={() => {
            setOpenCreateAddressModal(false);
            setIsAddressModalOpen(true);
          }}
          visibleModal={openCreateAddressModal}
          saleOrder={saleOrder}
        />
      }
      {
        <ApproveSaleOrderModal
          visibleModal={approveSaleOrderModal}
          changeVisibleModal={setApproveSaleOrderModal}
          saleOrder={saleOrder}
        />
      }
      {
        <ReproveSaleOrderModal
          visibleModal={reproveSaleOrderModal}
          changeVisibleModal={setReproveSaleOrderModal}
          saleOrder={saleOrder}
        />
      }
      {
        <CancelSaleOrderModal
          visibleModal={cancelSaleOrderModal}
          changeVisibleModal={setCancelSaleOrderModal}
          saleOrder={saleOrder}
        />
      }
      {
        <ReverseSaleOrderModal
          visibleModal={reverseSaleOrderModal}
          changeVisibleModal={setReverseSaleOrderModal}
          saleOrder={saleOrder}
        />
      }
    </>
  );
};
